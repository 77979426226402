// vue
import { ref, reactive, computed, watch, onMounted } from 'vue';
import moment from 'moment';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useProfile } from '@store/profile';

// shared
import { CRUD_PAGER_FACTORY } from '@shared/factories';

// services
import ProfileService from '@services/profileService';
import LocalStorage from '@services/ts/localStorage';

// naive-ui
import { useNotification } from 'naive-ui';

// router
import { useRoute } from 'vue-router';

// i18n
import { useI18n } from 'vue-i18n';

export default function (props, context) {
    // store
    const gl = useGl();
    const refs = useRefs();
    const profile = useProfile();

    // router
    const route = useRoute();

    // ui
    const notification = useNotification();

    // i18n
    const { t } = useI18n();

    // vars
    const { _ } = window;
    const show = ref(false);
    const dataRefs = ref();
    const loading = ref(false);

    // ranges
    const ranges = computed(() => refs.ranges);

    // storage filters
    const storeFilterKey = '___filters_balance_history';
    let storeFilters = LocalStorage.getItem(storeFilterKey);

    // period
    const localDefaultPeriod = ref();
    const defaultPeriod = moment().subtract(7, 'days').format('YYYY-MM-DD') + ' ' + moment().add(7, 'days').format('YYYY-MM-DD');
    const storagePeriod = () => {
        const i = ranges.value.findIndex(({ id }) => id == storeFilters?.filters.period);

        const result = ~i
            ? ranges.value[i].value.join(' ')
            : storeFilters?.filters.period || defaultPeriod;

        if (!localDefaultPeriod.value) {
            localDefaultPeriod.value = result;
        }

        return result;
    };

    // set filter settings
    if (storeFilters == null) {
        LocalStorage.setItem(storeFilterKey, CRUD_PAGER_FACTORY({
            bots: {},
            exchanges: [ -1 ],
            types: [ -1 ],
            states: [ -1 ],
            period: storagePeriod(),
            transaction_types: [ -1 ],
            statuses: [ -1 ],
        }));

        storeFilters = LocalStorage.getItem(storeFilterKey);
    }

    const filters = reactive({
        ...storeFilters,
    });

    const resetFilters = () => {
        filters.filters.bots = {};
        filters.filters.exchanges = [ -1 ];
        filters.filters.types = [ -1 ];
        filters.filters.states = [ -1 ];
        filters.filters.period = moment().subtract(7, 'days').format('YYYY-MM-DD') + ' ' + moment().add(7, 'days').format('YYYY-MM-DD');
        filters.filters.transaction_types = [ -1 ];
        filters.filters.statuses = [ -1 ];

        getTransactions();
    };
    const filtersChanged = computed(() => {
        return Object.keys(filters.filters.bots).length
            || filters.filters.exchanges[0] !== -1
            || filters.filters.types[0] !== -1
            || filters.filters.states[0] !== -1
            || filters.filters.transaction_types[0] !== -1
            || filters.filters.statuses[0] !== -1;
    });

    watch(() => props.tab, v => {
        if (props.myTab === v) {
            if (!dataRefs.value)
                getTransactions();
        }
    });

    const refFilters = computed(() => [
        {
            type: 'render',
            mode: 'date_picker',
            value: periods.value,
            filter: 'filters.period',
            title: refs.localization.filters.profile.filters_profile_period_label,
            seeable: true,
        }, {
            mode: 'radio_group',
            title: refs.localization.filters.profile.filters_profile_type_of_transaction_label,
            refs: profile.finOpTypes,
            value: filters.filters.types,
            filter: 'filters.types',
            seeable: filters.filters.types[0] !== -1,
        }, {
            mode: 'bots',
            title: refs.localization.filters.profile.filters_profile_bots_label,
            refs: profile.botFilters,
            filter: 'filters.bots',
            seeable: !!Object.keys(filters.filters.bots).length,
        }, {
            mode: 'exchanges',
            title: refs.localization.filters.profile?.filters_profile_exchanges_label,
            refs: refs.exchanges,
            filter: 'filters.exchanges',
            seeable: filters.filters.exchanges[0] !== -1,
        }, {
            mode: 'checkbox_group',
            title: refs.localization.filters.profile.filters_profile_transaction_type_label,
            refs: profile.transactionTypes,
            filter: 'filters.transaction_types',
            seeable: filters.filters.transaction_types[0] !== -1,
        }, {
            mode: 'checkbox_group',
            title: refs.localization.filters.profile.filters_profile_statuses_label,
            refs: profile.transactionStatuses,
            filter: 'filters.statuses',
            seeable: filters.filters.statuses[0] !== -1,
        },
    ]);

    const periods = computed(() => {
        const arr = filters.filters.period.split(' ');

        return [new Date(arr[0]).getTime(), new Date(arr[1]).getTime()];
    });

    const changeFilter = ([ key, value ], update = false) => {
        const canUpdate = key === 'query' && !value && dataRefs.value?.pager.query;

        if (key !== 'page') {
            _.set(filters, 'page', 1);
        }

        if (key === 'perPage') {
            LocalStorage.setItem('__filters_balance_history', value);
        }

        _.set(filters, key, value !== null && value !== undefined ? value : [ -1 ]);
        
        if (update || canUpdate)
            getTransactions();
    };

    const updateDate = $event => {
        if ($event) {
            let value = `${new Date($event[0] + 86400000).toISOString().split('T')[0]} ${new Date($event[1] + 86400000).toISOString().split('T')[0]}`;

            // mobile

            // const firstDate = new Date($event[0]).toISOString().split('T')[0] !== filters.filters.period.split(' ')[0]
            //     ? new Date($event[0]).toISOString().split('T')[0]
            //     : filters.filters.period.split(' ')[0];

            // const secondDate = new Date($event[1]).toISOString().split('T')[0] !== filters.filters.period.split(' ')[1]
            //     ? new Date($event[1]).toISOString().split('T')[0]
            //     : filters.filters.period.split(' ')[1];

            // let value = `${firstDate} ${secondDate}`;
            changeFilter(['filters.period', value ]);
        }
    };

    const getTransactions = async () => {
        !gl.isMobile
            ? gl.showLoading = true
            : loading.value = true;

        // save filters
        LocalStorage.setItem(storeFilterKey, filters);
        
        try {
            dataRefs.value = ( await ProfileService.getTransactions({ pager: filters }) ).data;
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };
      
        !gl.isMobile
            ? gl.showLoading = false
            : loading.value = false;
    };

    const downloadData = async format => {
        !gl.isMobile
            ? gl.showLoading = true
            : loading.value = true;

        try {
            const { redirectTo } = ( await ProfileService.getTransactions({
                format,
                pager: filters,
            }) ).data;

            window.location.href = redirectTo;
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        !gl.isMobile
            ? gl.showLoading = false
            : loading.value = false;
    };

    onMounted(async () => {
        if (!gl.isMobile) {
            if (route.query?.tab === 'account_balance_history') {
                await getTransactions();
            }
        } else {
            if (!dataRefs.value) {
                getTransactions();
            }
        }
    });

    return {
        gl,
        refs,
        show,
        profile,
        filters,
        periods,
        dataRefs,
        loading,
        refFilters,
        filtersChanged,
        updateDate,
        changeFilter,
        downloadData,
        resetFilters,
        getTransactions,
    };
}