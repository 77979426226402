// vue
import { ref, computed, watch } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';

// i18n
import { useI18n } from 'vue-i18n';

// services
import ProfileService from '@services/profileService';

// naive-ui
import { useNotification } from 'naive-ui';

export default function () {
    // store
    const gl = useGl();
    const refs = useRefs();

    // ui
    const notification = useNotification();

    // vars
    const page = ref(1);
    const showModal = ref(true);

    // i18n
    const { t } = useI18n();

    const show = computed(() => !!gl.postDialogMessages && gl.postDialogMessages?.length);

    watch(show, () => {
        page.value = 1;
    });

    const close = () => {
        gl.postDialogMessages = null;
    };

    const dialogMessageMarkAsRead = async el => {
        try {
            if (el.need_feedback)
                ProfileService.dialogMessageMarkAsRead({ id: el.id });

            const i = gl.postDialogMessages.findIndex(item => item.id === el.id);

            gl.postDialogMessages.splice(i, 1);

            if (!gl.postDialogMessages.length) {
                close();
            }
        } catch {
            notification.error({
                content: t('errorMessage'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        };
    };

    return {
        gl,
        refs,
        page,
        show,
        showModal,
        close,
        dialogMessageMarkAsRead,
    };
}