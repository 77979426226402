<template>
<section class="px-3 min-h-screen">
    <section class="flex justify-start">
        <n-icon
            size="20"
            class="text-gray-600 dark:text-white/75"
            @click="router.go(-1)">
            <ArrowLeft12Regular />
        </n-icon>
    </section>

    <account-info />
    <n-card
        size="small"
        :bordered="false"
        class="mt-4 overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-md"
        content-style="padding: 0;"
        :segmented="{
          content: true,
          footer: 'soft'
        }">
        <template
            v-for="item in tabs"
            :key="item.id">
            <template v-if="item.id === 'theme'">
                <n-button
                    quaternary
                    size="large"
                    class="w-full nav flex items-center justify-between text-base text-left text-gray-600 pl-4 pr-4 first:mt-2 last:mb-2 dark:text-white/75 h-12 rounded-none"
                    @click="changeTheme">
                    <section class="w-full flex justify-between items-center text-sm">
                        <section class="flex items-center ">
                            <n-icon size="16" class="mr-3"><component :is="item.icon"></component></n-icon>
                            {{ item.title }}
                        </section>
                        <n-switch
                            :rail-style="railStyle"
                            :value="gl.darkTheme"
                            size="medium">
                            <template #checked-icon>
                                <n-icon><WeatherMoon28Regular /></n-icon>
                            </template>
                            <template #unchecked-icon>
                                <n-icon><WeatherSunny32Regular /></n-icon>
                            </template>
                        </n-switch>
                    </section>
                </n-button>
            </template>
            <template v-else>
                <n-button
                    quaternary
                    size="large"
                    class="w-full nav flex items-center justify-between text-base text-left text-gray-600 pl-4 pr-4 first:mt-2 last:mb-2 dark:text-white/75 h-12 rounded-none"
                    @click="changeTab(item.id)">
                    <section class="w-full flex justify-between items-center text-sm">
                        <section class="flex items-center ">
                            <n-icon size="16" class="mr-3"><component :is="item.icon"></component></n-icon>
                            {{ item.title }}
                        </section>
                        <n-icon
                            size="14"
                            class="text-gray-400 dark:text-white/50">
                            <ArrowRight12Regular />
                        </n-icon>
                    </section>
                </n-button>
            </template>
        </template>
    </n-card>
    <n-button
        ghost
        type="error"
        class="mt-4 w-full rounded-md"
        @click="logOut">
        <div class="text-xs font-semibold text-red-400">{{ refs.header.logout }}</div>
    </n-button>
    <n-drawer
        :auto-focus="false"
        v-model:show="showModal"
        width="100%"
        class="bg-gray-100 dark:bg-darkbg">
        <n-drawer-content body-content-style="padding: 0 12px;" :auto-focus="false">
            <template
                v-for="item in tabs"
                :key="item.id">
                <div v-if="tab === item.id" class="flex flex-col h-full">
                    <div class="relative w-full bg-gray-100 dark:bg-darkbg text-sm text-center text-gray-600 dark:text-white/75 py-4">
                        <n-icon
                            size="20"
                            class="text-gray-600 dark:text-white/75 absolute top-1/2 left-0 -translate-y-1/2"
                            @click="showModal = false">
                            <ArrowLeft12Regular />
                        </n-icon>
                        {{ item.title }}
                    </div>
                    <div class="pb-4 flex-grow overflow-y-scroll overscroll-none scrollbar-hide">
                        <component
                            :tab="tab"
                            :is="item.component">
                        </component>
                    </div>
                </div>
            </template>
        </n-drawer-content>
    </n-drawer>
</section>
</template>

<script>
// vue
import { ref, computed, watch, onMounted } from 'vue';

// services
import ProfileService from '@services/profileService';
import LocalStorage from '@/services/LocalStorage';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useProfile } from '@store/profile';

// cookies
import { useCookies } from 'vue3-cookies';

// naive-ui
import {
    NIcon,
    NCard,
    NButton,
    NDrawer,
    NSwitch,
    NDrawerContent,
    useNotification } from 'naive-ui';

// i18n
import { useI18n } from 'vue-i18n';

// router
import { useRoute, useRouter } from 'vue-router';

// icons
import {
    Alert24Regular,
    Person16Regular,
    Password24Regular,
    PersonInfo16Filled,
    ArrowLeft12Regular,
    ArrowRight12Regular,
    MoneyHand24Regular,
    PaintBrush20Regular,
    WeatherMoon28Regular,
    WeatherSunny32Regular,
    MoneyCalculator24Regular,
    ContentSettings24Regular,
    PeopleCommunityAdd20Regular,
    TabInprivateAccount24Regular }  from '@vicons/fluent';

// components
import OAuth from '@components/profile/o-auth/mobile.vue';
import MainInfo from '@components/profile/main-info/mobile.vue';
import Password from '@components/profile/password/mobile.vue';
import AccountInfo from '@components/profile/account-info/mobile.vue';
import Notifications from '@components/profile/notifications/mobile.vue';
import BalanceHistory from '@components/profile/balance-history/mobile.vue';
import ReferralProgram from '@components/profile/referral-program/mobile.vue';
import PaymentsRequest from '@components/profile/payments-request/mobile.vue';
import MarketplaceSettings from '@components/profile/marketplace-settings/mobile.vue';

export default {
    name: 'profile-mobile',
    components: {
        NIcon,
        NCard,
        NButton,
        NDrawer,
        NSwitch,
        AccountInfo,
        NDrawerContent,
        Person16Regular,
        ReferralProgram,
        PaymentsRequest,
        ArrowLeft12Regular,
        ArrowRight12Regular,
        WeatherMoon28Regular,
        WeatherSunny32Regular,
    },
    setup() {
        // router
        const gl = useGl();
        const profile = useProfile();
        const refs = useRefs();

        // router
        const route = useRoute();
        const router = useRouter();

        // cookies
        const { cookies } = useCookies();

        // ui
        const notification = useNotification();

        // i18n
        const { t } = useI18n();

        // vars
        const tab = computed(() => route.query?.tab);
        const showModal = ref();
        watch(showModal, v => {
            if (!v) {
                router.push({ name: route.name, query: { tab: 'account_info' }});
            }
        });
        watch(() => route.query?.tab, v => {
            if (v && v !== 'account_info') {
                changeTab(v);
            }
        });
        const init = ref(false);

        const tabs = computed(() => init.value
            ? [
                {
                    id: 'main_info',
                    title: profile.localization['profile_main_info_title'],
                    component: MainInfo,
                    icon: PersonInfo16Filled,
                    fields: ['full_name'],
                },{
                    id: 'marketplace_settings',
                    title: profile.localization['profile/marketplace/header'],
                    component: MarketplaceSettings,
                    icon: ContentSettings24Regular,
                    fields: ['mentorship_price', 'mentor_contact', 'mentor_notes'],
                }, {
                    id: 'account_balance_history',
                    title: profile.localization['profile_account_balance_history_title'],
                    component: BalanceHistory,
                    icon: MoneyCalculator24Regular,
                    fields: [],
                }, {
                    id: 'referral_info',
                    title: profile.localization['profile_referral_info_title'],
                    component: ReferralProgram,
                    icon: PeopleCommunityAdd20Regular,
                    fields: [],
                }, {
                    id: 'referral_info_withdrawal',
                    title: profile.localization['profile_referral_info_withdrawal_request_title'],
                    component: PaymentsRequest,
                    icon: MoneyHand24Regular,
                    fields: [],
                }, {
                    id: 'oauth',
                    title: profile.localization['profile/oauth/header'],
                    component: OAuth,
                    icon: TabInprivateAccount24Regular,
                    fields: [],
                }, {
                    id: 'profile_notifications',
                    title: profile.localization['profile_notification_title'],
                    component: Notifications,
                    icon: Alert24Regular,
                    fields: [],
                }, {
                    id: 'profile_password',
                    title: profile.localization['profile_password_title'],
                    component: Password,
                    icon: Password24Regular,
                    fields: ['new_password'],
                }, {
                    id: 'theme',
                    icon: PaintBrush20Regular,
                    title: gl.darkTheme ? t('theme[0]') : t('theme[1]'),
                },
            ]: []);

        const railStyle = ({
            focused,
            checked,
        }) => {
            const style = {};
            if (checked) {
                style.background = '#2B2C39';
                if (focused) {
                    style.boxShadow = '0';
                }
            } else {
                if (focused) {
                    style.boxShadow = '0';
                }
            }
            return style;
        };

        const changeTheme = () => {
            if (!~[...document.documentElement.classList].indexOf('dark')) {
                LocalStorage.setItem('darkTheme', true);
                gl.darkTheme = true;
            } else {
                LocalStorage.setItem('darkTheme', false);
                gl.darkTheme = false;
            }

            document.documentElement.classList.toggle('dark');
        };

        const getRefs = async (showLoading = true) => {
            if (showLoading)
                gl.showLoading = true;

            try {
                profile.refs = ( await ProfileService.getRefs() ).data;
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };
            
            if (showLoading)
                gl.showLoading = false;
        };

        const getFullInfo = async (showLoading = true) => {
            if (showLoading)
                gl.showLoading = true;

            try {
                profile.fullInfo = ( await ProfileService.getFullInfo() ).data;
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };
            
            if (showLoading)
                gl.showLoading = false;
        };

        const getData = async () => {
            gl.showLoading = true;

            await Promise.allSettled([
                getRefs(false),
                getFullInfo(false),
            ]);

            gl.showLoading = false;
        };

        const changeTab = $event => {
            showModal.value = true;

            setTimeout(() => {
                router.push({ name: route.name, query: { tab: $event }});
            });
        };

        const logOut = async () => {
            gl.showLoading = true;

            try {
                const preapare = await ProfileService.logout();

                if (preapare.data.status) {
                    document.location.href = preapare.data.logout.redirectTo;
                }

            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };

            gl.showLoading = false;
        };

        onMounted(async () => {
            await getData();
            
            init.value = true;
            gl.secondMenu = true;

            if (!route.query?.tab) {
                router.push({ name: route.name, query: { tab: 'account_info' }});
            } else if (route.query?.tab !== 'account_info') {
                showModal.value = true;
            }
        });

        return {
            gl,
            tab,
            tabs,
            refs,
            router,
            showModal,
            t,
            logOut,
            changeTab,
            railStyle,
            changeTheme,
        };
    },
};
</script>

<style lang="scss" scoped>
.nav :deep(.n-button__content) {
    width: 100%;
}
</style>