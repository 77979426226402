<template>
<section>
    <rb-checkbox
        :label="checkBoxTitle"
        :checked="checked"
        :help="quickStart.localization['quick_start/matrix/info']"
        @update:checked="$emit('update:checked', $event)" />

    <div class="mt-4">
        <div class="w-full flex justify-between items-center mt-2">
            <n-button
                strong
                class="rounded-md text-white/90"
                :color="gl.mainColor"
                :disabled="disabled"
                @click.prevent="onDec" >
                <strong class="text-lg">-</strong>
            </n-button>

            <div class="flex flex-grow items-center">
                <n-input
                    type="text"
                    class="flex-grow mx-2 text-center font-bold rounded-md"
                    :placeholder="''"
                    :disabled="disabled || hasIntervals"
                    :allow-input="onlyAllowNumber"
                    :value="modelValue"
                    @keyup="onKeyUp"
                    @input="onInput" />
                    <!-- <div class="text-main text-2xl font-bold pr-2">x</div> -->
            </div>
            
            <n-button
                strong
                class="rounded-md text-white/90"
                :color="gl.mainColor"
                :disabled="disabled"
                @click.prevent="onInc" >
                <strong class="text-lg">+</strong>
            </n-button>
        </div>
      <n-slider
          class="w-full"
          :step="1"
          :value="modelValue"
          :min="minMax.min"
          :max="minMax.max"
          :marks="sliderMarks"
          :disabled="disabled"
          @update:value="onInput"/>
    </div>
</section>
<div v-if="!isExchangeFutures && checked" class="mt-12">
    <rb-checkbox
        popoverType="warning"
        :help="help"
        :nowrap="false"
        :label="partOrdersUnstopableTitle"
        :checked="partOrdersUnstopable"
        @update:checked="$emit('update:partOrdersUnstopable', $event)" />
    <slot name="partOrdersUnstopableAlerts"></slot>
</div>
</template>

<script>
// general
import general from './general';

// naive-ui
import {
    NIcon,
    NInput,
    NButton,
    NSlider,
    NCheckbox } from 'naive-ui';

// components
import RbCheckbox from '@components/rb-checkbox/mobile.vue';

// icons
import { CellularData320Filled } from '@vicons/fluent';

export default {
    name: 'rb-part-orders-mobile',
    emits: ['update:checked', 'update:partOrdersUnstopable'],
    props: {
        checked: Boolean,
        isExchangeFutures: Boolean,
        partOrdersUnstopable: Boolean,
        checkBoxTitle: String,
        help: String,
        partOrdersUnstopableTitle: String,
        modelValue: [String, Number],
        pair: {
            type: Object,
            default: () => ({
                min: 1,
                max: 0,
            }),
        },
    },
    components: {
        NIcon,
        NInput,
        NButton,
        NSlider,
        NCheckbox,
        RbCheckbox,
        CellularData320Filled,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>