import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';

// store
import { useGl } from '@store/ts/gl';

// views
import Home from '@views/Home.vue';
import Marketplace from '@views/Marketplace.vue';
import Tools from '@views/Tools.vue';
import Profile from '@views/Profile.vue';
import QuickStart from'@views/QuickStart.vue';

// pages
import Bots from '@pages/bots/index.vue';
import Tasks from '@pages/tasks/index.vue';
import Wallets from '@pages/wallets/index.vue';
import ApiKeys from '@pages/api-keys/index.vue';
import TradeStatistic from '@pages/trade-statistic/index.vue';

// components
const MarketplaceBuy = () => import('@components/marketplace-buy/index.vue');
const MarketplaceSell = () => import('@components/marketplace-sell/index.vue');
const MarketplaceSignals = () => import('@components/marketplace-signals/index.vue');
const Mentors = () => import('@components/marketplace-mentorship/mentors/index.vue');
const iMentor = () => import('@components/marketplace-mentorship/i-mentor/index.vue');
const myMentors = () => import('@components/marketplace-mentorship/my-mentors/index.vue');
const MarketplaceMentorship = () => import('@components/marketplace-mentorship/index.vue');
const BotsReview = () => import('@components/bots/bots-review/index.vue');
const BotsCreate = () => import('@components/bots/bots-create/index.vue');
const BotsClone = () => import('@components/bots/bots-clone/index.vue');
const BotsEdit = () => import('@components/bots/bots-edit/index.vue');
const StatsManagement = () => import('@pages/trade-statistic/management/index.vue');
const ToolsStats = () => import('@components/toolss/stats/index.vue');
const ToolsVolatility = () => import('@components/toolss/volatility/index.vue');
const ToolsAnalyzer = () => import('@components/toolss/tools-analyzer/index.vue');
const AvailSignals = () => import('@components/marketplace-signals/avail-signals/index.vue');
const MySignals = () => import('@components/marketplace-signals/my-signals/index.vue');
const Presets = () => import('@components/toolss/presets/index.vue');

const routes = [
    {
        path: '/:bot_id?',
        name: 'home',
        component: Home,
    }, {
        path: '/tasks',
        name: 'tasks',
        component: Tasks,
    }, {
        path: '/marketplace',
        name: 'marketplace',
        component: Marketplace,
        children: [
            {
                path: 'buy/:bot_id?',
                name: 'marketplace.buy',
                component: MarketplaceBuy,
                children: [
                    {
                        path: 'free/:free_bot_id?',
                        name: 'marketplace.buy.free',
                        component: MarketplaceBuy,
                    },
                ],
            }, {
                path: 'sell/:id?',
                name: 'marketplace.sell',
                component: MarketplaceSell,
            }, {
                path: 'signals',
                name: 'marketplace.signals.index',
                component: MarketplaceSignals,
                children: [
                    {
                        path: '',
                        name: 'marketplace.signals.avail-signals',
                        component: AvailSignals,
                    }, {
                        path: 'my-signals',
                        name: 'marketplace.signals.my-signals',
                        component: MySignals,
                    },
                ],
            }, {
                path: 'mentorship',
                name: 'marketplace.mentorship',
                component: MarketplaceMentorship,
                children: [
                    {
                        path: '',
                        name: 'marketplace.mentorship.mentors',
                        component: Mentors,
                    }, {
                        path: 'my-mentors',
                        name: 'marketplace.mentorship.myMentors',
                        component: myMentors,
                    }, {
                        path: 'i-mentor',
                        name: 'marketplace.mentorship.iMentor',
                        component: iMentor,
                    },
                ],
            },
        ],
    }, {
        name: 'quick-start',
        path: '/quick-start',
        component: QuickStart,
        // children: [
        //     {
        //         path: '',
        //         name: 'quick-start.component',
        //         component: QuickStartComponent,
        //     },
        // ],
    }, {
        name: 'wallets',
        path: '/wallets/:id?',
        component: Wallets,
    }, {
        name: 'bots',
        path: '/bots',
        component: Bots,
        children: [
            {
                name: 'bots.review',
                path: '',
                component: BotsReview,
            }, {
                name: 'bots.clone',
                path: 'clone/:id',
                component: BotsClone,
            }, {
                name: 'bots.create',
                path: 'create',
                component: BotsCreate,
            }, {
                path: 'edit/:id',
                name: 'bots.edit',
                component: BotsEdit,
            },
        ],
    }, {
        name: 'trade-statistic',
        path: '/trade-statistic',
        children: [
            { name: 'trade-statistic.main', path: '', component: TradeStatistic },
            { name: 'trade-statistic.management', path: 'management/:id', component: StatsManagement },
        ],
    }, {
        path: '/about',
        name: 'About',
        component: () =>
            import('../views/About.vue'),
    },
    {
        name: 'api-keys',
        path: '/api-keys/:id?',
        component: ApiKeys,
    },
    {
        name: 'tools',
        path: '/tools',
        component: Tools,
        children: [
            {
                name: 'tools.stats',
                path: '',
                component: ToolsStats,
            }, {
                name: 'tools.indicators',
                path: 'volatility-indicators',
                component: ToolsVolatility,
            }, {
                name: 'tools.va',
                path: 'volatility-analyzer',
                component: ToolsAnalyzer,
            }, {
                name: 'tools.presets',
                path: 'presets/:tab?',
                component: Presets,
            },
        ],
    },
    {
        name: 'profile',
        path: '/profile',
        component: Profile,
    },
];

const createHistory = process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory;

const router = createRouter({
    history: createHistory(process.env.VUE_ROUTER_BASE),
    scrollBehavior(to, from, savedPosition) {
        if (to.name != from.name) {
            const el = document.querySelector('#main_container');
            if (el) {
                el.scrollTo({
                    top: 0,
                });
            }
        }
        // always scroll to top
        return { top: 0 };
    },
    routes,
});

router.beforeEach((to, from) => {
    const gl = useGl();
    gl.postAdviceMessages = null;
});

export default router;
