// vue
import { ref, computed, onMounted } from 'vue';

// i18n
import { useI18n } from 'vue-i18n';

// services
import Api from '@services/api';

// naive-ui
import { useNotification } from 'naive-ui';

// store
import { useGl } from '@store/ts/gl';
import { useEnv } from '@store/ts/env';
import { useRefs } from '@store/ts/refs';
import { useDashBoard } from '@store/ts/dashBoard';

export default function (props, context) {
    // store
    const gl = useGl();
    const env = useEnv();
    const refs = useRefs();
    const dashBoard = useDashBoard();

    // vars
    const updateLoading = ref(false);

    // ui
    const notification = useNotification();

    // i18n
    const { t } = useI18n();

    const loading = computed(() => gl.loading || dashBoard.loading || !dashBoard.refs);

    const data = computed(() => [
        {
            id: 'daily_profit',
            title: dashBoard.localization?.dashboard_profit_24,
            data: dashBoard.revenue_stats?.daily_profit,
            ciclos: dashBoard.revenue_stats?.daily_profit.cycles.completed + ' ' + dashBoard.localization?.dashboard_profit_24_cycles_completed,
            stats: !dashBoard.revenue_stats ? [] : (Object.entries(dashBoard.revenue_stats.daily_profit.coins).map(([key, value]) => [...value, key]))
                .sort(([ , , a], [ , , b]) => {
                    if (a < b)
                        return -1;
                    if (a > b)
                        return 1;
                    return;
                }),
        }, {
            id: 'week_profit',
            title: dashBoard.localization?.dashboard_profit_week,
            data: dashBoard.revenue_stats?.week_profit,
            ciclos: dashBoard.revenue_stats?.week_profit.cycles.completed + ' ' + dashBoard.localization?.dashboard_profit_week_cycles_completed,
            stats: !dashBoard.revenue_stats ? [] :  (Object.entries(dashBoard.revenue_stats.week_profit.coins).map(([key, value]) => [...value, key]))
                .sort(([ , , a], [ , , b]) => {
                    if (a < b)
                        return -1;
                    if (a > b)
                        return 1;
                    return;
                }),
        }, {
            id: 'month_profit',
            title: dashBoard.localization?.dashboard_profit_month,
            data: dashBoard.revenue_stats?.month_profit,
            ciclos: dashBoard.revenue_stats?.month_profit.cycles.completed + ' ' + dashBoard.localization?.dashboard_profit_month_cycles_completed,
            stats: !dashBoard.revenue_stats ? [] :  (Object.entries(dashBoard.revenue_stats.month_profit.coins).map(([key, value]) => [...value, key]))
                .sort(([ , , a], [ , , b]) => {
                    if (a < b)
                        return -1;
                    if (a > b)
                        return 1;
                    return;
                }),
        }, {
            id: 'total_profit',
            title: dashBoard.localization?.dashboard_profit_total,
            data: dashBoard.revenue_stats?.total_profit,
            ciclos: dashBoard.revenue_stats?.total_profit.cycles.completed + ' ' + dashBoard.localization?.dashboard_profit_total_cycles_completed,
            stats: !dashBoard.revenue_stats ? [] : (Object.entries(dashBoard.revenue_stats.total_profit.coins).map(([key, value]) => [...value, key]))
                .sort(([ , , a], [ , , b]) => {
                    if (a < b)
                        return -1;
                    if (a > b)
                        return 1;
                    return;
                }),
        },
    ]);

    const currency = icon => refs.currencies.find(el => el.id === icon);

    const update = async () => {
        updateLoading.value = true;

        try {
            dashBoard.revenueStats = ( await Api.dashBoard({
                dashboard_block: 'revenue_stats',
            }) ).data.revenue_stats;
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        }

        updateLoading.value = false;
    };
    
    return {
        t,
        gl,
        env,
        data,
        update,
        loading,
        currency,
        dashBoard,
        updateLoading,
    };
}