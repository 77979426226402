<template>
<mobile v-if="gl.isMobile">
    <template #afterFastApi><slot name="afterFastApi"></slot></template>
    <template #afterSelect><slot name="afterSelect"></slot></template>
    <template #afterCreate><slot name="afterCreate"></slot></template>
</mobile>
<desktop v-else>
    <template #afterFastApi><slot name="afterFastApi"></slot></template>
    <template #afterSelect><slot name="afterSelect"></slot></template>
    <template #afterCreate><slot name="afterCreate"></slot></template>
</desktop>
</template>

<script>
// store
import { useGl } from '@store/ts/gl';

// components
import Desktop from './desktop.vue';
import Mobile from './mobile.vue';

export default {
    name: 'rb-api-key-cr',
    components: {
        Mobile,
        Desktop,
    },
    setup() {
        const gl = useGl();
    
        return {
            gl,
        };
    },
};
</script>
