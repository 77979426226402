import Api from './index';

export default class Tools {

    static async getStatsFullInfo(payload) {
        return await Api.getData({
            payload,
            action: 'tools.stats.getFullInfo',
        });
    };

    static async getCoinListInfo(id) {
        return await Api.getData({
            payload: { id },
            action: 'tools.cl.getCoinListInfo',
        });
    };

    static async runPreset(payload) {
        return await Api.getData({
            url: 'https://vaapp.revenuebot.io/private/api/tools/va/runPreset/',
            payload,
            action: 'tools.va.runPreset',
        });
    };

    static async savePreset(payload) {
        return await Api.getData({
            payload,
            action: 'tools.va.savePreset',
        });
    };

    static async getPresetInfo(id) {
        return await Api.getData({
            payload: { id },
            action: 'tools.va.getPresetInfo',
        });
    };

    static async getRefs() {
        return await Api.getData({
            action: 'tools.getRefs',
        });
    };

    static async deletePreset(id) {
        return await Api.getData({
            payload: { id },
            action: 'tools.va.deletePreset',
        });
    };

    static async createCoinList(payload) {
        return await Api.getData({
            payload,
            action: 'tools.cl.createCoinList',
        });
    };

    static async updateCoinList(payload) {
        return await Api.getData({
            payload: {
                coin_list: payload,
            },
            action: 'tools.cl.updateCoinList',
        });
    };

    static async deleteCoinList(id) {
        return await Api.getData({
            payload: { id },
            action: 'tools.cl.deleteCoinList',
        });
    };
}