<template>
<section class="pb-2">
<div class="text-xl text-left text-gray-700 dark:text-white/90 font-semibold">
    <n-skeleton v-if="!profile.localization" :width="160" :height="20" :sharp="false" />
    <template v-else>{{ profile.localization['profile/marketplace/header'] }}</template>
</div>
<n-card
    :bordered="false"
    class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4"
    :segmented="{
        content: true,
        footer: 'soft'
    }">
    <template #default>
        <div class="text-base text-left text-gray-600 dark:text-white/75 font-semibold">
            <n-skeleton v-if="!profile.localization" :width="160" :height="20" :sharp="false" />
            <template v-else>{{ profile.localization['profile/marketplace/mentorship/header'] }}</template>
        </div>

        <div class="flex flex-wrap mt-4">
            <div class="w-4/12">
                <rb-switch
                    id="is_mentor"
                    :label="profile.localization['profile/marketplace/mentorship/participate_as_mentor']"
                    v-model:value="profile.fullInfo.is_mentor" />
            </div>
            
            <div class="w-4/12 px-4">
                <rb-input
                    id="profile__mentorship_price"
                    :status="profile.errors?.mentorship_price ? 'error' : undefined"
                    :msg="profile.errors?.mentorship_price?.msg"
                    :disabled="!profile.fullInfo.is_mentor"
                    :label="profile.localization['profile/marketplace/mentorship/mentorship_price']"
                    :value="pointless(profile.fullInfo.mentorship_price)"
                    @update:value="onMentorshipPriceInput($event), profile.cleanError('mentorship_price')"
                >
                <template #beforeInput>
                    <strong class="text-2xl text-main whitespace-nowrap mt-1 mr-1">0.</strong>
                </template>
                </rb-input>
            </div>

            <div class="w-4/12 pl-4">
                <rb-input
                    id="profile__mentor_contact"
                    :status="profile.errors?.mentor_contact ? 'error' : undefined"
                    :msg="profile.errors?.mentor_contact?.msg"
                    :disabled="!profile.fullInfo.is_mentor"
                    :label="profile.localization['profile/marketplace/mentorship/mentor_contact']"
                    v-model:value="profile.fullInfo.mentor_contact"
                    @update:value="profile.cleanError('mentor_contact')" />
            </div>
        </div>

        <n-alert class="my-4 w-full rounded-md" type="warning">
            <div class="notes font-semibold text-xs mt-[2px]" v-html="profile.localization['profile/marketplace/mentorship/mentor_message']"></div>
        </n-alert>

        <section id="profile__mentor_notes">
            <quill-editor
                theme="snow"
                contentType="html"
                v-model:content="profile.fullInfo.mentor_message"
                @update:content="profile.cleanError('mentor_notes')">
            </quill-editor>
            <div v-if="profile.errors?.mentor_notes" class="w-full mt-2 text-red-400 text-right">{{ profile.errors.mentor_notes.msg }}</div>
        </section>
    </template>
</n-card>
</section>
</template>

<script>
// vue
import { ref, watch } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useProfile } from '@store/profile';

// icons
import { Warning24Filled, Dismiss16Filled, IosArrowRtl24Filled } from '@vicons/fluent';

// components
import RbInput from '@components/rb-input';
import RbSelect from '@components/rb-select';
import RbSwitch from '@components/rb-switch';

// editor
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

// naive-ui
import {
    NIcon,
    NCard,
    NAlert,
    NButton,
    NDivider,
    NCollapseTransition } from 'naive-ui';

export default {
    name: 'marketplace-settings-desktop',
    components: {
        NIcon,
        NCard,
        NAlert,
        NButton,
        RbInput,
        RbSelect,
        NDivider,
        RbSwitch,
        QuillEditor,
        Warning24Filled,
        Dismiss16Filled,
        IosArrowRtl24Filled,
        NCollapseTransition,
    },
    setup() {
    // store
        const gl = useGl();
        const refs = useRefs();
        const profile = useProfile();

        // vars
        const show = ref(false);

        watch(() => profile.fullInfo.is_mentor, v => {
            if (!v) {
                profile.cleanError('mentorship_price');
                profile.cleanError('mentor_contact');
                profile.cleanError('mentor_notes');
            };
        });

        const onMentorshipPriceInput = $event => {
            let newPrice = String($event);
            const dotIdx = newPrice.indexOf('.');
            if (dotIdx !== -1) {
                newPrice = newPrice.substring(dotIdx + 1);
            }

            if (/[^\d]/.test(newPrice)) {
                return;
            }
        
            profile.fullInfo.mentorship_price = $event;
        };

        const pointless = val => {
        // eslint-disable-next-line no-param-reassign
            val = String(val);
            const dotIdx = String(val).indexOf('.');
            return dotIdx === -1 ? val : val.substring(dotIdx + 1);
        };

        return {
            gl,
            refs,
            show,
            profile,
            pointless,
            onMentorshipPriceInput,
        };
    },
};
</script>