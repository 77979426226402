<template>
<!-- <n-skeleton v-if="gl.loading" :width="width" :sharp="false" size="large" /> -->
<div ref="filter" class="text-left w-max lg:flex lg:flex-row items-center">
    <div class="text-sm lg:text-base lg:mr-6" :class="{'opacity-40': gl.loading}">{{ label || refs.localization.grids.pager.rows_per_page_label }}</div>
    <n-radio-group
        class="mt-2 lg:mt-0"
        :value="size"
        :disabled="localLoading"
        :size="gl.screenWidth < 1024 ? 'small' : 'large'"
        @update:value="$emit('setPerPage', $event)">
        <n-radio-button
            v-for="el in options"
            :key="el.value"
            :value="el.value">
            {{ el.label }}
        </n-radio-button>
    </n-radio-group>
</div>
</template>

<script>
// vue
import { ref, computed, watch, onMounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';

// naive-ui
import { NRadioGroup, NRadioButton, NSkeleton} from 'naive-ui';

export default {
    name: 'grid-nav',
    props: {
        loading: Boolean,
        label: String,
        size: {
            type: Number,
            default: 10,
        },
        sizes: {
            type: Array,
            default: () => [10, 25, 50, 75, 100],
        },
        all: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        NSkeleton,
        NRadioGroup,
        NRadioButton,
    },
    setup(props, { emit }) {
        const gl = useGl();
        const refs = useRefs();
        const filter = ref();
        const width = ref();

        const localLoading = computed(() => gl.loading || props.loading);

        const options = computed(() => {
            const arr = props.sizes.map(el => ({
                value: el,
                label: el,
            }));

            if (props.all)
                arr.push({
                    value: '*',
                    label: refs.localization.grids.pager.all_label,
                });

            return arr;
        });

        onMounted(() => {
            width.value = filter.value.clientWidth;
        });

        return {
            gl,
            refs,
            width,
            filter,
            options,
            localLoading,
        };
    },
};
</script>