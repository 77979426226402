<template>
<header id="header">
    <section class="flex flex-row flex-none h-20 items-center justify-between px-12 bg-gray-100 dark:bg-darkbg">
        <div class="items-center flex lg:hidden text-lightMain" @click="showMMenu = true">
            <n-icon size="20">
                <LineHorizontal320Filled />
            </n-icon>
        </div>
        <div class="flex items-center">
            <img class="h-6 lg:h-8 dark:hidden" src="@assets/img/logo.svg" alt="logo">
            <img class="h-6 lg:h-8 hidden dark:block fill-white" src="@assets/img/darkLogo.svg" alt="logo">
            <n-button
                size="small"
                class="ml-4 rounded-2xl"
                @click="goOfficeV1">
                <div class="text-xs font-semibold">office V1</div>
            </n-button>
            <n-badge v-if="refs.tasks.enabled" :value="refs.countUnshiftedTask">
                <n-button
                    size="small"
                    class="ml-4 rounded-2xl"
                    @click="router.push({ name: 'tasks' })">
                    <div class="text-xs font-semibold">{{ refs.header?.tasks || 'Задания' }}</div>
                </n-button>
            </n-badge>
        </div>
        
        <div class="flex-row items-center justify-end hidden lg:flex">
            <!-- <n-skeleton v-if="gl.loading" class="mr-4" :width="100" :height="30" :sharp="false" /> -->

            <!-- <n-skeleton v-if="gl.loading" class="mr-4" circle size="large" /> -->
            <n-dropdown
                :options="qna"
                placement="bottom-start"
                trigger="click"
                size="small"
                @select="optionFn">
                <div class="mx-2 flex justify-center items-center hover:text-main hover:cursor-pointer text-lightMain">
                    <n-icon size="25">
                        <ChatBubblesQuestion16Regular />
                    </n-icon>
                </div>
            </n-dropdown>

            <!-- <n-skeleton v-if="gl.loading" class="mr-6" circle size="large" /> -->
            <div class="mx-2 flex justify-center items-center hover:cursor-pointer hover:text-main" @click="showNotifications = true">
                <n-badge :value="gl.notifications.length">
                    <n-icon size="25" class="text-lightMain hover:text-main">
                        <Alert16Regular />
                    </n-icon>
                </n-badge>
            </div>

            <div v-if="gl.ticker.length" class="flex-col mr-2 ml-6">
                <div
                    v-for="ticker in gl.ticker"
                    :key="ticker.name"
                    class="last:mt-[3px] flex items-center justify-between text-[11px] border border-gray-300 dark:border-gray-800 rounded-xl py-[3px] pl-[4px] pr-2 text-left">
                    <rb-coin class="w-4 h-4 my-0 mr-2" :coin="currency(ticker.name.split('/')[0].toLowerCase())" />
                    <div class="text-gray-500 dark:text-white/75 font-semibold">{{ (ticker.avg).toFixed(2) }}</div>
                </div>
            </div>

            <!-- <n-skeleton v-if="gl.loading" class="mr-4" circle size="large" /> -->
            <section class="ml-4 mr-2 text-lightMain">
                <div
                    class="flex justify-end items-center">
                    <span class="text-base font-semibold" :class="gl.balance?.balance > 0 ? 'text-green-600' : 'text-red-500'">
                        {{ gl.balance?.balance }}
                    </span>
                    <div class="relative ml-3">
                        <n-icon size="25">
                            <Wallet24Regular />
                        </n-icon>
                        <rb-coin class="w-5 absolute -bottom-1 -left-1" :coin="currency(env.profitSymbol)" />
                    </div>
                </div>
                <div
                    class="text-right"
                    :class="env.timeToShutdown ? '-mt-1' : ''">
                    &asymp; {{ gl.balance?.balance_usdt }} USDT
                </div>
                <n-tag v-if="gl.timeToShutdown?.timeToShutdown || gl.timeToShutdown?.timeToStop" type="error" size="small" class="text-[10px] h-4" round>
                    <span>{{ gl.timeToShutdown.status === 2 ? refs.localization.header.stop : refs.localization.header.suspend }}</span>
                    <strong>{{ gl.timeToShutdown.status === 2 ? gl.timeToShutdown?.timeToStop : gl.timeToShutdown?.timeToShutdown }}</strong>
                </n-tag>
            </section>
            
            
            <div
                class="ml-2 flex justify-center items-center hover:cursor-pointer text-lightMain hover:text-main"
                @click="router.push({ name: 'profile', query: { tab: 'account_info' } })">
                <n-icon size="25">
                    <Person16Regular />
                </n-icon>
            </div>

            <n-button
                ghost
                type="error"
                size="small"
                class="ml-4 rounded-md"
                @click="logOut">
                <div class="text-xs font-semibold text-red-400">{{ refs.header.logout }}</div>
            </n-button>

            <!-- <div class="text-left ml-6 dark:text-[#d1d1d1]">
                <div class="text-sm flex flex-row items-center">{{ env.email }}</div>
                <div class="text-base font-semibold">
                    {{ refs.header.balance }}
                    <span class="text-main">{{ env.balance }} BTC</span>
                </div>
                <div class="text-xs font-semibold pt-1">
                    <span class="text-main">{{ env.timeToShutdown || env.formattedTime }}</span>
                    {{ env.formattedDate }}
                </div>
            </div>

            <n-button
                round
                strong
                secondary
                class="ml-12"
                @click="logOut">
                <div class="text-xs font-semibold text-white uppercase">{{ refs.header.logout }}</div>
            </n-button> -->
        </div>
        <n-drawer v-model:show="showNotifications" :width="502" placement="right">
            <n-drawer-content :title="t('notifications')">
                <template v-if="gl.notifications.length">
                    <n-button class="mb-4 rounded-md text-white/90" :color="gl.mainColor" @click="onDeleteNotifications(null)">
                        {{ refs.localization.header.mark_all_as_read }}
                    </n-button>

                    <template v-for="item in gl.notifications" :key="item.id">
                        <n-card
                            class="mb-2 hover:no-underline cursor-pointer rounded-md"
                            @click="onNotificationClicked(item)"
                            content-style="padding: 10px 12px;">
                            <div>
                                <div class="flex justify-between items-center">
                                    <n-tag class="no-underline" :bordered="false" type="success">{{ item.timestamp }}</n-tag>
                                    <n-button
                                        tertiary circle type="error"
                                        size="tiny"
                                        @click.prevent.stop="onDeleteNotifications(item.id)">
                                        <template #icon>
                                            <n-icon class="text-red-500" size="10"><Dismiss16Filled /></n-icon>
                                        </template>
                                    </n-button>
                                </div>
                                <div class="text-sm underline">{{ item.title }}</div>
                            </div>
                        </n-card>
                    </template>
                </template>
                <template v-else>
                    <div class="text-base">{{ t('emptyNotifications') }}</div>
                </template>
            </n-drawer-content>
        </n-drawer>
        <base-modal-desktop
            :label="alert?.title"
            v-model:show="showAlert">
            <template #default>
                <div v-if="alert.allowHtml" class="realm-content" v-html="alert.content"></div>
                <div v-else class="realm-content">{{alert.content}}</div>
            </template>
            <template #footer>
                <div class="flex justify-end">
                    <n-button
                        class="rounded-md text-white/90"
                        :color="gl.mainColor"
                        @click="showAlert = false">
                        {{ alert.yepLabel }}
                    </n-button>
                </div>
            </template>
        </base-modal-desktop>
        <base-modal-desktop
            width="500px"
            :label="refs.localization.privateMessage.dialogHeader"
            v-model:show="showPrivateMessage">
            <template #default>
                <template v-for="(field, i) in dataFormPrivateMessage" :key="i">
                    <rb-input
                        class="mb-4"
                        :label="field.title"
                        :type="field.type"
                        :status="field.status === 'error' ? 'error' : undefined"
                        :msg="field.msg"
                        v-model:value="field.value"
                        @update:value="field.status = null, field.msg = null" />
                </template>

                <div class="flex justify-center">
                    <vue-recaptcha
                        ref="recaptcha"
                        sitekey="6LeQDWYaAAAAAPUoBoIEo2blRv4APQ5BDTYmf3MC"
                        size="normal" 
                        theme="light"
                        :hl="env.language"
                        @verify="recaptchaKey = $event">
                    </vue-recaptcha>
                </div>
            </template>
            <template #footer>
                <div class="flex justify-end">
                    <n-button
                        class="rounded-md text-white/90"
                        :color="gl.mainColor"
                        :loading="privateMessageLoading"
                        :disabled="privateMessageLoading"
                        @click="onPrivateMessageSubmit">
                        {{ refs.localization.privateMessage.sendBtn }}
                    </n-button>
                </div>
            </template>
        </base-modal-desktop>
    </section>
</header>

<!-- <pre>
    {{ gl.notifications[0] }}
</pre> -->
</template>

<script>
import { ref, reactive, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useCookies } from 'vue3-cookies';
import {
    NTag,
    NCard,
    NIcon,
    NBadge,
    NDrawer,
    NButton,
    NDivider,
    NPopover,
    NDropdown,
    NSkeleton,
    useMessage,
    useNotification,
    NDrawerContent } from 'naive-ui';

// shared
import util from '@shared/util';

// components
import RbCoin from '@components/rb-coin';
import RbInput from '@components/rb-input';
import vueRecaptcha from 'vue3-recaptcha2';
import LeftPanel from '@components/left-panel/index.vue';
    
import {
    Eye24Regular,
    Star12Filled,
    Wallet24Regular,
    Alert16Regular,
    Dismiss16Filled,
    EyeOff24Regular,
    Person16Regular,
    Question20Filled,
    CaretDown20Regular,
    MoreVertical16Filled,
    LocalLanguage16Filled,
    LineHorizontal320Filled,
    ChatBubblesQuestion16Regular } from '@vicons/fluent';

// store
import { useGl } from '@store/ts/gl';
import { useEnv } from '@store/ts/env';
import { useUser } from '@store/user';
import { useRefs } from '@store/ts/refs';
import { useProfile } from '@store/profile';
import { useDashBoard } from '@store/ts/dashBoard';
import { useProfileFullInfo } from '@store/profileFullInfo';

import LocalStorage from '@services/ts/localStorage';
import ProfileService from '@services/profileService';
import RealmService from '@services/realmService';

export default {
    name: 'header',
    components: {
        NTag,
        NCard,
        NIcon,
        RbCoin,
        NBadge,
        NButton,
        NDrawer,
        RbInput,
        NDivider,
        NPopover,
        NDropdown,
        NSkeleton,
        LeftPanel,
        Star12Filled,
        Eye24Regular,
        vueRecaptcha,
        Alert16Regular,
        NDrawerContent,
        Dismiss16Filled,
        EyeOff24Regular,
        Person16Regular,
        Wallet24Regular,
        Question20Filled,
        CaretDown20Regular,
        MoreVertical16Filled,
        LocalLanguage16Filled,
        LineHorizontal320Filled,
        ChatBubblesQuestion16Regular,
    },
    setup() {
        // store
        const router = useRouter();
        const route = useRoute();
        const profile = useProfile();

        const { cookies } = useCookies();

        // naive-ui
        
        const notification = useNotification();

        // объект пользователя
        const user = useUser();
        const refs = useRefs();
        const env = useEnv();
        const gl = useGl();
        const alert = ref();
        const recaptcha = ref();
        const showFAQ = ref(false);
        const showMMenu = ref(false);
        const showRMenu = ref(false);
        const showAlert = ref(false);
        const recaptchaKey = ref();
        const privateMessageLoading = ref(false);
        const showPrivateMessage = ref(false);
        const profileFullInfo = useProfileFullInfo();
        const showNotifications = ref(false);
        const showMNotifications = ref(false);
        const showLanguage = ref(false);

        const { t, tm, locale } = useI18n({ useScope: 'global' });

        const qna = computed(() => ([
            {
                label: refs.header.faq,
                key: 1,
                fn() {
                    window.open(
                        refs.lang === 'en'
                            ? 'https://desk.revenuebot.io/en/knowledgebase'
                            : 'https://desk.revenuebot.io/ru/%D0%B1%D0%B0%D0%B7%D0%B0-%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B9',
                        '_blank');
                },
            }, {
                label: refs.header.blog,
                key: 2,
                fn() {
                    window.open(
                        refs.lang === 'en'
                            ? 'https://blog.revenuebot.io/'
                            : 'https://blog.revenuebot.io/ru/',
                        '_blank');
                },
            }, {
                label: refs.header.referral_program,
                key: 3,
                fn() {
                    router.push({ name: 'profile', query: { tab: 'referral_info' }});
                },
            }, {
                label: refs.header.ticket_supper,
                key: 4,
                fn() {
                    window.open(
                        refs.lang === 'en'
                            ? 'https://desk.revenuebot.io/en/tickets/create/step1'
                            : 'https://desk.revenuebot.io/ru/tickets/create/step1',
                        '_blank');
                },
            }, {
                label: '@revenuebot_user',
                key: 5,
                fn() {
                    window.open(
                        refs.lang === 'ru'
                            ? 'https://t.me/revenuebot_user'
                            : 'https://t.me/revenuebot_users_en',
                        '_blank');
                },
            }, {
                label: refs.privateMessage.menuTitle,
                key: 6,
                fn() {
                    showPrivateMessage.value = true;
                },
            }, {
                label: refs.localization.header.free_consultations,
                key: 7,
                fn() {
                    window.open('https://appt.link/consultation_from_support/revenuebot', '_blank');
                },
            }, {
                label: refs.localization.header.educational_videos,
                key: 8,
                fn() {
                    window.open('https://www.youtube.com/channel/UCrAyFAxvDzPIwYKgdSDwCFg', '_blank');
                },
            },
        ]));

        const userOptions = computed(() => ([
            // {
            //     label: profileRefs.localization.profile_account_info_title,
            //     key: 1,
            //     fn() {
            //         // router.push('#');
            //     },
            // }, {
            //     type: 'divider',
            //     key: 'd1',
            // },
            {
                label: refs.header.logout,
                key: 2,
                fn() {
                    cookies.remove('rb-session', null, '.revenuebot.io');
                    document.location.href = 'https://revenuebot.io/';
                },
            },
        ]));

        const dataFormPrivateMessage = reactive({
            to: {
                value: '',
                type: 'text',
                status: undefined,
                msg: undefined,
                title: refs.localization.privateMessage.recipientId,
            },
            subject: {
                value: '',
                type: 'text',
                status: undefined,
                msg: undefined,
                title: refs.localization.privateMessage.subject,
            },
            message: {
                value: '',
                type: 'textarea',
                status: undefined,
                msg: undefined,
                title: refs.localization.privateMessage.message,
            },
        });

        const onDeleteNotifications = async id => {
            gl.showLoading = true;

            let ids = id ? [id] : gl.notifications.map(({ id }) => id);

            try {
                const data = await ProfileService.markNotificationsAsRead({ ids });

                if (!data?.data && data.msg) {
                    gl.showNotification({
                        type: 'error',
                        msg: data.msg,
                    });
                } else {
                    gl.notifications = gl.notifications.filter(el => !ids.find(id => id === el.id));

                    if (ids.length > 1)
                        showNotifications.value = false;
                };
            } catch {
                gl.showNotification({
                    type: 'error',
                    msg: t('errorMessage'),
                });
            }

            gl.showLoading = false;
        };

        const onNotificationClicked = async n => {
            gl.showLoading = true;

            if (Array.isArray(n.realm)) {
                try {
                    const prepare = await RealmService.describeInternal(n.realm[0]);
                    
                    if (prepare.data?.errors?.length) {
                        prepare.data.errors.forEach(({ msg }) => {
                            gl.showNotification({
                                type: 'error',
                                msg,
                            });
                        });
                    } else {
                        gl.notifications = gl.notifications.filter(({ id }) => id !== n.id);
                        alert.value = prepare.data.alert;
                        showAlert.value = true;
                    };
                } catch {
                    gl.showNotification({
                        type: 'error',
                        msg: t('errorMessage'),
                    });
                }
            } else if (n.url) {
                window.location.href = n.url;
            }

            gl.showLoading = false;
        };

        const optionFn = (_, item) => {
            item.fn();
        };

        const logOut = async () => {
            gl.showLoading = true;

            try {
                const preapare = await ProfileService.logout();

                console.log('preapare', preapare);

                if (preapare.data.status) {
                    document.location.href = preapare.data.logout.redirectTo;
                }

            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };

            gl.showLoading = false;
        };

        const onPrivateMessageSubmit = async () => {
            privateMessageLoading.value = true;
            const msg = {};
            
            Object.entries(dataFormPrivateMessage).forEach(([ key, value ]) => {
                msg[key] = value.value;
            });

            msg.from = env.ID;
            msg['_formPath'] = 'pmsg';
            msg['g-recaptcha-response'] = recaptchaKey.value;
            
            try {
                const prepare = await ProfileService.privateMessage(msg);

                if (!prepare.data.status) {
                    if (prepare.data?.errors_form && Object.keys(prepare.data?.errors_form).length) {

                        for (let key in dataFormPrivateMessage) {
                            const fields = prepare.data.errors_form.pmsg.fields;
                            const el = Object.keys(fields).find(el => el === key);

                            if (el) {
                                dataFormPrivateMessage[key].status = 'error';
                                dataFormPrivateMessage[key].msg = fields[el].msg;
                            } else {
                                dataFormPrivateMessage[key].status = 'success';
                                dataFormPrivateMessage[key].msg = undefined;
                            }
                        }
                    }
                } else {
                    showPrivateMessage.value = false;
                    // clean dataFormPrivateMessage
                    for (let key in dataFormPrivateMessage)
                        dataFormPrivateMessage[key].value = '';
                }

                if (prepare.postMessages)
                    prepare.postMessages.forEach(el => {
                        gl.showNotification({
                            type: el.success ? 'success' : 'error',
                            msg: el.msg,
                        });
                    });
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };

            recaptcha.value.reset();
            privateMessageLoading.value = false;
        };

        const currency = icon => refs.currencies.find(el => el.id === icon);

        const goOfficeV1 = () => {
            let url = new URL(window.location.href);
            window.open(url.origin + '/officev1/', '_blank');
        };

        return {
            t,
            gl,
            qna,
            env,
            refs,
            alert,
            locale,
            router,
            showFAQ,
            showRMenu,
            showAlert,
            showMMenu,
            recaptcha,
            userOptions,
            recaptchaKey,
            showLanguage,
            profileFullInfo,
            showNotifications,
            showMNotifications,
            showPrivateMessage,
            dataFormPrivateMessage,
            privateMessageLoading,
            onPrivateMessageSubmit,
            logOut,
            optionFn,
            currency,
            goOfficeV1,
            onNotificationClicked,
            onDeleteNotifications,
        };
    },
};
</script>

<style lang="scss" scoped>
.realm-content {
    :deep(a) {
        color: #4949D9;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}
</style>