<template>
<div
    v-if="isFooter"
    class="h-full flex justify-center items-center text-main text-md dark:bg-darkCardBg bg-gray-50 font-semibold relative overflow-hidden">
    {{ dataFooter }} <div class="absolute w-full top-0 left-0 h-[2px] bg-main shadow-xl"></div>
</div>
<template v-else>
    <div
        v-if="['marketplace.i_mentor', 'marketplace.my_mentors'].includes(type)"
        class="h-full flex justify-center items-center px-2"
        :class="classes">
        <n-button
            v-if="data === 'confirmed'"
            round
            size="tiny"
            strong="true"
            type="primary"
            @click="$emit('doSomething', ['onQuestionInfo', row.id])">
            {{ marketplace.localization?.['marketplace/my_mentors/grid/mentorship_done'] }}
        </n-button>
        <n-button
            v-else-if="data === 'expired'"
            round
            size="tiny"
            strong="true"
            type="warning"
            @click="$emit('doSomething', ['onQuestionInfo', row.id])">
            {{ marketplace.localization?.['marketplace/my_mentors/grid/mentorship_done'] }}
        </n-button>
        <div v-else class="text-center text-[10px] font-semibold">{{ marketplace.mentorshipRequestStates.find(({ id }) => id === row.state).title }}</div>
    </div>
    <div
        v-else
        class="h-full flex flex-col justify-center items-center px-2 text-[10px] text-current text-center"
        :class="classes">
        {{ localState[0] }}
        <n-popover
            v-if="localState[1] !== '()'"
            trigger="click"
            placement="left"
            class="bg-main max-w-[200px]"
            :arrow-style="{
                backgroundColor: gl.mainColor,
            }">
            <template #trigger>
                <div class="px-1">
                    <n-icon class="inline cursor-pointer">
                        <QuestionCircle16Filled class="text-main text-xl" />
                    </n-icon>
                </div>
            </template>
            <template #default>
                <div class="text-white/90 text-[10px]" v-html="localState[1]"></div>
            </template>
        </n-popover>
    </div>
</template>
</template>

<script>
// vue
import { computed, onMounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useBots } from '@/store/bots';
import { useMarketplace } from '@store/ts/marketplace';

// naive-ui
import {
    NIcon,
    NButton,
    NPopover } from 'naive-ui';

// icons
import {
    QuestionCircle16Filled } from '@vicons/fluent';

export default {
    name: 'cell-state',
    props: {
        isFooter: {
            type: Boolean,
            default: false,
            required: true,
        },
        dataFooter: {
            default: '',
        },
        data: {
            required: true,
        },
        classes: {
            type: String,
        },
        row: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
    },
    components: {
        NIcon,
        NButton,
        NPopover,
        QuestionCircle16Filled,
    },
    setup(props) {
        // store
        const gl = useGl();
        const bots = useBots();
        const marketplace = useMarketplace();

        const localState = computed(() => {
            if (['bots', 'stats'].includes(props.type)) {
                const i = props.row.state_txt.indexOf('(');

                return [
                    props.row.state_txt.slice(0, i - 1),
                    props.row.state_txt.slice(i),
                ];
            }

            return '';
        });

        return {
            gl,
            localState,
            marketplace,
        };
    },
};
</script>