<template>
<section>
    <slot name="header"></slot>
    <slot name="default"></slot>
    <n-divider v-if="$slots.footer" class="my-4" />
    <slot name="footer"></slot>
</section>
</template>

<script>
// naive-ui
import {
    NDivider } from 'naive-ui';

export default {
    name: 'rb-api-key-cr-blank',
    components: {
        NDivider,
    },
    setup() {
        // 
    },
};
</script>