import { defineStore } from 'pinia';

export const useConfirm = defineStore('confirm', {
    state: () => ({
        show: false,
        title: '',
        fn: null,
        args: null,
    }),
    getters: {
        // localization(state) {
        //     return state.refs?.localization[Object.keys(state.refs.localization)[0]];
        // },
    },
    actions: {
        openConfirm({ title, fn, args }) {
            if (!title) return;

            this.show = true;
            this.title = title;
            this.fn = fn;
            this.args = args;
        },
        closeConfirm() {
            this.show = false;

            setTimeout(() => {
                this.clearData();
            }, 200);
            
        },
        clearData() {
            this.show = false;
            this.title = '';
            this.fn = null;
            this.args = null;
        },
        doFn() {
            this.show = false;
            this.fn(this.args);
        },
    },
});