// vue
import { h, ref, computed, reactive, watch, onMounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useEnv } from '@store/ts/env';
import { useRefs } from '@store/ts/refs';

// i18n
import { useI18n } from 'vue-i18n';

// components
import RbCoin from '@components/rb-coin';

// naive-ui
import { useMessage, useNotification } from 'naive-ui';

// services
import ProfileService from '@services/profileService';

// shared
import { crudPagerFactory } from '@shared/ts/factories';

export default function (props) {
    // store
    const gl = useGl();
    const env = useEnv();
    const refs = useRefs();

    // vars
    const notification = useNotification();
    const currentMethodPay = ref('crypt');
    const showModal = ref(false);
    const selectionCurrency = ref();
    const showCurrencys = ref(false);
    const amounts = reactive({
        paypalAmount: 50,
        cardsAmount: 50,
    });
    const loadings = reactive({
        generateWallet: false,
    });

    const dataForm = reactive({});

    const width = 500, height = 600;
    const left = (screen.width/2)-(width/2);
    const top = (screen.height/2)-(height/2);

    const btns = reactive({
        PayPal: {
            label: refs.localization.topUpBalance?.pay_with_paypal || 'PAY WITH PAYPAL',
            fn: () => {
                if (dataForm.PayPal.value < topUpBalanceDefaultAmounts.value.min_amount) {
                    dataForm.PayPal.status = 'error';
                    dataForm.PayPal.msg = `${refs.localization.topUpBalance?.min_replenishment_amount} ${topUpBalanceDefaultAmounts.value.min_amount} USDT`;
                } else if (dataForm.PayPal.value > topUpBalanceDefaultAmounts.value.max_amount) {
                    dataForm.PayPal.status = 'error';
                    dataForm.PayPal.msg = `${refs.localization.topUpBalance?.max_replenishment_amount} ${topUpBalanceDefaultAmounts.value.max_amount} USDT`;
                } else {
                    dataForm.PayPal.status = undefined;
                    dataForm.PayPal.msg = undefined;
                    window.open(`https://app.revenuebot.io/external/pay/paypal/pay/?amount=${dataForm.PayPal.value}`,
                        'joe',
                        `height=${height}, width=${width}, top=${top}, left=${left}`);
                }
            },
        },
        Stripe: {
            label: refs.localization.topUpBalance?.pay_with_stripe || 'PAY WITH STRIPE',
            fn: () => {
                if (dataForm.Stripe.value < topUpBalanceDefaultAmounts.value.min_amount) {
                    dataForm.Stripe.status = 'error';
                    dataForm.Stripe.msg = `${refs.localization.topUpBalance?.min_replenishment_amount} ${topUpBalanceDefaultAmounts.value.min_amount} USDT`;
                } else if (dataForm.Stripe.value > topUpBalanceDefaultAmounts.value.max_amount) {
                    dataForm.Stripe.status = 'error';
                    dataForm.Stripe.msg = `${refs.localization.topUpBalance?.max_replenishment_amount} ${topUpBalanceDefaultAmounts.value.max_amount} USDT`;
                } else {
                    window.open(`https://app.revenuebot.io/external/pay/stripe/pay/?amount=${dataForm.Stripe.value}`,
                        'joe',
                        `height=${height}, width=${width}, top=${top}, left=${left}`);
                }
            },
        },
    });

    // naive-ui
    

    // 18n
    const { t } = useI18n();

    watch(() => props.show, () => {
        setTimeout(() => {
            selectionCurrency.value = null;
            currentMethodPay.value = 'crypt';
        }, 300);
    });

    const topUpBalanceDefaultAmounts = computed(() => {
        if (currentMethodPay.value === 'crypt') return null;

        return env.topUpBalanceDefaultAmounts.find(({ id }) => id === currentMethodPay.value);
    });

    const topupSelectMethods = computed(() => {
        if (!Array.isArray(env.topupMethods)) return [];

        return env.topupMethods.map(cur => {
            const curEntry = refs.currencies.find(({ id }) => id === cur.currency);
            if (curEntry) {
                cur.label = cur.title;
                cur.value = cur.id;

                return {...curEntry, ...cur};
            }
            return null;
        }).filter(cur => cur);
    });

    const renderLabel = (option) => {
        return h(
            'div',
            {
                style: {
                    display: 'flex',
                    alignItems: 'center',
                },
            },
            [
                h(RbCoin, {
                    class: 'my-2 h-6',
                    coin: option,
                }),
                h(
                    'div',
                    {
                        style: {
                            marginLeft: '8px',
                            padding: '4px 0',
                        },
                    },
                    [
                        h('div', null, [option.label]),
                    ],
                ),
            ],
        );
    };

    const setSelectionCurrency = e => {
        selectionCurrency.value = topupSelectMethods.value.find(({ id }) => id === e);
        showCurrencys.value = false;
    };

    const copy = s => {
        navigator.clipboard.writeText(s);
        gl.showNotification({
            type: 'success',
            msg: t('copied'),
        });
    };

    const currency = icon => refs.currencies.find(el => el.id === icon);

    const generateWallet = async () => {
        loadings.generateWallet = true;

        const { id } = selectionCurrency.value;
        try {
            const result = await ProfileService.generateWallet({
                method: id,
                pager: crudPagerFactory(),
            });

            const i = env.topupMethods.findIndex(method => method.id === id);

            if (~i) {
                env.topupMethods[i] = {
                    ...env.topupMethods[i],
                    ...result.data.data,
                };

                setSelectionCurrency(id);
            }
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        loadings.generateWallet = false;
    };

    const dataFormPrepare = () => {
        env.topUpBalanceDefaultAmounts.forEach(el => dataForm[el.id] = {
            ...el,
            status: undefined,
            msg: undefined,
            loading: false,
            value: el.default,
        });
    };

    onMounted(() => {
        dataFormPrepare();
    });

    return {
        gl,
        env,
        refs,
        btns,
        amounts,
        showModal,
        loadings,
        dataForm,
        showCurrencys,
        currentMethodPay,
        selectionCurrency,
        topupSelectMethods,
        topUpBalanceDefaultAmounts,
        t,
        copy,
        currency,
        renderLabel,
        generateWallet,
        setSelectionCurrency,
        onlyAllowNumber: value => !value || /^\d+[.,]?\d{0,10}$/.test(value),
    };
}