<template>
<component
    :is="component"
    :label="title"
    :show="show"
    @update:show="!$event ? $emit('update:show', $event) : null">
    <template #default>
        <div v-if="!init" class="flex h-full justify-center">
            <n-spin size="small" />
        </div>
        <template v-else>
            <section v-if="!onlyCreate" class="mb-6">
                <rb-label
                    :label="label.text || apiKeys.localization.apikeys_key_f"
                    :helpers="label.helpers" />

                <div class="mt-4 flex items-center">
                    <div class="border-b border-gray-200 dark:border-gray-600 flex-grow"></div>
                    <rb-radio-group
                        v-model:value="apiOptions"
                        size="small"
                        :options="assignmentTypes" />
                    <div class="border-b border-gray-200 dark:border-gray-600 flex-grow"></div>
                </div>
            </section>
            <template v-if="!onlyCreate && apiOptions == 'fast_api'">
                <n-alert v-if="exchangeMeta2Info" class="rounded-md" id="fast_api" type="warning" size="small">
                    <div class="notes text-[10px]" v-html="exchangeMeta2Info"></div>
                </n-alert>
                <slot name="afterFastApi"></slot>
            </template>
            <template v-else-if="!onlyCreate && apiOptions == 'select'">
                <rb-select
                    v-if="localApiKeysList.length"
                    :loading="loadings.apiKey"
                    :disabled="loadings.apiKey"
                    :label="apiKeys.localization?.select_api_key || 'Выберите Api ключ'"
                    :options="localApiKeysList"
                    :value="apiKeyValue"
                    @update:value="$emit('update:apiKeyValue', $event)" />
                <n-alert
                    v-else class="w-full mt-4 rounded-md" type="error">
                    <div class="text-[10px] note">{{ apiKeys.localization?.no_keys_for_exchange || 'Нет ключей для данной биржи, создайте новый' }}</div>
                </n-alert>
                <slot name="afterSelect"></slot>
            </template>
            <template v-else>
                <section class="flex flex-col">
                    <n-alert v-if="exchangeMetaInfo" class="rounded-md" type="warning" size="small">
                        <div class="notes text-[10px]" v-html="exchangeMetaInfo"></div>
                    </n-alert>
                    <n-alert v-if="exchangeMeta2Info && !fastapiEnabled" class="rounded-md mb-4" type="warning" size="small">
                        <div class="notes text-[10px]" v-html="exchangeMeta2Info"></div>
                    </n-alert>
                    <section>
                        <div class="flex items-start">
                            <rb-input
                                id="name"
                                class="w-6/12 pr-2"
                                :label="dataForm.name.title"
                                :status="dataForm.name.status === 'error' ? 'error' : undefined"
                                :msg="dataForm.name.msg"
                                :placeholder="dataForm.name.placeholder"
                                v-model:value="dataForm.name.value"
                                @update:value="dataForm.name.status = null, dataForm.name.msg = null" />
                                
                            <div class="w-6/12 pl-2 flex items-end">
                                <rb-select
                                    :label="dataForm.exchange.title"
                                    :options="exchangeOptions"
                                    :disabled="isUpdate || exchangeId"
                                    :status="dataForm.exchange.status === 'error' ? 'error' : undefined"
                                    :msg="dataForm.exchange.msg"
                                    class="flex-grow"
                                    v-model:value="dataForm.exchange.value"
                                    @update:value="dataForm.exchange.status = null, dataForm.exchange.msg = null" />
                                <div
                                    v-if="isExchangeSelected"
                                    class="rb-td-stock-icon ml-2 mb-[10px] h-5"
                                    :class="`rb-td-stock-icon-${exchange(dataForm.exchange.value)?.code_name}`">
                                </div>
                            </div>
                        </div>
                    </section>

                    <section v-if="fastapiEnabled && onlyCreate && isCreate" class="flex items-center mt-6 mb-4">
                        <div class="border-b border-gray-200 dark:border-gray-600 flex-grow"></div>
                        <rb-radio-group
                            v-model:value="apiOptionsOnlyForCreate"
                            :options="assignmentTypesOnlyForCreate" />
                        <div class="border-b border-gray-200 dark:border-gray-600 flex-grow"></div>
                    </section>
                    <n-divider v-else class="mb-2" />

                    <template v-if="apiOptionsOnlyForCreate == 'fast_api'">
                        <n-alert v-if="exchangeMeta2Info" class="rounded-md mt-4" id="fast_api" type="warning" size="small">
                            <div class="notes text-[10px]" v-html="exchangeMeta2Info"></div>
                        </n-alert>
                    </template>

                    <template v-else-if="apiOptionsOnlyForCreate == 'create'">
                        <rb-input
                            :label="dataForm.key.title"
                            :placeholder="dataForm.key.placeholder"
                            :status="dataForm.key.status === 'error' ? 'error' : undefined"
                            :msg="dataForm.key.msg"
                            v-model:value="dataForm.key.value"
                            @update:value="dataForm.key.status = null, dataForm.key.msg = null" />
                        <n-divider class="mb-2" />

                        <rb-input
                            :label="dataForm.sign.title"
                            :placeholder="dataForm.sign.placeholder"
                            :status="dataForm.sign.status === 'error' ? 'error' : undefined"
                            :msg="dataForm.sign.msg"
                            v-model:value="dataForm.sign.value"
                            @update:value="dataForm.sign.status = null, dataForm.sign.msg = null" />

                        <slot name="afterCreate"></slot>
                    </template>
                </section>
            </template>
        </template>
    </template>
    <template #footer v-if="init && apiOptions == 'create'">
        <rb-checkbox
            size="small"
            :label="dataForm.show_dashboard.title"
            v-model:checked="dataForm.show_dashboard.value" />
        <n-button
            size="small"
            class="rounded-md text-white/90 w-full mt-4"
            :color="gl.mainColor"
            :disabled="!canDoingSomethingWidthApiKey || localLoading"
            :loading="localLoading"
            @click="onDoingSomethingWithApiKey">
            {{ title }}
        </n-button>
    </template>
</component>
</template>

<script>
// general
import general from './general';

// naive-ui
import {
    NSpin,
    NAlert,
    NDivider,
    NButton } from 'naive-ui';

export default {
    name: 'rb-api-key-cr-desktop',
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        exchangeId: {
            type: [String, Number],
            default: null,
        },
        apiKeyId: {
            type: [String, Number],
            default: null,
        },
        data: {
            type: Object,
            default: () => ({}),
        },
        likeAModal: {
            type: Boolean,
            default: true,
        },
        apiKeysList: {
            type: Array,
            default: () => ([]),
        },
        onlyCreate: {
            type: Boolean,
            default: true,
        },
        mainTitle: {
            type: String,
            default: null,
        },
        apiKeyValue: {
            type: [String, Number],
            default: -1,
        },
        label: {
            type: Object,
            default: () => ({
                text: '',
                helpers: [],
            }),
        },
    },
    components: {
        NSpin,
        NAlert,
        NButton,
        NDivider,
    },
    setup(props, context) {
        return {
            ...general(...arguments),
        };
    },
};
</script>