<template>
<n-drawer
    :auto-focus="false"
    :show="show"
    width="100%"
    class="bg-gray-100 dark:bg-darkbg"
    @after-leave="$emit('leave')">
    <n-drawer-content :body-content-style="`padding: 0 ${xPadding ? '12px' : '0'}`" :auto-focus="false">
        <div class="flex flex-col h-full">
            <div class="relative w-full bg-gray-100 dark:bg-darkbg text-sm text-center text-gray-600 dark:text-white/75 py-4 min-h-[55px]">
                <n-icon
                    size="20"
                    class="text-gray-600 dark:text-white/75 absolute top-1/2 -translate-y-1/2"
                    :class="xPadding ? 'left-0' : 'left-4'"
                    @click="$emit('update:show', false)">
                    <ArrowLeft12Regular />
                </n-icon>
                {{ label }}
            </div>
            <div class="pb-4 flex-grow overflow-y-scroll overscroll-none scrollbar-hide">
                <div v-if="loading" class="flex h-full justify-center">
                    <n-spin size="small" />
                </div>
                <template v-else>
                    <slot name="default"></slot>
                </template>
            </div>
            <div v-if="$slots.footer" class="w-full sticky bottom-0 z-50 py-2">
                <slot name="footer"></slot>
            </div>
        </div>
    </n-drawer-content>
</n-drawer>
</template>

<script>
// vue
import { ref, reactive, computed, watch, onMounted } from 'vue';

// services
import ApiKeysService from '@services/apiKeysService';
import MarketplaceService from '@services/marketplace';
import ExchangesService from '@services/exchangesService';
import WalletsService from '@services/walletsService';

// router
import { useRoute, useRouter } from 'vue-router';

// store
import { useGl } from '@store/ts/gl';
import { useEnv } from '@store/ts/env';
import { useRefs } from '@store/ts/refs';
import { useMarketplace } from '@store/ts/marketplace';

// naive-ui
import {
    NIcon,
    NCard,
    NSpin,
    NTable,
    NAlert,
    NInput,
    NSelect,
    NButton,
    NDrawer,
    NDivider,
    NCollapse,
    useMessage,
    NRadioGroup,
    NRadioButton,
    NDrawerContent,
    NCollapseItem } from 'naive-ui';

// components
import RbCoin from '@components/rb-coin';
import RbInput from '@components/rb-input/mobile.vue';
import RbSelect from '@components/rb-select/mobile.vue';
import RbDepo from '@components/rb-depo/index.vue';
import RbWalletSelectMobile from '@components/rb-wallet-select/mobile.vue';
import SuperTable from '@components/super-table/mobile.vue';

// icons
import { Warning24Filled, ArrowLeft12Regular } from '@vicons/fluent';

// i18n
import { useI18n } from 'vue-i18n';

export default {
    name: 'base-drawer-mobile',
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        xPadding: {
            type: Boolean,
            default: true,
        },
        label: {
            type: String,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        NIcon,
        NSpin,
        NDrawer,
        NDrawerContent,
        ArrowLeft12Regular,
    },
    setup() {
    //
    },
};
</script>