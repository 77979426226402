// vue
import { ref, reactive, computed, watch, onMounted } from 'vue';

// services
import ApiKeysService from '@services/apiKeysService';
import MarketplaceService from '@services/marketplace';
import ExchangesService from '@services/exchangesService';
import WalletsService from '@services/walletsService';

// router
import { useRoute, useRouter } from 'vue-router';

// shared
import { crudPagerFactory } from '@shared/ts/factories';

// store
import { useGl } from '@store/ts/gl';
import { useEnv } from '@store/ts/env';
import { useRefs } from '@store/ts/refs';
import { useMarketplace } from '@store/ts/marketplace';

// naive-ui
import { useMessage } from 'naive-ui';

// i18n
import { useI18n } from 'vue-i18n';

export default function (props, context) {
    // router
    const route = useRoute();
    const router = useRouter();

    // i18-n
    const { t } = useI18n();

    // store
    const gl = useGl();
    const env = useEnv();
    const refs = useRefs();
    const marketplace = useMarketplace();

    // filters
    const filters = reactive(crudPagerFactory({
        market_dashboard: true,
    }, {
        perPage: '*',
    }));

    // vars
    const bot = ref();
    watch(() => bot.value?.data.general.exchange, async v => {
        exchangeInfo.value = ( await ExchangesService.getFullInfo(v) ).data;

        walletsRefs.value = ( await WalletsService.getWalletsList({
            exchanges: [bot.value.data.general.exchange],
            apikeys: [-1],
            pairs: [bot.value.data.general.pair],
            currencies: [bot.value.data.general.wallet_currency],
        }) ).data.records;
    });

    watch(() => props.show, v => {
        if (v) {
            getData();
        } else {
            apiKey.value = null;
            wallet.value = null;

            for (let key in walletModel)
                walletModel[key].value = '';
        }
    });

    // vars
    const init = ref(false);
    const notes = ref();
    const apiKey = ref();
    const wallet = ref();
    const newBotId = ref();
    const walletsRefs = ref();
    const exchangeInfo = ref();
    const balancesRefs = ref();
    const ownerContact = ref();
    const successMessage = ref();
    const configBought = ref(false);
    const apiOptions = ref('select');
    const canCreateWallet = ref(false);
    const walletOptions = ref('select');
    const loadings = reactive({
        apiKey: false,
        createWallet: false,
        buyBot: false,
        checkBalance: false,
    });

    const columns = computed(() => [
        {
            name: 'config_price',
            title: marketplace.localization?.['marketplace/review/config_price'],
            isSortable: false,
        }, {
            name: 'exchange',
            title: marketplace.localization?.['marketplace/review/exchange'],
            isSortable: false,
        }, {
            name: 'algo',
            title: marketplace.localization?.['marketplace/review/algo'],
            isSortable: false,
        }, {
            name: 'pair',
            title: marketplace.localization?.['marketplace/review/pair'],
            isSortable: false,
        }, {
            name: 'depo_usdt',
            title: marketplace.localization?.['marketplace/review/profit'],
            isSortable: false,
        }, {
            name: 'total_profit',
            title: marketplace.localization?.['marketplace/review/total_profit'],
            isSortable: false,
        },
    ]);

    const records = computed(() => [
        {
            config_price: bot.value.data.general.config_price,
            exchange: bot.value.data.general.exchange,
            algo: bot.value.data.general.algo,
            pair: bot.value.data.general.pair,
            depo_usdt: bot.value.data.general.profit,
            _depo_usdt_symbol:bot.value.data.general.profit_coin,
            total_profit: bot.value.data.general.total_profit,
            _total_profit_symbol: bot.value.data.general.total_profit_coin,
        },
    ]);

    const buyBotModel = reactive({
        name: {
            value: '',
            title: marketplace.localization?.['marketplace/buy/review/bot/label'],
            placeholder: marketplace.localization?.['marketplace/buy/review/bot/placeholder'],
            status: undefined,
            msg: undefined,
        },
    });

    const walletModel = reactive({
        name: {
            value: '',
            title: marketplace.localization?.['marketplace/buy/review/wallet/name/label'],
            placeholder: marketplace.localization?.['marketplace/buy/review/wallet/name/placeholder'],
            status: undefined,
            msg: undefined,
            class: 'flex-grow w-auto',
            currency: undefined,
        },
        amount: {
            value: '',
            title: marketplace.localization?.['marketplace/buy/review/wallet/amount/label'],
            placeholder: '',
            status: undefined,
            msg: undefined,
            class: 'w-[100px]',
            currency: undefined,
        },
    });

    const botActionLinks = computed(() => [
        {
            routerLink: true,
            link: marketplace.localization?.['marketplace/buy/review/postinfo/edit_bot'],
            text: marketplace.localization?.['marketplace/buy/review/postinfo/edit_bot_descr'],
            path: `/bots/edit/${newBotId.value}`,
        }, {
            routerLink: true,
            link: marketplace.localization?.['marketplace/buy/review/postinfo/bots_list'],
            text: marketplace.localization?.['marketplace/buy/review/postinfo/bots_list_descr'],
            path: '/bots/',
        }, {
            routerLink: true,
            link: marketplace.localization?.['marketplace/buy/review/postinfo/bots_stats'],
            text: marketplace.localization?.['marketplace/buy/review/postinfo/bots_stats_list'],
            path: '/trade-statistic/',
        }, {
            routerLink: false,
            text: marketplace.localization?.['marketplace/buy/review/postinfo/how_to_control'],
        }, {
            routerLink: false,
            text: marketplace.localization?.['marketplace/buy/review/postinfo/how_to_stats'],
        },
    ]);

    const isApiKeySelected = computed(() => apiKey.value != -1 && apiKey.value != null);
    const isWalletSelected = computed(() => wallet.value != -1 && wallet.value != null);

    const pairSymbols = computed(() => String(bot.value.data.general.pair).split('/').map(s => s.trim()));

    const isExchangeCoin = computed(() => !!exchangeInfo.value?.extra?.coin == 1);

    const isExchangeTrailingstop = computed(() => !!exchangeInfo.value?.extra?.trailingstop == 1);

    const isExchangeFutures = computed(() => !!exchange(bot.value.data.general.exchange).futures);

    const coinSymbolForWallet = computed(() => {
        const [fSymbol, sSymbol] = pairSymbols.value;
        if (isExchangeCoin.value)
            return fSymbol;

        if (isExchangeFutures.value)
            return sSymbol;

        return bot.value.data.general.algo === 'long'
            ? sSymbol
            : (bot.value.data.general.algo === 'short' ? fSymbol : null);
    });

    const pairWalletsRefs = computed(() => {
        if (!walletsRefs.value || bot.value.data.general.algo == -1) return [];
        const symbolForWallet = coinSymbolForWallet.value;
        return walletsRefs.value.filter(w => w.currency == symbolForWallet);
    });

    const confIntro = computed(() => {
        let intro = marketplace.localization?.['marketplace/buy/review/intro'];

        if (bot.value)
            Object.keys(bot.value.data.general)
                .forEach(key => intro = intro.split('{' + key + '}').join(bot.value.data.general[key]));

        return intro;
    });

    const exchangeId = computed(() => bot.value?.data?.general?.exchange);
    const exchangeMeta2Info = computed(() => exchange(bot.value.data.general.exchange)?.meta2);
    const exchangeMeta3Info = computed(() => exchange(bot.value.data.general.exchange)?.meta3);

    const walletCurrency = computed(() => {
        if (!walletsRefs.value) return -1;
        let localWallet = walletsRefs.value.find(w => w.id == wallet.value);
        return localWallet?.currency || -1;
    });

    const symbolBalance = computed(() => balancesRefs.value?.[walletCurrency.value] || '');
    const symbolTotalBalance = computed(() => balancesRefs.value?.[`${walletCurrency.value}_total`] || '');

    const isApiKeyReady = computed(() => apiOptions.value === 'select' && (apiKey.value && apiKey.value != -1));
    const isWalletReady = computed(() => walletOptions.value === 'select' && (wallet.value && wallet.value != -1));

    const canBuyConfiguration = computed(() => buyBotModel.name.value.trim() && isApiKeyReady.value && isWalletReady.value);

    const exchange = (exchange) => {
        return refs.exchanges.find(el => el.id === exchange);
    };

    const algos = (algo) => {
        return marketplace.algos.find(el => el.id === algo);
    };

    const currency = (icon) => {
        return refs.currencies.find(el => el.id === icon);
    };

    const getBotName = () => {
        const exchangeTitle = exchange(bot.value.data.general.exchange).title;
        const currencyTitle = currency(bot.value.data.general.wallet_currency).title;
        const id = bot.value.data.general.id;
        return `MARKET | Bot #${id} | ${exchangeTitle} | ${currencyTitle} — `;
    };

    const apiKeys = computed(() => bot.value?.data.suitable_api_keys ? bot.value.data.suitable_api_keys.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })) : []);


    const addNewApiKey = async $event => {
        // change active tab
        apiOptions.value = 'select';

        
        loadings.apiKey = true;
        // get info
        await getData();

        // change api key
        apiKey.value = $event.id;
        loadings.apiKey = false;
    };

    const onCreateWallet = async () => {
        loadings.createWallet = true;

        const records = {
            exchange: bot.value.data.general.exchange,
            api_key: apiKey.value,
            name: walletModel.name.value,
            comment: walletModel.name.value,
            type: 'standard',
            amount: +walletModel.amount.value,
            currency: bot.value.data.general.wallet_currency,
            _formPath: 'market.buy.review',
        };

        const prepare = await WalletsService.addNew(records);

        if (!prepare.data.status) {
            if (prepare.data?.errors_form) {

                for (let key in walletModel) {
                    const fields = prepare.data.errors_form['market.buy.review'].fields;
                    const el = Object.keys(fields).find(el => el === key);

                    if (el) {
                        walletModel[key].status = 'error';
                        walletModel[key].msg = fields[el].msg;
                    } else {
                        walletModel[key].status = 'success';
                        walletModel[key].msg = undefined;
                    }
                }
            }
        } else {
            // clean apiKeyModel
            for (let key in walletModel)
                walletModel[key].value = '';

            // show messages
            prepare.postMessages.forEach(el => {
                gl.showNotification({
                    type: el.success ? 'success' : 'error',
                    msg: el.msg,
                });
            });
          
            walletsRefs.value = ( await WalletsService.getWalletsList({
                exchanges: [bot.value.data.general.exchange],
                apikeys: [-1],
                pairs: [bot.value.data.general.pair],
                currencies: [bot.value.data.general.wallet_currency],
            }) ).data.records;

            if (!gl.isMobile) {
                // set new value wallet
                wallet.value = null;

                setTimeout(() => {
                // change tab
                    walletOptions.value = 'select';
                }, 1000);
            } else {
                // change tab
                walletOptions.value = 'select';

                setTimeout(() => {
                    // set new value wallet
                    wallet.value = +prepare.data.records[0].id;
                }, 200);
            }
            
          
        }

        loadings.createWallet = false;
    };

    const onCheckBalance = async () => {
        loadings.checkBalance = true;

        const prepare = await WalletsService.getBalances(apiKey.value);

        if (!prepare.data.status) {
            prepare.data.errors.forEach(({ msg }) => {
                gl.showNotification({
                    type: 'error',
                    msg,
                });
            });

            // reset balancesRefs
            balancesRefs.value = null;
        } else {
            balancesRefs.value = prepare.data.balances;
        }

        loadings.checkBalance = false;
    };

    const onBuyConfiguration = async () => {
        loadings.buyBot = true;

        const conf = {
            source_bot_id: bot.value.data.general.id,
    
            api_key_action: apiOptions.value,
            wallet_action: walletOptions.value,
    
            bot_name: buyBotModel.name.value,
    
            api_key: apiKey.value,
            // api_key_name: apiKeyModel.name.value,
            // api_key_value: apiKeyModel.key.value,
            // api_key_sign: apiKeyModel.sign.value,
    
            wallet: wallet.value,
            wallet_name: walletModel.name.value,
            wallet_amount: walletModel.amount.value,
    
            _formPath: 'market.buy.review"',
        };
  
        const prepare = await MarketplaceService.buyBotConfiguration(conf);

        if (!prepare.data.status) {
            if (prepare.data?.errors) {
                prepare.data.errors.forEach(({ msg }) => {
                    gl.showNotification({
                        type: 'error',
                        msg,
                    });
                });
            }
            if (prepare.data?.errors_form) {
                for (let key in buyBotModel) {
                    const fields = prepare.data.errors_form['market.buy.review"'].fields;
                    const el = Object.keys(fields).find(el => el === key);

                    if (el) {
                        buyBotModel[key].status = 'error';
                        buyBotModel[key].msg = fields[el].msg;
                    } else {
                        buyBotModel[key].status = 'success';
                        buyBotModel[key].msg = undefined;
                    }
                }
            }
        } else {
            gl.showNotification({
                type: 'success',
                msg: prepare.data.message,
            });

            newBotId.value = prepare.data.id;
            notes.value = prepare.data.notes;
            configBought.value = true;
            successMessage.value = prepare.data.message;
            ownerContact.value = prepare.data.owner_contact;
        }

        loadings.buyBot = false;
    };

    const getData = async () => {
        bot.value = await MarketplaceService.getFullInfoForBuy({
            pager: props.withFilters ? {
                ...filters,
            } : null,
            id: route.params.bot_id || route.params.free_bot_id,
        });

        // set bot name
        buyBotModel.name.value = props.mode == 'marketplace' ? getBotName() : bot.value.data.general.name;

        // set default values in walletModel
        walletModel.name.value = buyBotModel.name.value;
        walletModel.amount.currency = bot.value.data.general.wallet_currency;

        init.value = true;
    };

    const parsed = v => {
        var arr = v.split(/></g);

        //split removes the >< so we need to determine where to put them back in.
        for(var i = 0; i < arr.length; i++){
            if(arr[i].substring(0, 1) != '<'){
                arr[i] = '<' + arr[i];
            }

            if(arr[i].slice(-1) != '>'){
                arr[i] = arr[i] + '>';
            }
        }

        return arr;
    };

    const leave = () => {
        init.value = false;
        newBotId.value = null;
        balancesRefs.value = null;
    };

    onMounted(async () => {
        if (route.params.bot_id || route.params.free_bot_id)
            await getData();
    });

    return {
        gl,
        bot,
        env,
        refs,
        init,
        notes,
        apiKey,
        wallet,
        router,
        apiKeys,
        columns,
        records,
        newBotId,
        loadings,
        confIntro,
        exchangeId,
        apiOptions,
        buyBotModel,
        walletModel,
        marketplace,
        ownerContact,
        balancesRefs,
        configBought,
        walletOptions,
        symbolBalance,
        botActionLinks,
        successMessage,
        canCreateWallet,
        pairWalletsRefs,
        isWalletSelected,
        isApiKeySelected,
        exchangeMeta2Info,
        exchangeMeta3Info,
        symbolTotalBalance,
        canBuyConfiguration,
        t,
        algos,
        leave,
        parsed,
        getData,
        currency,
        exchange,
        addNewApiKey,
        onCreateWallet,
        onCheckBalance,
        onBuyConfiguration,
    };
}