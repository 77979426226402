<template>
<section>
    <n-alert v-if="exchangeMeta2Info" class="rounded-md mb-4" type="warning" size="small">
        <div class="notes text-[10px]" v-html="exchangeMeta2Info"></div>
    </n-alert>
    <div class="flex items-center justify-between">
        <rb-title
            :help="help"
            :status="status"
            :help-warning="exchangeMetaInfo"
            :label="mainTitle" />

        <rb-radio-group
            size="small"
            v-model:value="apiOptions"
            :options="options" />
    </div>

    <n-alert v-if="!apiKeysRefs?.length" class="rounded-md mt-4" type="warning" size="small">
        <div 
            class="notes text-[10px]"
            v-html="getNoKeysAvailableInfo(bots.localization?.no_keys_available || 'Для биржи {{}} нет доступных ключей. Создайте новый.')"></div>
    </n-alert>

    <section v-if="errors.length" class="mt-4">
        <template v-for="(error, i) in errors" :key="i">
            <n-alert class="rounded-md mb-4" type="warning" size="small">
                <div class="notes text-[10px]" v-html="error.msg"></div>
            </n-alert>
        </template>
    </section>

    <section class="mt-4">
        <template v-if="apiOptions === 'select'">
            <rb-select
                filterable
                popoverType="warning"
                :label="selectTitle"
                :options="apiKeys"
                :value="apiKeyValue"
                :loading="loading"
                :status="status"
                :msg="msg"
                :disabled="loading || !apiKeys.length"
                :help="exchangeMetaInfo"
                @update:value="$emit('update:modelValue', $event)" />
        </template>
        <template v-else>
            <rb-input
                v-for="(apiKey, key) in apiKeyModel"
                :key="key"
                class="mb-4"
                :status="apiKey.status === 'error' ? 'error' : undefined"
                :msg="apiKey.msg"
                :label="apiKey.title"
                :disabled="loading"
                :placeholder="apiKey.placeholder"
                v-model:value="apiKey.value"
                @update:value="apiKey.status = null, apiKey.msg = null" />

            <div class="flex justify-end items-center">
                <n-popover
                    v-if="exchangeMeta2Info"
                    style="width: 200px" trigger="click"
                    class="rounded-md bg-yellow-600">
                    <template #trigger>
                        <n-icon
                            class="cursor-pointer text-xl mx-2 text-yellow-600">
                            <QuestionCircle20Filled />
                        </n-icon>
                    </template>
                    <span
                        class="notes text-[10px] text-white"
                        v-html="exchangeMeta2Info">
                    </span>
                </n-popover>
                <n-button
                    strong
                    class="rounded-md text-white/90"
                    :color="gl.mainColor"
                    :loading="addApiLoading"
                    :disabled="createApiDisable || addApiLoading"
                    @click="onCreateApiKey">
                    {{ buttonLabel }}
                </n-button>
            </div>
        </template>
    </section>
</section>
</template>

<script>
// general
import general from './general';

// icons
import { Warning24Filled, QuestionCircle20Filled } from '@vicons/fluent';

// components
import RbInput from '@components/rb-input/mobile.vue';
import RbSelect from '@components/rb-select/mobile.vue';

// naive-ui
import {
    NIcon,
    NAlert,
    NInput,
    NSelect,
    NButton,
    NPopover,
    NRadioGroup,
    NRadioButton } from 'naive-ui';

export default {
    name: 'api-keys-mobile',
    emits: ['update:modelValue'],
    props: {
        modelValue: [String, Number],
        selectTitle: String,
        mainTitle: String,
        emptyText: String,
        name: String,
        help: String,
        status: String,
        msg: String,
        exchange: {
            type: Object,
            required: true,
        },
        apiKeysRefs: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        mode: {
            type: String,
            default: 'quick-start',
            validator: prop => ['quick-start', 'bots', 'marketplace'].includes(prop), 
        },
    },
    components: {
        NIcon,
        NAlert,
        NInput,
        NButton,
        RbInput,
        NSelect,
        RbSelect,
        NPopover,
        NRadioGroup,
        NRadioButton,
        Warning24Filled,
        QuestionCircle20Filled,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>