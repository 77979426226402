<template>
<n-modal
    preset="dialog"
    class="rounded-lg bg-white dark:bg-darkCardBg"
    :show="show"
    transform-origin="center"
    @update:show="!$event ? $emit('update:show', $event) : null">
    <template #default>
        <slot name="default"></slot>
        <slot name="body"></slot>
        <div class="flex justify-end mt-4">
            <n-button
                secondary
                size="small"
                type="default"
                class="mr-2 rounded-md text-gray-600 dark:text-white/75"
                @click="$emit('negative-click')">
                {{ refs.localization.dialogs.cancel }}
            </n-button>
            <n-button
                secondary
                size="small"
                type="default"
                class="rounded-md bg-main text-white/90"
                @click="$emit('positive-click')">
                {{ refs.localization.dialogs.ok }}
            </n-button>
        </div>
    </template>
</n-modal>
</template>

<script>
// store
import { useRefs } from '@store/ts/refs';

// naive-ui
import {
    NIcon,
    NCard,
    NModal,
    NButton } from 'naive-ui';

// icons
import { Dismiss16Filled } from '@vicons/fluent';

export default {
    name: 'base-dialog-desktop',
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        height: {
            type: Number, String,
        },
    },
    components: {
        NIcon,
        NCard,
        NModal,
        NButton,
        Dismiss16Filled,
    },
    setup() {
        // store
        const refs = useRefs();

        return {
            refs,
        };
    },
};
</script>