<template>
<section>
    <div class="flex justify-between items-center">
        <div v-if="refresh" class="flex justify-between items-center">
            <n-button
                secondary
                type="default"
                class="rounded-none rounded-l-md"
                :disabled="loading"
                @click="update">
                <n-icon size="22" class="mr-2"><ArrowSyncCircle16Regular /></n-icon>
                {{ refs.localization.autorefresh.autorefresh_btn }}
            </n-button>
            <n-popover trigger="hover" placement="bottom">
                <template #trigger>
                    <n-button
                        secondary
                        type="default"
                        class="rounded-none rounded-r-md px-2"
                        :disabled="loading">
                        <n-icon size="20" class="text-main"><CaretDown24Filled /></n-icon>
                    </n-button>
                </template>
                <rb-checkbox
                    :label="refs.localization.autorefresh.autorefresh_label"
                    v-model:checked="autoUpdate" />
            </n-popover>
        </div>
        <n-dropdown
            scrollable
            trigger="click"
            style="max-height: 300px;"
            class="bg-white dark:bg-darkCardBg"
            placement="bottom-start"
            :show="showDropdownRef"
            :options="columnsOptions"
            :disabled="loading"
            :render-label="renderDropdownLabel"
            @clickoutside="showDropdownRef = false">
            <n-button
                tertiary
                class="rounded-md flex items-center text-gray-600 dark:text-white/75 mr-2"
                :class="!refresh ? 'ml-0' : 'ml-2'"
                :disabled="loading"
                @click="showDropdownRef = true">
                <n-icon class="mr-2" size="20"><ColumnEdit20Filled /></n-icon>
                {{ refs.localization.grids.tvs.modal_title }}
            </n-button>
        </n-dropdown>
        <div class="flex-grow mx-2">
            <rb-input
                v-if="showSearch"
                clearable
                type="text"
                :value="filters.query"
                :placeholder="placeholder"
                :disabled="loading"
                @update:value="$emit('changeFilter', [ 'query', $event ])"
                @keyup.enter="$emit('getData')"
                @keyup.esc="$emit('changeFilter', [ 'query', '' ])" />
                <!-- @keyup.esc="$emit('changeFilter', [ 'query', '' ])" -->
        </div>
        <slot name="afterSearch" ></slot>

        <div class="flex">
            <n-button
                v-if="zoomButton"
                secondary
                type="default"
                :disabled="loading"
                class="rounded-md flex items-center text-main dark:text-white/75 dark:bg-main bg-white ml-2 pl-[6px] pr-[5px]"
                @click="$emit('show-modal', true)">
                <n-icon size="24"><ArrowExpand20Filled /></n-icon>
            </n-button>
            <n-button
                v-else
                circle
                secondary
                type="default"
                class="ml-4"
                @click="$emit('show-modal', false)">
                <template #icon>
                    <n-icon class="text-gray-600 dark:text-white" size="16"><Dismiss16Filled /></n-icon>
                </template>
            </n-button>
        </div>
    </div>
    <Progress v-if="autoUpdate" class="mt-4" ref="progress" @done="doneTimer" />
</section>
</template>

<script>
// vue
import { h, ref, computed, watch, onMounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useMarketplace } from '@store/ts/marketplace';

// naive-ui
import {
    NCard,
    NIcon,
    NModal,
    NInput,
    NButton,
    NPopover,
    NSkeleton,
    NDropdown,
    NCheckbox } from 'naive-ui';

// i18n
import { useI18n } from 'vue-i18n';

// components
import RbInput from '@components/rb-input';
import Progress from '@components/base/progress.vue';
import GridNav from '@components/base/grid-nav.vue';
import RbCheckbox from '@components/rb-checkbox';

// icons
import {
    Filter20Filled,
    Dismiss16Filled,
    ColumnEdit20Filled,
    CaretDown24Filled,
    ArrowExpand20Filled,
    ArrowSyncCircle16Regular } from '@vicons/fluent';

// services
import LocalStorage from '@services/ts/localStorage';

export default {
    name: 'GridSettings',
    emits: ['setPerPage', 'enter', 'update:search'],
    props: {
        label: String,
        searchPlaceholder: String,
        zoomButton: {
            type: Boolean,
            default: false,
        },
        filters: {
            type: Object,
            required: true,
        },
        refFilters: {
            type: [ Object, Array ],
        },
        columns: {
            type: Array,
            required: true,
        },
        columnsStorageName: {
            type: String,
            required: true,
        },
        showSearch: {
            type: Boolean,
            default: true,
        },
        refresh: {
            type: Boolean,
            default: true,
        },
        filtersChanged: {
            type: Boolean,
            default: false,
        },
        filterId: {
            type: String,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        NCard,
        NIcon,
        NModal,
        NInput,
        RbInput,
        NButton,
        GridNav,
        Progress,
        NPopover,
        NSkeleton,
        NCheckbox,
        NDropdown,
        RbCheckbox,
        Filter20Filled,
        Dismiss16Filled,
        CaretDown24Filled,
        ColumnEdit20Filled,
        ArrowExpand20Filled,
        ArrowSyncCircle16Regular,
    },
    setup(props, { emit, slots }) {
        // store
        const gl = useGl();
        const refs = useRefs();
        const marketplace = useMarketplace();

        // i18n
        const { t } = useI18n();

        // vars
        const progress = ref();
        const showModal = ref(false);
        const autoUpdate = ref(false);
        const showDropdownRef = ref(false);

        const localLoading = computed(() => gl.loading || props.loading);
        const placeholder = computed(() => props.searchPlaceholder || refs.localization.grids.search_label);
        const columnsOptions = computed(() => {
            return props.columns.map(el => ({
                key: el.name,
                label: el.title,
                ...el,
            })).filter(({ name }) => !['checked', 'actions'].includes(name));
        });

        watch(() => props.columns, () => {
            columnsPrepare();
        });

        watch(() => gl.canAutoUpdate, v => {
            if (autoUpdate.value) {
                !v
                    ? progress.value.stop()
                    : progress.value.start();
            }
        });

        watch(() => props.loading, v => {
            if (v && autoUpdate.value)
                progress.value.stop();
            else if (!v && autoUpdate.value)
                progress.value.start();
        });

        // watch([...props.columns.map((_, i) => () => props.columns[i].isHidden)], () => {
        //     // emit('changeDataColumn', props.columns);
        // });

        const doneTimer = () => {
            emit('getData');
        };

        const update = () => {
            if (autoUpdate.value) progress.value.stop();
            emit('getData');

            // gl.loading = true;
            // if (autoUpdate.value) progress.value.stop();

            // await forceUpdate();

            // if (autoUpdate.value) progress.value.start();
            // gl.loading = false;
        };

        const setCheck = column => {
            column.isHidden = !column.isHidden;

            saveStorageColumns();
        };

        const saveStorageColumns = () => {
            LocalStorage.setItem(props.columnsStorageName, JSON.stringify(props.columns));
        };

        const renderDropdownLabel = option => {
            return h(RbCheckbox, {
                label: option.label,
                checked: !option.isHidden,
                class: 'w-full h-full items-center',
                onUpdateChecked: () => setCheck(props.columns.find(({ name }) => name === option.name)),
            });
        };

        const columnsPrepare = () => {
            const localColumns = JSON.parse(LocalStorage.getItem(props.columnsStorageName));

            if (localColumns) {
                localColumns.forEach(({ name, isHidden }) => {
                    const el = props.columns.find(({ name: id }) => id === name);
                    if (el)
                        el.isHidden = isHidden;
                });
            };
        };

        onMounted(() => {
            columnsPrepare();
        });

        return {
            gl,
            refs,
            slots,
            progress,
            showModal,
            autoUpdate,
            marketplace,
            placeholder,
            localLoading,
            columnsOptions,
            showDropdownRef,
            t,
            update,
            setCheck,
            doneTimer,
            renderDropdownLabel,
        };
    },
};
</script>

<style lang="scss" scoped>
</style>