<template>
<section class="pb-2">
    <div class="text-xl text-left text-gray-700 dark:text-white/90 font-semibold">
        <n-skeleton v-if="!profile.localization" :width="160" :height="20" :sharp="false" />
        <template v-else>{{ profile.localization.profile_notification_title }}</template>
    </div>
    <n-card
        :bordered="false"
        class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4"
        content-style="padding: 12px;"
        :segmented="{
            content: true,
            footer: 'soft'
        }">
        <template #default>
            <div class="flex flex-wrap">
                <section class="flex flex-wrap">
                    <div
                        v-for="(notification, i) in profile.fullInfo.notifications"
                        :key="i + notification.name"
                        class="mb-4 px-2">
                        <div class="w-full text-md whitespace-nowrap text-left text-gray-600 dark:text-white/75">{{ notification.title }}</div>

                        <n-button-group class="mt-2">
                            <template v-for="(item, i) in notification.items" :key="i">
                                <n-button
                                    strong
                                    secondary
                                    size="small"
                                    class="first:rounded-l-md last:rounded-r-md flex items-center"
                                    @click="updateNotificationsValues(profile.fullInfo.notifications, { name: notification.name, id: item.id, reset: false })" >
                                    <n-icon size="20" v-if="~notification.values.indexOf(item.id)" class="text-main mr-1"><Checkmark12Filled /></n-icon>
                                    <span :class="~notification.values.indexOf(item.id) ? 'text-main' : 'text-gray-600 dark:text-white/75'">{{ item.title }}</span>
                                </n-button>
                            </template>
                            
                            <n-button
                                strong
                                secondary
                                size="small"
                                type="error"
                                class="first:rounded-l-md last:rounded-r-md flex items-center"
                                @click="updateNotificationsValues(profile.fullInfo.notifications, { name: notification.name, id: null, reset: true })" >
                                <n-icon size="20" v-if="!notification.values.length" class="text-red-500 mr-1"><Checkmark12Filled /></n-icon>
                                <span class="text-red-500">off</span>
                            </n-button>
                        </n-button-group>
                    </div>
                </section>
                <n-divider />

                <div class="flex items-end" v-if="profile.fullInfo.notification_reports">
                    <div>
                        <div class="text-md whitespace-nowrap text-left text-gray-600 dark:text-white/75">
                            {{ profile.fullInfo.notification_reports.notify_with.title }}
                        </div>
                        <n-button-group class="mt-2">
                            <template v-for="(item, i) in profile.fullInfo.notification_reports.notify_with.items" :key="i">
                                <n-button
                                    strong
                                    secondary
                                    size="small"
                                    class="first:rounded-l-md last:rounded-r-md flex items-center"
                                    @click="updateNotificationsValues2(profile.fullInfo.notification_reports.notify_with, { name: profile.fullInfo.notification_reports.notify_with.name, id: item.id, reset: false })" >
                                    <n-icon size="20" v-if="~profile.fullInfo.notification_reports.notify_with.values.indexOf(item.id)" class="text-main mr-1"><Checkmark12Filled /></n-icon>
                                    <span :class="~profile.fullInfo.notification_reports.notify_with.values.indexOf(item.id) ? 'text-main' : 'text-gray-600 dark:text-white/75'">{{ item.title }}</span>
                                </n-button>
                            </template>
                            
                            <n-button
                                strong
                                secondary
                                size="small"
                                type="error"
                                class="first:rounded-l-md last:rounded-r-md flex items-center"
                                @click="updateNotificationsValues2(profile.fullInfo.notification_reports.notify_with, { name: profile.fullInfo.notification_reports.notify_with.name, id: null, reset: true })" >
                                <n-icon size="20" v-if="!profile.fullInfo.notification_reports.notify_with.values.length" class="text-red-500 mr-1"><Checkmark12Filled /></n-icon>
                                <span class="text-red-500">off</span>
                            </n-button>
                        </n-button-group>
                    </div>

                    <n-button-group class="ml-2 mb-[3px]">
                        <template v-for="(item, i) in profile.fullInfo.notification_reports.period.items" :key="i">
                            <n-button
                                strong
                                secondary
                                size="small"
                                class="first:rounded-l-md last:rounded-r-md flex items-center"
                                :disabled="profile.fullInfo.notification_reports.notify_with.values.length == 0"
                                :color="~profile.fullInfo.notification_reports.period.values.indexOf(item.id) ? gl.mainColor : ''"
                                @click="updateNotificationsValues2(profile.fullInfo.notification_reports.period, { name: profile.fullInfo.notification_reports.period.name, id: item.id, reset: false })">
                                <n-icon size="20" v-if="~profile.fullInfo.notification_reports.period.values.indexOf(item.id)" class="text-main mr-1"><Checkmark12Filled /></n-icon>
                                <span :class="~profile.fullInfo.notification_reports.period.values.indexOf(item.id) ? 'text-main' : 'text-gray-600 dark:text-white/75'">{{ item.title }}</span>
                            </n-button>
                        </template>
                    </n-button-group>
                </div>

                <section class="flex items-start justify-between mt-4">
                    <rb-select
                        class="pr-2"
                        :label="profile.localization['profile_notification_auto_remove_web_notifications_f']"
                        :options="notificationsAutoremoveListOptions"
                        v-model:value="profile.fullInfo.notifications_autoremove_value" />

                    <rb-switch
                        class="pl-2"
                        :label="profile.localization['profile_notification_telegram_notifications']"
                        v-model:value="profile.fullInfo.tg_enabled"
                        @update:value="onTgNotificationChanged" />
                </section>
            </div>
        </template>
    </n-card>
    <base-modal
        v-model:show="showModal">
        <div class="notes" v-html="profile.fullInfo.tg_enable_message"></div>
        <template #footer>
            <div class="flex justify-end">
                <n-button
                    strong
                    class="rounded-md text-white/90"
                    :color="gl.mainColor"
                    @click="onToggleTgNotifications(profile.fullInfo.tg_enabled);">
                    {{ refs.localization.dialogs.ok }}
                </n-button>
            </div>
        </template>
    </base-modal>
</section>
<!-- <n-alert type="default" :show-icon="false">
    <template #header>
        <div class="flex items-center font-semibold cursor-pointer uppercase" @click="show = !show">
            <span class="text-xl">{{ profile.localization['profile_notification_title'] }}</span>
            <n-icon class="text-base ml-2 -mt-1 transition transform" :class="show ? 'rotate-90' : ''">
                <IosArrowRtl24Filled />
            </n-icon>
        </div>
        <n-collapse-transition :show="show">
            <n-card class="bg-transparent mt-8">
                <section class="flex flex-wrap">
                    <div
                        v-for="(notification, i) in profile.fullInfo.notifications"
                        :key="i + notification.name"
                        class="w-4/12 mb-4">
                        <div class="text-base whitespace-nowrap">{{ notification.title }}</div>

                        <n-button-group>
                            <template v-for="(item, i) in notification.items" :key="i">
                                <n-button
                                    strong
                                    size="large"
                                    :color="~notification.values.indexOf(item.id) ? gl.mainColor : ''"
                                    @click="updateNotificationsValues(profile.fullInfo.notifications, { name: notification.name, id: item.id, reset: false })" >
                                    <span :class="!notification.values.length ? 'text-white' : ''">{{ item.title }}</span>
                                </n-button>
                            </template>
                            
                            <n-button
                                strong
                                size="large"
                                :color="!notification.values.length ? gl.mainColor : ''"
                                @click="updateNotificationsValues(profile.fullInfo.notifications, { name: notification.name, id: null, reset: true })" >
                                <span :class="!notification.values.length ? 'text-white' : ''">off</span>
                            </n-button>
                        </n-button-group>
                      </div>
                </section>
                <n-divider />
                
                <section class="flex items-start justify-between">
                    <div v-if="profile.fullInfo.notification_reports">
                        <div class="text-base whitespace-nowrap">{{ profile.fullInfo.notification_reports.notify_with.title }}</div>
                        <n-button-group class="block mt-4">
                            <template v-for="(item, i) in profile.fullInfo.notification_reports.notify_with.items" :key="i">
                                <n-button
                                    strong
                                    size="large"
                                    :color="~profile.fullInfo.notification_reports.notify_with.values.indexOf(item.id) ? gl.mainColor : ''"
                                    @click="updateNotificationsValues2(profile.fullInfo.notification_reports.notify_with, { name: profile.fullInfo.notification_reports.notify_with.name, id: item.id, reset: false })" >
                                    <span :class="!profile.fullInfo.notification_reports.notify_with.values.length ? 'text-white' : ''">{{ item.title }}</span>
                                </n-button>
                            </template>
                            
                            <n-button
                                strong
                                size="large"
                                :color="!profile.fullInfo.notification_reports.notify_with.values.length ? gl.mainColor : ''"
                                @click="updateNotificationsValues2(profile.fullInfo.notification_reports.notify_with, { name: profile.fullInfo.notification_reports.notify_with.name, id: null, reset: true })" >
                                <span :class="!profile.fullInfo.notification_reports.notify_with.values.length ? 'text-white' : ''">off</span>
                            </n-button>
                        </n-button-group>

                        <n-button-group class="mt-2 block">
                            <template v-for="(item, i) in profile.fullInfo.notification_reports.period.items" :key="i">
                                <n-button
                                    strong
                                    size="large"
                                    :disabled="profile.fullInfo.notification_reports.notify_with.values.length == 0"
                                    :color="~profile.fullInfo.notification_reports.period.values.indexOf(item.id) ? gl.mainColor : ''"
                                    @click="updateNotificationsValues2(profile.fullInfo.notification_reports.period, { name: profile.fullInfo.notification_reports.period.name, id: item.id, reset: false })" >
                                    <span :class="!profile.fullInfo.notification_reports.period.values.length ? 'text-white' : ''">{{ item.title }}</span>
                                </n-button>
                            </template>
                        </n-button-group>
                    </div>

                    <rb-select
                        class="px-4"
                        :label="profile.localization['profile_notification_auto_remove_web_notifications_f']"
                        :options="notificationsAutoremoveListOptions"
                        v-model:value="profile.fullInfo.notifications_autoremove_value" />

                    <rb-switch
                        :label="profile.localization['profile_notification_telegram_notifications']"
                        v-model:value="profile.fullInfo.tg_enabled"
                        @update:value="onTgNotificationChanged" />
                </section>
            </n-card>

            <base-modal
                v-model:show="showModal">
                <div class="notes" v-html="profile.fullInfo.tg_enable_message"></div>
                <template #footer>
                    <div class="flex justify-end">
                        <n-button
                            strong
                            size="large"
                            :color="gl.mainColor"
                            @click="onToggleTgNotifications(profile.fullInfo.tg_enabled);">
                            {{ refs.localization.dialogs.ok }}
                        </n-button>
                    </div>
                </template>
            </base-modal>
        </n-collapse-transition>
    </template>
</n-alert> -->
</template>

<script>
// vue
import { ref, computed } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useEnv } from '@store/ts/env';
import { useRefs } from '@store/ts/refs';
import { useProfile } from '@store/profile';

// i18n
import { useI18n } from 'vue-i18n';

// icons
import { Checkmark12Filled, Dismiss16Filled, IosArrowRtl24Filled } from '@vicons/fluent';

// services
import ProfileService from '@services/profileService';

// components
import RbInput from '@components/rb-input';
import RbSelect from '@components/rb-select';
import RbSwitch from '@components/rb-switch';
import BaseModal from '@components/base/base-modal';
import RbCheckboxGroup from '@components/rb-checkbox-group';

// naive-ui
import {
    NIcon,
    NCard,
    NAlert,
    NButton,
    NDivider,
    NButtonGroup,
    useNotification,
    NCollapseTransition } from 'naive-ui';

export default {
    name: 'notifications',
    components: {
        NIcon,
        NCard,
        NAlert,
        NButton,
        RbInput,
        RbSelect,
        NDivider,
        RbSwitch,
        BaseModal,
        NButtonGroup,
        RbCheckboxGroup,
        Dismiss16Filled,
        Checkmark12Filled,
        IosArrowRtl24Filled,
        NCollapseTransition,
    },
    setup() {
        // store
        const gl = useGl();
        const env = useEnv();
        const refs = useRefs();
        const profile = useProfile();

        // ui
        const notification = useNotification();

        // i18n
        const { t } = useI18n();

        // vars
        const show = ref(false);
        const showModal = ref(false);

        const notificationsAutoremoveListOptions = computed(() => profile.notificationsAutoremoveList.map(el => ({
            label: el.title,
            value: +el.id,
            ...el,
        })));

        const updateNotificationsValues = ( arr, $event ) => {
            const notif = arr.find(({ name }) => name === $event.name);

            if (!notif) return;

            if ($event.reset) {
                notif.values.splice(0);
            } else {
                const i = notif.values.indexOf($event.id);

                !~i
                    ? notif.values.push($event.id)
                    : notif.values.splice(i, 1);
            }
        };

        const updateNotificationsValues2 = ( arr, $event ) => {
            if ($event.reset) {
                arr.values.splice(0);
            } else {
                const i = arr.values.indexOf($event.id);

                !~i
                    ? arr.values.push($event.id)
                    : arr.values.splice(i, 1);
            }
        };

        const getFullInfo = async (showLoading = true) => {
            if (showLoading)
                gl.showLoading = true;

            try {
                profile.fullInfo = ( await ProfileService.getFullInfo() ).data;
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };
            
            if (showLoading)
                gl.showLoading = false;
        };

        const onToggleTgNotifications = async enable => {
            gl.showLoading = true;

            try {
                const { data, postMessages } = await ProfileService.toggleTgNotifications({
                    enable,
                });

                if (postMessages)
                    postMessages.forEach(el => {
                        gl.showNotification({
                            type: el.success ? 'success' : 'error',
                            msg: el.msg,
                        });
                    });

                if (data.status) {
                    await getFullInfo(false);
                    showModal.value = false;
                }
                    
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };
            
            gl.showLoading = false;
        };

        const onTgNotificationChanged = $event => {
            if ($event) {
                showModal.value = true;
            } else {
                onToggleTgNotifications($event);
            }
        };

        return {
            gl,
            env,
            refs,
            show,
            profile,
            showModal,
            notificationsAutoremoveListOptions,
            onTgNotificationChanged,
            onToggleTgNotifications,
            updateNotificationsValues,
            updateNotificationsValues2,
        };
    },
};
</script>