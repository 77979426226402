// vue
import { h, ref, computed, onMounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useBots } from '@store/bots';
import { useRefs } from '@store/ts/refs';
import { useStats } from '@store/stats';
import { useWallets } from '@store/wallets';
import { useApiKeys } from '@store/apiKeys';
import { useProfile } from '@store/profile';
import { useMarketplace } from '@store/ts/marketplace';

// router
import { useRoute, useRouter } from 'vue-router';

// i18n
import { useI18n } from 'vue-i18n';

// naive-ui
import {
    NIcon,
    useNotification } from 'naive-ui';

// services
import BotsService from'@services/bots';

// icons
import {
    Edit20Filled,
    Info24Filled,
    Edit20Regular,
    Cart20Regular,
    Play16Regular,
    Stop20Regular,
    CopyAdd24Regular,
    RecordStop28Regular,
    ArrowSync12Regular,
    PauseCircle24Regular,
    ChartMultiple20Regular,
    DeleteDismiss24Regular,
    ArchiveMultiple16Filled,
    ArrowTrendingLines20Filled } from '@vicons/fluent';

export default function (props, { emit }) {
    // store
    const gl = useGl();
    const bots = useBots();
    const refs = useRefs();
    const stats = useStats();
    const wallets = useWallets();
    const apiKeys = useApiKeys();
    const profile = useProfile();
    const marketplace = useMarketplace();

    // router
    const router = useRouter();

    // i18n
    const { t } = useI18n();

    // naive-ui
    const notification = useNotification();

    // vars
    const icons = {
        marketplace_Buy__review: Info24Filled,
        marketplace_free_buy__review: Info24Filled,
        marketplace_Sell__addToMarket: props.row.in_market ? DeleteDismiss24Regular : Cart20Regular,
        marketplace_Sell__statistics: ChartMultiple20Regular,
        marketplace_Sell__edit: Edit20Regular,
        marketplace_mentors__need_help: Cart20Regular,
        marketplace_mentors__question_info: Info24Filled,
        bots__start: Play16Regular,
        bots__restart: ArrowSync12Regular,
        bots__stop: Stop20Regular,
        bots__pause: PauseCircle24Regular,
        bots__edit: Edit20Filled,
        bots__delete: DeleteDismiss24Regular,
        bots__stats: ArrowTrendingLines20Filled,
        bots__clone: CopyAdd24Regular,
        bots__archive: ArchiveMultiple16Filled,
        bots__force_stop: RecordStop28Regular,
        wallets__edit: Edit20Regular,
        wallets__delete: DeleteDismiss24Regular,
        api_keys__play: Play16Regular,
        api_keys__edit: Edit20Filled,
        api_keys__delete: DeleteDismiss24Regular,
        q_auth__delete: DeleteDismiss24Regular,
        marketplace_avail_signals: props.row.subscription_type=='paid' ? Cart20Regular : Info24Filled,
        marketplace_my_signals: props.row.subscription_type=='paid' ? Cart20Regular : Info24Filled,
    };
    const showConfirmation = ref(false);
    const showConfirmationCheck = ref(false);
    const confirmLabel = ref();
    const declineMessage = ref();
    const genConfCheck = ref(false);
    const action = ref();

    const functions = {
        marketplace_Buy__review: () => router.push({ name: 'marketplace.buy', params: { bot_id: props.row?.id } }),
        marketplace_free_buy__review: () => router.push({ name: 'marketplace.buy.free', params: { free_bot_id: props.row?.id } }),
        marketplace_Sell__addToMarket: () => props.row.in_market
            ? emit('doSomething', ['tryDeleteBot', props.row.id])
            : router.push({ name: 'marketplace.sell', params: { id: props.row.id } }),
        marketplace_Sell__statistics: () => router.push({ name: 'trade-statistic.management', params: { id: props.row?.id } }),
        marketplace_Sell__edit: () => emit('doSomething', ['tryEditBot', props.row.id]),
        marketplace_mentors__need_help: () => emit('doSomething', ['needHelp', props.row.name]),
        marketplace_mentors__question_info: () => emit('doSomething', ['onQuestionInfo', props.row.id]),
        bots__start: () => canDoAction('start'),
        bots__restart: () => canDoAction('restart'),
        bots__stop: () => canDoAction('stop'),
        bots__pause: () => canDoAction('pause'),
        bots__edit: () => router.push({ name: 'bots.edit', params: { id: props.row.id } }),
        bots__delete: () => canDoAction('delete'),
        bots__stats: () => router.push({ name: 'trade-statistic.management', params: { id: props.row.id } }),
        bots__clone: () => router.push({ name: 'bots.clone', params: { id: props.row.id } }),
        bots__archive: () => props.row.status == -5 ? canDoAction('unarchive') : canDoAction('archive'),
        bots__force_stop: () => canDoAction('force_stop'),
        wallets__edit: () => emit('doSomething', ['walletsEdit', props.row]),
        wallets__delete: () => emit('doSomething', ['walletsDelete', props.row.id]),
        api_keys__play: () => emit('doSomething', ['play', props.row.id]),
        api_keys__edit: () => emit('doSomething', ['edit', props.row.id]),
        api_keys__delete: () => emit('doSomething', ['delete', props.row.id]),
        q_auth__delete: () => emit('doSomething', ['delete', props.row.id]),
        marketplace_avail_signals: () => emit('doSomething', ['showInfo', props.row]),
        marketplace_my_signals: () => emit('doSomething', ['showInfo', props.row]),
    };

    const popover = computed(() => ({
        marketplace_Buy__review: marketplace.localization?.['marketplace/buy/grid/actions/review'],
        marketplace_free_buy__review: marketplace.localization?.['marketplace/buy/grid/actions/review'],
        marketplace_Sell__addToMarket: props.row.in_market
            ? marketplace.localization?.['marketplace/sell/grid/actions/removeFromMarket']
            : marketplace.localization?.['marketplace/sell/grid/actions/addToMarket'],
        marketplace_Sell__statistics: marketplace.localization?.['marketplace/sell/grid/actions/statistics'],
        marketplace_Sell__edit: marketplace.localization?.['marketplace/sell/grid/actions/edit'],
        marketplace_mentors__need_help: marketplace.localization?.['marketplace/mentors/grid/actions/inquire_mentorship'],
        marketplace_mentors__question_info: marketplace.localization?.['marketplace/mentors/grid/actions/question_info'],
        bots__start: props.type === 'bots'
            ? bots.localization?.['available_bots_table_mass_start_button']
            : stats.localization?.['trade_statistics_management_start_button'],
        bots__restart: props.type === 'bots'
            ? bots.localization?.['available_bots_table_mass_restart_button']
            : stats.localization?.['trade_statistics_management_restart_button'],
        bots__stop: props.type === 'bots'
            ? bots.localization?.['available_bots_table_mass_stop_button']
            : stats.localization?.['trade_statistics_management_stop_button'],
        bots__pause: props.type === 'bots'
            ? bots.localization?.['available_bots_table_mass_pause_button']
            : stats.localization?.['trade_statistics_bots_table_actions_pause'],
        bots__edit: props.type === 'bots'
            ? bots.localization?.['available_bots_table_mass_edit']
            : stats.localization?.['trade_statistics_management_edit_button'],
        bots__delete: props.type === 'bots'
            ? bots.localization?.['available_bots_table_mass_delete_button']
            : stats.localization?.['trade_statistics_bots_table_mass_delete_button'],
        bots__stats: bots.localization?.['available_bots_table_mass_statistics'],
        bots__clone: props.type === 'bots'
            ? bots.localization?.['available_bots_table_mass_clone']
            : stats.localization?.['trade_statistics_bots_table_clone'],
        bots__force_stop: props.type === 'bots'
            ? bots.localization?.['bots_available_bots_table_mass_force_stop']
            : stats.localization?.['trade_statistics_bots_table_mass_force_stop'],
        bots__archive: props.type === 'bots'
            ? props.row.status == -5
                ? bots.localization?.['available_bots_table_unarchive']
                : bots.localization?.['available_bots_table_archive']
            : props.row.status == -5
                ? stats.localization?.['trade_statistics_bots_table_unarchive']
                : stats.localization?.['trade_statistics_bots_table_archive'],
        wallets__edit: wallets.localization?.['wallets_all_wallets_table_actions_edit'],
        wallets__delete: wallets.localization?.['wallets_all_wallets_table_mass_delete_button'],
        api_keys__play: apiKeys.localization?.['apikeys_all_keys_table_actions_check'],
        api_keys__edit: apiKeys.localization?.['apikeys_all_keys_table_actions_edit'],
        api_keys__delete: apiKeys.localization?.['apikeys_all_keys_table_actions_delete'],
        q_auth__delete: profile.localization?.['profile/oauth/grid/actions/unlink'],
        marketplace_avail_signals: props.row.subscription_type == 'paid'
            ? marketplace.localization?.['marketplace/signals/avail/grid/actions/buy']
            : marketplace.localization?.['marketplace/signals/grid/actions/info'],
        marketplace_my_signals: props.row.subscription_type == 'paid'
            ? marketplace.localization?.['marketplace/signals/my/grid/actions/rebill']
            : marketplace.localization?.['marketplace/signals/grid/actions/info'],
    }));

    const colors = computed(() => ({
        'q_auth__delete': 'red-500',
        'marketplace_Sell__addToMarket':
          props.row.in_market ? 'red-500' : undefined,
    }));

    const renderIcon = (icon) => {
        return () => {
            return h(NIcon, null, {
                default: () => h(icon),
            });
        };
    };

    const actionsPrepareMoreOptions = computed(() => {
        if (actionsPrepare.value.length > (gl.isMobile ? 2 : 3)) {
            return actionsPrepare.value.slice(3).map(el => ({
                label: el.popover,
                key: el.id,
                ...el,
                icon: renderIcon(el.icon),
            }));
        } else return [];
    });

    const canDoAction = async curAction => {
        gl.showLoading = true;

        action.value = curAction;
        confirmLabel.value = null;
        declineMessage.value = null;

        if (curAction === 'start') {
            try {
                const result = await BotsService.canDoAction({
                    id: props.row.id,
                    action: curAction,
                });

                if (result) {
                    if (result.data.confirm_label && result.data.decline_message) {
                        showConfirmationCheck.value = true;

                        confirmLabel.value = result.data.confirm_label;
                        declineMessage.value = result.data.decline_message;
                    } else {
                        showConfirmation.value = true;
                    }
                }
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };
        }  else {
            showConfirmation.value = true;
        }

        gl.showLoading = false;
    };

    const onGeneralConfirmClicked = async curAction => {
        gl.showLoading = true;

        try {
            let result;

            if (curAction === 'delete') {
                result = await BotsService.delete({
                    ids: [props.row.id],
                });
            } else {
                result = await BotsService.action({
                    id: props.row.id,
                    action: curAction,
                });
            }

            if (result) {
                // show messages
                if (result.postMessages)
                    result.postMessages.forEach(el => {
                        gl.showNotification({
                            type: el.success ? 'success' : 'error',
                            msg: el.msg,
                        });
                    });

                if (result.data.status) {
                    return void emit('getData');
                }
            }
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        gl.showLoading = false;
    };

    const actionsPrepare = computed(() => {
        return props.data.map(item => ({
            id: item,
            icon: icons[item],
            popover: popover.value[item],
            fn: functions[item],
        }));
    });

    const handleSelect = $event => {
        functions[$event]();
    };

    return {
        gl,
        refs,
        action,
        colors,
        functions,
        genConfCheck,
        confirmLabel,
        actionsPrepare,
        declineMessage,
        showConfirmation,
        showConfirmationCheck,
        actionsPrepareMoreOptions,
        handleSelect,
        onGeneralConfirmClicked,
    };
}