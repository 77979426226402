<template>
<base-modal-desktop
    width="700px"
    :label="mainButtonLabel"
    :show="show"
    @update:show="!$event ? $emit('update:show', $event) : null">
    <template #default>
        <div v-if="!init" class="flex h-full justify-center">
            <n-spin size="small" />
        </div>
        <div v-else class="relative">
            <section class="flex items-start">
                <rb-input
                    id="name"
                    class="w-6/12 pr-2"
                    :label="dataForm.name.title"
                    :status="dataForm.name.status === 'error' ? 'error' : undefined"
                    :msg="dataForm.name.msg"
                    :loading="dataForm.name.loading"
                    :disabled="dataForm.name.loading"
                    :placeholder="dataForm.name.placeholder"
                    v-model:value="dataForm.name.value"
                    @update:value="dataForm.name.status = null, dataForm.name.msg = null" />

                <div class="w-6/12 pl-2 flex items-end">
                    <rb-select
                        :label="dataForm.exchange.title"
                        :options="exchangeOptions"
                        :disabled="dataForm.exchange.loading || isUpdate || exchangeId"
                        :status="dataForm.exchange.status === 'error' ? 'error' : undefined"
                        :msg="dataForm.exchange.msg"
                        class="flex-grow"
                        v-model:value="dataForm.exchange.value"
                        @update:value="dataForm.exchange.status = null, dataForm.exchange.msg = null" />
                    <div
                        v-if="isExchangeSelected"
                        class="rb-td-stock-icon ml-2 mb-[10px] h-5"
                        :class="`rb-td-stock-icon-${exchange(dataForm.exchange.value)?.code_name}`">
                    </div>
                </div>
            </section>

            <template v-if="dataForm.currency.show">
                <n-divider />
                <div class="flex items-start">
                    <rb-currencies
                        class="pr-2 w-6/12"
                        :label="dataForm.currency.title"
                        :options="currencyOptions"
                        :status="dataForm.currency.status === 'error' ? 'error' : undefined"
                        :msg="dataForm.currency.msg"
                        :disabled="dataForm.currency.loading || isUpdate || coinSymbolForWallet"
                        :loading="dataForm.currency.loading"
                        v-model:value="dataForm.currency.value"
                        @update:value="dataForm.currency.status = null, dataForm.currency.msg = null" />
                    <!-- <div v-else class="text-left w-4/12 pl-2">
                        <div class="text-me">{{ dataForm.currency.title }}</div>
                        <div class="flex items-center mt-3">
                            <rb-coin class="h-6" :coin="currency(dataForm.currency.value)" />
                            <div class="text-center font-semibold ml-2">{{ currency(dataForm.currency.value)?.title }}</div>
                        </div>
                    </div> -->
                    <div class="w-6/12 pl-2">
                        <rb-input
                            id="amount"
                            type="percent"
                            :helpWarning="wallets.localization.wallets_minimum_depo_info"
                            :label="dataForm.amount.title"
                            :status="dataForm.amount.status === 'error' ? 'error' : undefined"
                            :msg="dataForm.amount.msg"
                            :placeholder="dataForm.amount.placeholder"
                            :disabled="dataForm.amount.loading"
                            v-model:value="dataForm.amount.value"
                            @update:value="dataForm.amount.status = null, dataForm.amount.msg = null">
                            <template #beforeInput>
                                <rb-radio-group
                                    class="mr-2"
                                    v-model:value="dataForm.type"
                                    :options="refs.walletTypes"
                                    :disabled="dataForm.amount.loading" />
                            </template>
                        </rb-input>
                    </div>
                </div>

                <n-divider />
                <section>
                    <div class="flex items-end">
                        <rb-select
                            class="flex-grow pr-2"
                            :label="dataForm.api_key.title"
                            :options="apiKeysOptions"
                            :status="dataForm.api_key.status === 'error' ? 'error' : undefined"
                            :msg="dataForm.api_key.msg"
                            :disabled="dataForm.api_key.loading"
                            :loading="dataForm.api_key.loading"
                            v-model:value="dataForm.api_key.value"
                            @update:value="dataForm.api_key.status = null, dataForm.api_key.msg = null" />
                        <div class="pl-2">
                            <div class="flex">
                                <n-button
                                    v-if="canCreateNewApiKey"
                                    class="text-white/90 rounded-md mr-1"
                                    :color="gl.mainColor"
                                    :disabled="!isExchangeSelected || dataForm.api_key.loading"
                                    @click="walletApiKeyDialog = true">
                                    + {{ wallets.localization.wallets_api_createnew }}
                                </n-button>
                                <n-button
                                    secondary
                                    class="rounded-md"
                                    :loading="loadings.onCheckBalance"
                                    :disabled="!canCheckBalance || dataForm.api_key.loading || loadings.onCheckBalance"
                                    @click="onCheckBalance">
                                    {{ wallets.localization.wallets_check_balance_button }}
                                </n-button>
                            </div>
                        </div>
                    </div>

                    <rb-checkbox
                        class="mt-4"
                        :disabled="!canCheckBalance || dataForm.api_key.loading"
                        :label="wallets.localization.wallets_show_all_non_zero"
                        v-model:checked="showAllNonZeroBalances" />

                    <n-card
                        v-if="symbolBalance || symbolTotalBalance"
                        size="small"
                        :bordered="false"
                        class="overflow-hidden shadow rounded-md dark:bg-darkbg bg-white w-full mt-6"
                        :segmented="{
                            content: true,
                            footer: 'soft'
                        }">
                        <div class="w-full flex justify-between">
                            <n-statistic
                                v-if="symbolBalance"
                                tabular-nums
                                class="text-left"
                                :label="wallets.localization['wallets_symbol_balance__available_amount']">
                                <n-number-animation
                                    :from="0"
                                    :to="+symbolBalance"
                                    :precision="8"
                                />
                            </n-statistic>
                            <n-statistic
                                v-if="symbolTotalBalance"
                                tabular-nums
                                class="text-left"
                                :label="wallets.localization['wallets_symbol_balance__balance_with_orders']">
                                <n-number-animation
                                    :from="0"
                                    :to="+symbolTotalBalance"
                                    :precision="8"
                                />
                            </n-statistic>
                        </div>
                    </n-card>

                    <div
                        v-if="showAllNonZeroBalances && allSymbolsBalances"
                        class="flex flex-wrap items-stretch mt-6 w-full">
                        <div
                            v-for="(item, i) in allSymbolsBalances"
                            :key="i"
                            class="mb-4 w-6/12 even:pl-2 odd:pr-2">
                            <n-card
                                size="small"
                                :bordered="false"
                                class="overflow-hidden shadow rounded-md dark:bg-darkbg bg-white h-full"
                                :segmented="{
                                    content: true,
                                    footer: 'soft'
                                }">
                                <div class="flex text-gray-600 dark:text-white/75">
                                    <div class="w-3/12 flex flex-col items-center">
                                        <rb-coin class="h-6" :coin="currency(item.symbol)" />
                                        <div class="font-semibold mt-2 text-[10px] text-center">{{ currency(item.symbol)?.title }}</div>
                                    </div>

                                    <div class="w-9/12 text-xs">
                                        <div class="text-right">
                                            {{ wallets.localization.wallets_check_balance_available }}
                                            <span class="font-bold text-main">{{ item.available }}</span>
                                        </div>
                                        <div class="text-right">
                                            {{ wallets.localization.wallets_extra_balance__with_orders }}
                                            <span class="text-main">{{ item.total }}</span>
                                        </div>
                                    </div>
                                </div>
                            </n-card>
                        </div>
                    </div>
                </section>
            </template>
            
            <section>
                <n-divider />
                <rb-input
                    id="comment"
                    class="w-full"
                    type="textarea"
                    :label="dataForm.comment.title"
                    :status="dataForm.comment.status === 'error' ? 'error' : undefined"
                    :msg="dataForm.comment.msg"
                    :placeholder="dataForm.comment.placeholder"
                    :disabled="dataForm.comment.loading"
                    v-model:value="dataForm.comment.value"
                    @update:value="dataForm.comment.status = null, dataForm.comment.msg = null" />
            </section>
        </div>
    </template>
    <template #footer>
        <div class="flex justify-end">
            <!-- :disabled="!canCreateWallet" -->
            <n-button
                secondary
                type="default"
                class="rounded-md text-gray-600 dark:text-white/75"
                @click="$emit('update:show', false)">
                {{ refs.localization.dialogs.cancel }}
            </n-button>
            <n-button
                class="ml-2 text-white/90 rounded-md"
                @click="onActionWallet"
                :loading="loading"
                :disabled="loading || !init || !canDoAction"
                :color="gl.mainColor" >
                {{ applyLabel }}
            </n-button>
        </div>
    </template>
</base-modal-desktop>
<rb-api-key-cr
    v-if="init"
    :api-keys-list="apiKeysOptions"
    :exchange-id="dataForm.exchange.value"
    v-model:show="walletApiKeyDialog"
    @addNewApiKey="addNewApiKey($event)" />
</template>

<script>
// general
import general from './general';

// components
import RbCoin from '@components/rb-coin';
import RbCurrencies from '@components/rb-currencies';
import WalletsIcon from '@components/icons/wallets.vue';
import RbApiKeyCr from '@components/rb-api-key-cr';

// icons
import { Warning24Filled } from '@vicons/fluent';

// naive-ui
import {
    NIcon,
    NCard,
    NSpin,
    NAlert,
    NButton,
    NDivider,
    NCollapse,
    NStatistic,
    NRadioGroup,
    NRadioButton,
    NCollapseItem,
    NNumberAnimation } from 'naive-ui';

export default {
    name: 'rb-wallet-cr-desktop',
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        walletId: {
            type: [String, Number],
            default: null,
        },
        exchangeId: {
            type: [String, Number],
            default: null,
        },
        coinSymbolForWallet: {
            type: String,
            default: null,
        },
        canCreateNewApiKey: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        NIcon,
        NCard,
        NSpin,
        NAlert,
        RbCoin,
        NButton,
        NDivider,
        NCollapse,
        RbApiKeyCr,
        NStatistic,
        WalletsIcon,
        NRadioGroup,
        NRadioButton,
        RbCurrencies,
        NCollapseItem,
        Warning24Filled,
        NNumberAnimation,
    },
    setup(props, context) {
        return {
            ...general(...arguments),
        };
    },
};
</script>