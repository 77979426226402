<template>
<div :class="mainBlockClasses">
    <div v-if="label" class="flex">
        <div
            class="text-md text-gray-600 dark:text-white/75 whitespace-nowrap"
            :class="status === 'error' ? 'text-red-400' : ''">{{ label }}</div>
        <n-popover
            v-if="help"
            trigger="hover"
            class="max-w-[500px] rounded-md"
            :class="popoverType === 'info' ? 'bg-main' : 'bg-yellow-600'"
            :placement="helpPosition">
            <template #trigger>
                <n-icon
                    class="text-main cursor-pointer text-lg ml-2"
                    :class="popoverType === 'info' ? 'text-main' : 'text-yellow-600'">
                    <QuestionCircle20Filled />
                </n-icon>
            </template>
            <span class="notes text-xs text-white" v-html="help"></span>
        </n-popover>
    </div>

    <n-select
        filterable
        class="mt-2 text-left"
        :size="size"
        :status="status"
        :loading="loading"
        :disabled="disabled || loading"
        :options="pairsRefsOptions"
        :placeholder="placeholder"
        :render-label="renderLabel"
        :value="value !== -1 ? value : undefined"
        @update:value="$emit('update:value', $event)"/>
    <div v-if="status === 'error'" class="w-full mt-2 text-red-400 text-right">{{ msg }}</div>
</div>
</template>

<script>
// vue
import { h, computed } from 'vue';

// store
import { useRefs } from '@store/ts/refs';

// components
import RbCoin from '@components/rb-coin';
import GridNav from '@components/base/grid-nav.vue';

// i18n
import { useI18n } from 'vue-i18n';

// naive-ui
import {
    NIcon,
    NAlert,
    NSelect,
    NDivider,
    NPopover } from 'naive-ui';

// icons
import { Warning24Filled, QuestionCircle20Filled } from '@vicons/fluent';

export default {
    name: 'RbPairs',
    emits: ['setPerPage', 'update:value'],
    props: {
        disabled: Boolean,
        loading: Boolean,
        label: {
            type: String,
        },
        labelPosition: {
            type: String,
            default: 'top',
            validator: prop => ['right', 'top'].includes(prop),
        },
        size: {
            type: String,
            default: 'medium',
            validator: prop => ['tiny', 'small', 'medium', 'large'].includes(prop),
        },
        help: {
            type: String,
        },
        largeHelp: {
            type: Boolean,
        },
        helpPosition: {
            type: String,
            default: 'right',
        },
        value: {
            type: [String, Number],
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        placeholder: {
            type: String,
            default: () => useI18n().t('select'),
        },
        status: {
            type: String,
            default: undefined,
        },
        msg: {
            type: String,
        },
    },
    components: {
        NIcon,
        NAlert,
        RbCoin,
        NSelect,
        GridNav,
        NDivider,
        NPopover,
        Warning24Filled,
        QuestionCircle20Filled,
    },
    setup(props) {
        // store
        const refs = useRefs();

        const mainBlockClasses = computed(() => {
            if (props.labelPosition === 'left') {
                return 'flex items-center';
            }

            return null;
        });

        const currency = (icon, first = true) => {
            const lIcon = first ? icon.split('/').shift().trim() : icon.split('/');

            const res = Array.isArray(lIcon)
                ? [...lIcon.map(curIcon => refs.currencies.find(el => el.id === curIcon.split('__')[0]))]
                : refs.currencies.find(el => el.id === lIcon);

            return res;
        };

        const pairsRefsOptions = computed(() => {
            const arr = props.options.map(cur => ({
                label: cur.title,
                value: cur.id,
                ...cur,
            }));

            arr.unshift({
                label: props.placeholder,
                value: null,
                style: {
                    color: 'gray',
                },
            });

            return arr;
        });

        const renderLabel = (option) => {
            return !option.value
                ? h('div', {
                    class: 'px-2 text-base',
                }, [option.label])
                : h(
                    'div',
                    {
                        class: 'flex items-center py-1',
                    },
                    [
                        h(RbCoin, {
                            class: 'w-4',
                            coin: currency(option.value, false)[0],
                        }),
                        h(
                            'div',
                            {
                                class: 'px-3',
                            },
                            [
                                h('span', {
                                    class: 'text-xs',
                                }, option.label.split('/')[0]),
                                h('span', {
                                    class: 'opacity-50 px-1',
                                }, '/'),
                                h('span',  {
                                    class: 'text-xs',
                                }, option.label.split('/')[1]),
                            ],
                        ),
                        h(RbCoin, {
                            class: 'w-4',
                            coin: currency(option.value, false)[1],
                        }),
                    ],
                );
        };
        
        return {
            pairsRefsOptions,
            mainBlockClasses,
            currency,
            renderLabel,
        };
    },
};
</script>

<style lang="scss" scoped>
.not-avail {
  color: #888!important;
  cursor: not-allowed!important;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  border-radius: unset!important;
}
</style>