<template>
<section>
    <n-alert v-if="!current" type="info" class="rounded-md" :show-icon="false">
        <div class="text-xs">{{ refs.localization.topUpBalance.select_method }}</div>
    </n-alert>
    <div class="text-gray-600 dark:text-white/75 text-xs mt-4">
        {{ refs.localization.topUpBalance.topup_method }}
    </div>
    <n-button
        tertiary
        class="rounded-md w-full mt-2"
        @click="showCurrencys = true">
        <div class="w-full flex justify-between items-center">
            <div class="flex items-center" v-if="current">
                <rb-coin class="w-5" :coin="currency(current.currency)" />
                <span class="text-gray-600 dark:text-white/75 text-xs pl-2">{{ current.title }}</span>
            </div>
            <span v-else
                class="text-gray-600/50 dark:text-white/40">{{ placeholder }}</span>
            <n-icon size="18"><CaretDown12Filled /></n-icon>
        </div>
    </n-button>
    <template v-if="current">
        <div
            class="flex flex-row items-center justify-between bg-white dark:bg-darkCardBg text-main p-4 rounded-md text-xs mt-4"
            @click="copy(current.account)">
              {{ current.account }}
            <n-icon class="ml-4" size="20"><Copy16Regular /></n-icon>
        </div>
        <div class="bg-white dark:bg-darkCardBg p-4 mt-2 text-xs rounded-md" v-html="current.message"></div>
        <div class="bg-white dark:bg-darkCardBg p-4 mt-2 rounded-md flex justify-center">
            <img class="w-9/12" :src="'data:image/png;base64,'+current.qrcode" />
        </div>
    </template>

    <n-drawer
        v-model:show="showCurrencys"
        width="100%"
        class="bg-gray-100 dark:bg-darkbg">
        <n-drawer-content body-content-style="padding: 0 12px;">
            <div class="flex flex-col h-full">
                <div class="relative w-full bg-gray-100 dark:bg-darkbg text-sm text-center text-gray-600 dark:text-white/75 py-4">
                    <n-icon
                        size="20"
                        class="text-gray-600 dark:text-white/75 absolute top-1/2 left-0 -translate-y-1/2"
                        @click="showCurrencys = false">
                        <ArrowLeft12Regular />
                    </n-icon>
                    {{ refs.localization.topUpBalance.topup_method }}
                </div>
                <!-- <pre>{{ topupSelectMethods }}</pre> -->
                <div
                    class="pb-4 flex content-start justify-between flex-wrap flex-grow overflow-y-scroll overscroll-none">
                    <div
                        v-for="item in topupSelectMethods"
                        :key="item.id"
                        :class="item.id == current?.id ? 'border border-main' : ''"
                        class="px-2 w-[calc(50%-4px)] py-1 dark:bg-[#292929] bg-gray-200 rounded-md flex items-center mb-2"
                        @click="setCurrent(item)">
                        <rb-coin class="w-5" :coin="currency(item.currency)" />
                        <span class="text-gray-600 dark:text-white/75 text-xs pl-2">{{ item.title }}</span>
                    </div>
                </div>
            </div>
        </n-drawer-content>
    </n-drawer>
</section>
</template>

<script>
// vue
import { ref, computed } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useEnv } from '@store/ts/env';
import { useRefs } from '@store/ts/refs';

// naive-ui
import {
    NIcon,
    NAlert,
    NDrawer,
    NButton,
    NDrawerContent,
    useMessage } from 'naive-ui';

// services
import Api from '@services/api';

// i18n
import { useI18n } from 'vue-i18n';

// icons
import {
    Copy16Regular,
    ArrowLeft12Regular,
    CaretDown12Filled } from '@vicons/fluent';

// components
import RbCoin from '@components/rb-coin';

export default {
    name: 'rb-currency-select-mobile',
    props: {
        value: {
            type: [ String, Number ],
        },
        placeholder: {
            type: [ String, Number ],
            default: () => useI18n().t('select'),
        },
    },
    components: {
        NIcon,
        NAlert,
        RbCoin,
        NButton,
        NDrawer,
        Copy16Regular,
        NDrawerContent,
        CaretDown12Filled,
        ArrowLeft12Regular,
    },
    setup() {
        // store
        const gl = useGl();
        const env = useEnv();
        const refs = useRefs();

        // naive-ui
        

        // i18n
        const { t } = useI18n();

        // vars
        const current = ref();
        const showCurrencys = ref(false);

        const topupSelectMethods = computed(() => {
            if (!Array.isArray(env.topupMethods)) return [];

            return env.topupMethods.map(cur => {
                const curEntry = refs.currencies.find(({ id }) => id === cur.currency);
                if (curEntry) {
                    cur.label = cur.title;
                    cur.value = cur.id;

                    return {...curEntry, ...cur};
                }
                return null;
            }).filter(cur => cur);
        });

        const currency = icon => refs.currencies.find(el => el.id === icon);

        const setCurrent = item => {
            current.value = item;
            showCurrencys.value = false;
        };

        const copy = (s) => {
            navigator.clipboard.writeText(s);
            gl.showNotification({
                type: 'success',
                msg: t('copied'),
            });
        };
        
        return {
            refs,
            current,
            showCurrencys,
            topupSelectMethods,
            currency,
            copy,
            setCurrent,
        };
    },
};
</script>

<style lang="scss" scoped>
:deep(.n-button) .n-button__content {
  width: 100%;
}
</style>