<template>
<div :class="labelPosition === 'left' ? 'flex justify-between items-center' : ''">
    <div class="flex mt-2" :class="labelPosition === 'left' ? 'w-2/12' : ''">
        <div
            class="text-xs text-gray-600 dark:text-white/75"
            :class="nowrap ? 'whitespace-nowrap' : ''">
            {{ label }}
        </div>
        <n-popover
            v-if="dataHelp"
            scrollable
            trigger="hover"
            placement="top"
            class="max-w-[200px] max-h-[200px] rounded-md bg-main">
            <template #trigger>
                <n-icon class="text-main cursor-pointer text-xl mx-2">
                    <QuestionCircle20Filled />
                </n-icon>
            </template>
            <span class="text-xs" v-html="dataHelp"></span>
        </n-popover>
    </div>
    <n-button
        tertiary
        size="large"
        :loading="loading"
        :disabled="loading || disabled"
        class="select-button w-full rounded-md pl-2 pr-2"
        :class="label ? 'mt-2' : ''"
        @click="showDrawer = true">
        <div class="w-full flex justify-between items-center">
            <div
                v-if="value"
                class="flex items-center w-[calc(100%-24px)] overflow-x-auto scrollbar-hide">
                <template v-if="multiple && currentValue?.length">
                    <div
                        v-for="checked in currentValue"
                        :key="checked"
                        class="flex items-center rounded-md h-full bg-main bg-opacity-20 border-opacity-50 border border-main p-1 mr-2">
                        <rb-coin class="w-4 h-4 mr-2" :coin="currency(checked.value)"/>
                        {{ checked.label }} 
                    </div>
                </template>
                <template v-else-if="!multiple && currentValue">
                    <div
                        class="flex items-center h-full">
                        <rb-coin class="w-5 h-5 mr-2" :coin="currency(currentValue.value)"/>
                        {{ currentValue.label }} 
                    </div>
                </template>
                <template v-else>
                    <span class="text-gray-600 dark:text-white/75 text-xs pl-2">{{ t('select') }}</span>
                </template>
            </div>
            <span v-else
                class="text-gray-600/50 dark:text-white/40">{{ t('select') }}</span>
            <n-icon size="18"><CaretDown12Filled /></n-icon>
        </div>
    </n-button>
    <n-drawer
        v-model:show="showDrawer"
        width="100%"
        display-directive="show"
        class="bg-gray-100 dark:bg-darkbg"
        :auto-focus="false">
        <n-drawer-content body-content-style="padding: 0">
            <div class="flex flex-col h-full">
                <div class="w-full flex justify-between px-3 items-center bg-gray-100 dark:bg-darkbg text-sm text-center text-gray-600 dark:text-white/75 py-2">
                    <n-icon
                        size="20"
                        class="text-gray-600 dark:text-white/75"
                        @click="showDrawer = false">
                        <ArrowLeft12Regular />
                    </n-icon>
                    <!-- <rb-input v-if="showSearch" v-model:value="query" class="ml-3" /> -->
                    <n-button
                        quaternary
                        class="bg-transparent text-main pr-0"
                        @click="showDrawer = false">
                        {{ t('done') }}
                    </n-button>
                </div>
                <div class="pb-4 flex-grow overflow-y-scroll overscroll-none scrollbar-hide">
                    <template
                        v-for="(item, i) in options"
                        :key="i">
                        <div
                            v-if="item"
                            class="w-full flex justify-between items-center pl-6 pr-3 py-2 rounded-none dark:odd:bg-[#242424] odd:bg-gray-50"
                            @click="setValue(item)">
                            <div class="flex-grow flex items-center">
                                <rb-coin class="w-4 h-4 mr-2" :coin="currency(item.value)"/>
                                {{ item.label }}
                            </div>
                            <n-icon v-if="isChecked(item)" size="20" class="text-main">
                                <Checkmark12Filled />
                            </n-icon>
                        </div>
                    </template>
                </div>
            </div>
        </n-drawer-content>
    </n-drawer>
    <slot name="messages"></slot>
  </div>
</template>

<script>
// vue
import { h, ref, computed } from 'vue';

// store
import { useRefs } from '@store/ts/refs';

// components
import RbCoin from '@components/rb-coin';

// i18n
import { useI18n } from 'vue-i18n';

// icons
import { ArrowLeft12Regular, CaretDown12Filled, Checkmark12Filled, QuestionCircle20Filled } from '@vicons/fluent';

// naive-ui
import {
    NIcon,
    NSelect,
    NButton,
    NDrawer,
    NPopover,
    NDrawerContent } from 'naive-ui';

export default {
    name: 'rb-coin-list-mobile',
    emits: ['update:value'],
    props: {
        label: {
            type: String,
            required: true,
        },
        labelPosition: {
            type: String,
            default: 'top',
            validator: prop => ['right', 'top'].includes(prop),
        },
        dataHelp: {
            type: String,
        },
        filterable: {
            type: Boolean,
            default: true,
        },
        options: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
        },
        value: {
            type: [String, Number],
            required: true,
            default: null,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
        },
        nowrap: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        NIcon,
        RbCoin,
        NDrawer,
        NButton,
        NSelect,
        NPopover,
        NDrawerContent,
        CaretDown12Filled,
        Checkmark12Filled,
        ArrowLeft12Regular,
        QuestionCircle20Filled,
    },
    setup(props, { emit }) {
        // store
        const refs = useRefs();
        
        // i18n
        const { t } = useI18n();

        // vars
        const { _ } = window;
        const showDrawer = ref(false);

        
        const currency = (icon) => refs.currencies.find(el => el.id === icon);

        const renderLabel = option => {
            return h(
                'div',
                {
                    class: 'flex items-center py-1',
                },
                [
                    h(RbCoin, {
                        class: 'w-4',
                        coin: currency(option.value),
                    }),
                    h(
                        'div',
                        {
                            class: 'px-3 text-xs',
                        }, option.label,
                    ),
                ],
            );
        };

        const currentValue = computed(() => {
            if (props.multiple) {
                const result = [];

                for (const item of props.options) {
                    if (Array.isArray(props.value) && ~props.value.findIndex(el => el == item.value)) {
                        result.push(item);
                    }
                }

                return result.length ? result : null;
            } else {
                const i = props.options.findIndex(el => el.value == props.value);
                return ~i ? props.options[i] : null;
            }
            
        });

        const addOrRemove = (array, value) => {
            var index = array.indexOf(value);

            if (index === -1) {
                array.push(value);
            } else {
                array.splice(index, 1);
            }

            return array;
        };

        const setValue = v => {
            if (props.multiple) {
                const copy = Array.isArray(props.value)
                    ? _.cloneDeep(props.value)
                    : [ props.value ];

                emit('update:value', addOrRemove(copy, v.value));
            } else {
                emit('update:value', v.value);
            }
        };

        const isChecked = v => {
            return Array.isArray(props.value)
                ? ~props.value.findIndex(el => el == v.value)
                : props.value == v.value;
        };

        return {
            t,
            showDrawer,
            currentValue,
            renderLabel,
            currency,
            isChecked,
            setValue,
        };
    },
};
</script>

<style lang="scss" scoped>
.select-button :deep(.n-button__content) {
    width: 100%;
}
</style>