
// vue
import { defineComponent } from 'vue';

// naive-ui
import {
    NTag,
    NIcon,
    NCard,
    NButton,
    NDivider } from 'naive-ui';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

// general
import general from './general';

// icons
import {
    ArrowLeft12Regular,
    ArrowRight12Regular } from '@vicons/fluent';

// components
import RbSwitch from '@components/rb-switch/mobile.vue';
import BotReview from '@components/bot-review/mobile.vue';

export default defineComponent({
    name: 'ready-bots-mobile',
    components: {
        NTag,
        NCard,
        NIcon,
        Swiper,
        NButton,
        NDivider,
        RbSwitch,
        BotReview,
        SwiperSlide,
        ArrowLeft12Regular,
        ArrowRight12Regular,
    },
    setup() {
      
        return {
            ...general(),
        };
    },
});
