<template>
<rb-label
    :label="label"
    :helpers="helpers" />
<n-select
    filterable
    size="large"
    :class="label ? 'mt-2' : ''"
    :options="options"
    :value="value"
    :render-tag="renderTag"
    :render-label="renderLabel"
    :placeholder="`- ${t('select')} -`"
    @update:value="$emit('update:value', $event)"/>
</template>

<script>
// vue
import { h, ref, computed } from 'vue';

// naive-ui
import { NSelect } from 'naive-ui';

// components
import RbCoin from '@components/rb-coin';

// i18n
import { useI18n } from 'vue-i18n';

export default {
    name: 'RbWalletSelect',
    emits: ['update:value'],
    components: {
        RbCoin,
        NSelect,
    },
    props: {
        value: [String, Number],
        filterable: {
            type: Boolean,
            default: true,
        },
        placeholder: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: '',
        },
        currencies: {
            type: Object,
            required: true,
        },
        items: {
            default: () => [],
        },
        helpers: {
            type: Array,
            default: () => [],
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n();

        const deposit = ref();

        const options = computed(() => props.items.map(el => ({
            label: el.name,
            value: el.id,
            class: 'w-full',
            code_name: 'rb-cc__' + el.currency,
            ...el,
        })));

        const renderTag = ({ option }) => {
            return h('div', {
                class: 'w-full flex items-center',
            }, [
                h('div',{
                    class: 'h-full',
                }, h(RbCoin, {
                    coin: { ...option, code_name: 'rb-cc__' + option.currency },
                    class: 'w-5 h-5 mr-2',
                })),
                h('div', null, [
                    h('div', {
                        class: 'flex items-end',
                    }, [
                        h('span', {
                            class: 'text-sm font-semibold text-base',
                        }, option.amount),
                        h('span', {
                            class: 'uppercase ml-1 opacity-80 text-[12px]',
                        }, option.currency ),
                    ]),
                    h('div', {
                        class: '-mt-2',
                    }, 
                    h('span', {
                        class: 'text-xs opacity-60',
                    }, option.name)),
                ]),
            ]);
        };

        const renderLabel = option => {
            return h('div', {
                class: 'w-full flex items-center py-[1px]',
            }, [
                h('div',{
                    class: 'h-full',
                }, h(RbCoin, {
                    coin: { ...option, code_name: 'rb-cc__' + option.currency },
                    class: 'w-5 h-5 mr-2',
                })),
                h('div', null, [
                    h('div', {
                        class: 'flex',
                    }, [
                        h('span', {
                            class: 'text-sm font-semibold',
                        }, option.amount),
                        h('span', {
                            class: 'uppercase ml-1 opacity-80',
                        }, option.currency ),
                    ]),
                    h('div', {
                        class: '-mt-1',
                    }, 
                    h('span', {
                        class: 'text-xs opacity-60',
                    }, option.name)),
                ]),
            ]);
        };

        return {
            options,
            deposit,
            t,
            renderTag,
            renderLabel,
        };
    },
};
</script>

<style lang="scss">
.n-base-select-menu .n-base-select-option .n-base-select-option__content {
    width: 100%;
}
</style>