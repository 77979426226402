// vue
import { ref } from 'vue';

export default function (props) {
    // vars
    const localShow = ref(props.show);

    return {
        localShow,
    };
}