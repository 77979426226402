<template>
<section>
    <div class="flex items-center cursor-pointer" @click="localShow = !localShow">
        <div class="text-sm text-gray-600 dark:text-white/75">{{ title }}</div>
        <n-icon class="ml-2 transition transform text-sm" :class="localShow ? 'rotate-90' : ''">
            <IosArrowRtl24Filled />
        </n-icon>
    </div>
    <n-collapse-transition :show="localShow" class="mt-4">
        <slot name="content"></slot>
    </n-collapse-transition>
</section>
</template>

<script>
// vue
import general from './general';

// naive-ui
import { NIcon, NCollapseTransition } from 'naive-ui';

// icons
import { IosArrowRtl24Filled } from '@vicons/fluent';

export default {
    name: 'rb-collapse-transition-desktop',
    props: {
        title: {
            type: String,
            default: '',
        },
        show: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        NIcon,
        IosArrowRtl24Filled,
        NCollapseTransition,
    },
    setup(props) {
        return {
            ...general(...arguments),
        };
    },
};
</script>