<template>
<section>
    <section
        ref="rbRadioGroup"
        class="rounded-md flex items-center px-[4px] py-1 w-min bg-white dark:bg-inputBackground rb-radio-group">
        <template v-for="option in localOptions" :key="option.value">
            <div
                class="rounded-[4px] whitespace-nowrap cursor-pointer flex items-center"
                :class="[...buttonCLass, value == option.value ? 'bg-main text-white/90' : 'hover:text-main']"
                @click="updateValue(option.value)">
                <n-icon
                    v-if="option?.icon"
                    class="text-lg mr-2"
                    :class="option.icon?.classes">
                    <component :is="option.icon?.name" />
                </n-icon>
                {{ option.label }}
            </div>
        </template>
    </section>
</section>
</template>

<script>
// general
import general from './general';

// naive-ui
import {
    NIcon,
    NButton } from 'naive-ui';

export default {
    name: 'rb-radio-group-desktop',
    props: {
        value: {
            type: [String, Number],
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        size: {
            type: String,
            default: 'small',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        NIcon,
        NButton,
    },
    setup(props, context) {
    
        return {
            ...general(...arguments),
        };
    },
};
</script>