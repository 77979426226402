// vue
import { h, ref, reactive, computed, watch, onMounted } from 'vue';

// i18n
import { useI18n } from 'vue-i18n';

// services
import WalletsService from '@services/walletsService';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';

// components
import RbCoin from '@components/rb-coin';

export default function (props, context) {
    // i18n
    const { t } = useI18n();

    // store
    const gl = useGl();
    const refs = useRefs();

    // naive-ui
  

    // vars
    const balancesRefs = ref();
    const walletOptions = ref('select');
    const checkBalanceLoading = ref(false);
    const loadingCreateWallet = ref(false);

    const options = computed(() =>props.tabOptionsRef.map(el => ({
        value: el.id,
        label: el.title,
        ...el,
    })));

    const selectOptions = computed(() => props.selectOptionsRef.map(el => ({
        label: el.name,
        value: +el.id,
        class: 'w-full',
        code_name: 'rb-cc__' + el.currency,
        ...el,
    })));

    const walletSelectHelpers = computed(() => [
        {
            type: 'warning',
            content: props.depoInfo,
        },
    ]);

    const isWalletSelected = computed(() => props.modelValue != -1);
    const walletCurrency = computed(() => {
        if (!props.selectOptionsRef.length) return -1;
        let localWallet = props.selectOptionsRef.find(({ id }) => id == props.modelValue);
        return localWallet?.currency || -1;
    });
    const symbolBalance = computed(() => balancesRefs.value?.[walletCurrency.value] || '');

    const symbolTotalBalance = computed(() => balancesRefs.value?.[`${walletCurrency.value}_total`] || '');

    const createDiabled = computed(() => !walletModel.name.value || !walletModel.amount.value);

    watch(() => props.modelValue, () => {
        balancesRefs.value = null;
    });

    const renderTag = ({ option }) => {
        return h('div', {
            class: 'w-full flex flex-row items-center',
        }, [
            h('span', {
                class: 'text-sm flex-grow',
            }, option.name),
            h('span', {
                class: 'text-sm text-main font-semibold',
            }, option.amount),
            h(RbCoin, {
                coin: option,
                class: 'ml-4 w-4',
            }),
            h('span', {
                class: 'uppercase ml-2 opacity-50',
            }, option.currency),
        ]);
    };

    const renderLabel = (option) => {
        return h('div', {
            class: 'w-full py-1 flex flex-row items-center',
        }, [
            h('span', {
                class: 'text-xs flex-grow',
            }, option.name),
            h('span', {
                class: 'text-sm font-semibold',
            }, option.amount),
            h(RbCoin, {
                coin: option,
                class: 'ml-2 w-4',
            }),
            h('span', {
                class: 'uppercase ml-2 mr-8 opacity-50',
            }, option.currency ),
        ]);
    };

    const walletModel = reactive({
        name: {
            value: '',
            title: props.walletModelInfo.name.title,
            placeholder: props.walletModelInfo.name.placeholder,
            status: undefined,
            msg: undefined,
            class: 'flex-grow w-auto',
            currency: undefined,
        },
        amount: {
            value: '',
            title: props.walletModelInfo.amount.title,
            placeholder: '',
            status: undefined,
            msg: undefined,
            class: 'w-[100px]',
            currency: undefined,
        },
    });

    const onCheckBalance = async () => {
        checkBalanceLoading.value = true;

        const prepare = await WalletsService.getBalances(props.mainData.api_key.value);

        if (!prepare.data.status) {
            prepare.data.errors.forEach(({ msg }) => {
                gl.showNotification({
                    type: 'error',
                    msg,
                });
            });

            // reset balancesRefs
            balancesRefs.value = null;
        } else {
            balancesRefs.value = prepare.data.balances;
        }

        checkBalanceLoading.value = false;
    };

    const onCreateWallet = async () => {
        loadingCreateWallet.value = true;

        const records = {
            name: walletModel.name.value,
            comment: 'Quick Start',
            currency: props.coinSymbolForWallet,
            exchange: props.mainData.exchange.value,
            type: 1,
            amount: walletModel.amount.value,
            api_key: props.mainData.api_key.value,
            _formPath: 'wallets',
        };

        const prepare = await WalletsService.addNew(records);

        if (!prepare.data.status) {
            if (prepare.data?.errors_form) {

                for (let key in walletModel) {
                    const fields = prepare.data.errors_form.wallets.fields;
                    const el = Object.keys(fields).find(el => el === key);

                    if (el) {
                        walletModel[key].status = 'error';
                        walletModel[key].msg = fields[el].msg;
                    } else {
                        walletModel[key].status = 'success';
                        walletModel[key].msg = undefined;
                    }
                }
            }
        } else {
            // clean apiKeyModel
            for (let key in walletModel)
                walletModel[key].value = '';

            // show messages
            prepare.postMessages.forEach(el => {
                gl.showNotification({
                    type: el.success ? 'success' : 'error',
                    msg: el.msg,
                });
            });

            // console.log('data', data);

            const walletEntry = prepare.data.records[0];
            if (!walletEntry) return;

            context.emit('setWallet', walletEntry);
          
            walletOptions.value = 'select';
            context.emit('update:modelValue', +walletEntry.id);
          
        }

        loadingCreateWallet.value = false;
    };

    const currency = (icon) => {
        return refs.currencies.find(el => el.id === icon);
    };

    onMounted(() => {
        walletModel.name.value = props.mainData.name.value + ' — Quick Start';
    });

    return {
        gl,
        options,
        walletModel,
        createDiabled,
        selectOptions,
        walletOptions,
        symbolBalance,
        isWalletSelected,
        symbolTotalBalance,
        checkBalanceLoading,
        loadingCreateWallet,
        walletSelectHelpers,
        t,
        currency,
        renderTag,
        renderLabel,
        onCreateWallet,
        onCheckBalance,
    };
}