<template>
<section>
    <rb-select
        label-position="left"
        :label="mainLabel"
        :options="options"
        v-model:value="walletOptions" />

    <div class="mt-4 text-left" :class="classBlock">
        <template v-if="walletOptions === 'select'">
            <rb-wallet-select
                :label="selectLabel"
                :items="selectOptions"
                :value="modelValue !== -1 ? modelValue : null"
                :helpers="walletSelectHelpers"
                @update:value="$emit('update:modelValue', $event)"/>
            
            <slot name="messages"></slot>
            
            <div class="flex flex-row flex-wrap justify-end mt-4">
                <n-button
                    strong
                    class="rounded-md text-white/90"
                    :color="gl.mainColor"
                    :disabled="!isWalletSelected || checkBalanceLoading"
                    :loading="checkBalanceLoading"
                    @click="onCheckBalance">
                    {{ buttonCheckLabel }}
                </n-button>

                <div class="w-full flex justify-between mt-6">
                    <n-statistic v-if="symbolBalance" :label="symbolBalanceLabel" tabular-nums>
                        <n-number-animation
                            :from="0"
                            :to="+symbolBalance"
                            :precision="8"
                        />
                    </n-statistic>
                    <n-statistic v-if="symbolTotalBalance" :label="symbolTotalBalanceLabel" tabular-nums>
                        <n-number-animation
                            :from="0"
                            :to="+symbolTotalBalance"
                            :precision="8"
                        />
                    </n-statistic>
                </div>
            </div>
        </template>
        <template v-else>
            <div
                v-for="(field, key) in walletModel"
                :key="key"
                class="flex items-end">
                <rb-input
                    class="mb-4"
                    :status="field.status === 'error' ? 'error' : undefined"
                    :msg="field.msg"
                    :label="field.title"
                    :placeholder="field.placeholder"
                    v-model:value="field.value"
                    @update:value="field.status = null, field.msg = null" />
                <div v-if="coinSymbolForWallet && key === 'amount'" class="flex items-center ml-4 mb-4">
                    <rb-coin class="w-6" :coin="currency(coinSymbolForWallet)" />
                    <span class="text-sm text-gray-600 dark:text-white/75 font-semibold uppercase ml-2 whitespace-nowrap">{{ coinSymbolForWallet }}</span>
                </div>
            </div>
            

            <div class="flex flex-wrap justify-end mt-4">
                <n-button
                    strong
                    class="rounded-md text-white/90"
                    :color="gl.mainColor"
                    :loading="loadingCreateWallet"
                    :disabled="createDiabled || loadingCreateWallet"
                    @click="onCreateWallet">
                    + {{ buttonCreateLabel }}
                </n-button>
            </div>
        </template>
    </div>
    <slot name="alerts"></slot>
</section>
</template>

<script>
// general
import general from './general';

// naive-ui
import {
    NIcon,
    NInput,
    NAlert,
    NSelect,
    NButton,
    NPopover,
    NStatistic,
    NRadioGroup,
    NRadioButton,
    NNumberAnimation } from 'naive-ui';

// icons
import { Warning24Filled, QuestionCircle20Filled } from '@vicons/fluent';

// components
import RbCoin from '@components/rb-coin';
import RbInput from '@components/rb-input';
import RbSelect from '@components/rb-select';
import RbWalletSelect from '@components/rb-wallet-select';

export default {
    name: 'RbAlgoSelect',
    emits: ['update:modelValue'],
    props: {
        name: String,
        modelValue: String,
        loading: Boolean,
        buttonCheckLabel: String,
        buttonCreateLabel: String,
        symbolBalanceLabel: String,
        symbolTotalBalanceLabel: String,
        classBlock: String,
        depoInfo: String,
        mainData: {
            type: Object,
            required: true,
        },
        apiKey: {
            type: [String, Number],
            required: true,
        },
        selectLabel: {
            type: String,
            required: true,
        },
        mainLabel: {
            type: String,
            required: true,
        },
        alertLabel: {
            type: String,
        },
        tabOptionsRef: {
            type: Array,
            required: true,
        },
        selectOptionsRef: {
            type: [Array, Object],
            required: true,
        },
        walletModelInfo: {
            type: Array,
            required: true,
        },
        coinSymbolForWallet: {
            required: true,
        },
    },
    components: {
        NIcon,
        NInput,
        RbCoin,
        NAlert,
        NSelect,
        NButton,
        RbInput,
        RbSelect,
        NPopover,
        NStatistic,
        NRadioGroup,
        NRadioButton,
        RbWalletSelect,
        Warning24Filled,
        NNumberAnimation,
        QuestionCircle20Filled,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>