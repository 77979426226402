<template>
<section>
    <div class="flex flex-wrap justify-between items-start">
        <div class="flex items-center">
            <div class="text-md whitespace-nowrap">{{ label }}</div>
            <n-popover
                v-if="help"
                style="width: 300px" trigger="hover" placement="right"
                class="rounded-md bg-main">
                <template #trigger>
                    <n-icon
                        class="cursor-pointer text-lg ml-2 text-main">
                        <QuestionCircle20Filled />
                    </n-icon>
                </template>
                <span
                    class="notes text-xs text-white"
                    v-html="help">
                </span>
            </n-popover>
            <n-popover
                v-if="helpWarning"
                trigger="hover"
                class="max-w-[500px] rounded-md bg-yellow-600"
                placement="top">
                <template #trigger>
                    <n-icon
                        class="cursor-pointer text-lg ml-2 text-yellow-600">
                        <Warning24Filled />
                    </n-icon>
                </template>
                <span class="notes text-xs text-white" v-html="helpWarning"></span>
            </n-popover>
        </div>
        <div class="w-full flex justify-between items-center mt-2">
            <n-button
                strong
                class="rounded-md text-white/90"
                :color="gl.mainColor"
                :disabled="disabled"
                @click.prevent="onDec" >
                <strong class="text-lg">-</strong>
            </n-button>

            <div class="flex flex-grow items-center">
                <n-input
                type="text"
                class="flex-grow mx-2 text-center font-bold rounded-md"
                :placeholder="''"
                :disabled="disabled || hasIntervals"
                :allow-input="onlyAllowNumber"
                :value="inputValue"
                @keyup="onKeyUp"
                @input="onInput" />
                <div class="text-main text-2xl font-bold pr-2">x</div>
            </div>
            
            <n-button
                strong
                class="rounded-md text-white/90"
                :color="gl.mainColor"
                :disabled="disabled"
                @click.prevent="onInc" >
                <strong class="text-lg">+</strong>
            </n-button>
        </div>
        <n-slider
            class="w-full"
            :step="1"
            :value="sliderValue"
            :min="minMax.min"
            :max="minMax.max"
            :marks="sliderMarks"
            :disabled="disabled"
            :format-tooltip="formatTooltip"
            @update:value="onInput"/>
        <slot name="messages"></slot>
    </div>

  <slot name="alerts"></slot>
</section>
</template>

<script>
// vue
import { ref, computed, onMounted } from 'vue';

// naive-ui
import {
    NIcon,
    NInput,
    NSlider,
    NButton,
    NDivider,
    NPopover } from 'naive-ui';

// store
import { useGl } from '@store/ts/gl';

// icons
import { QuestionCircle20Filled, Warning24Filled } from '@vicons/fluent';

export default {
    name: 'RbShoulder',
    emits: ['update:modelValue'],
    props: {
        label: String,
        disabled: Boolean,
        interval: Boolean,
        divider: Boolean,
        modelValue: [String, Number],
        pair: {
            type: Object,
            default: () => ({
                min: 0,
                max: 0,
            }),
        },
        help: {
            type: String,
        },
        helpWarning: {
            type: String,
        },
    },
    components: {
        NIcon,
        NInput,
        NSlider,
        NButton,
        NDivider,
        NPopover,
        Warning24Filled,
        QuestionCircle20Filled,
    },
    setup(props, { emit }) {
        // store
        const gl = useGl();

        const minMax = computed(() => {
            const min = hasIntervals.value
                ? 0
                : parseInt(props.pair.min, 10);

            const max = hasIntervals.value
                ? props.pair.interval.length - 1
                : parseInt(props.pair.max, 10);

            return { min, max };
        });

        const hasIntervals = computed(() => !!props.pair.interval);

        const activeIndex = computed(() => {
            return hasIntervals.value
                ? props.pair.interval.findIndex(el => el == props.modelValue)
                : null;
        });

        const onInc = () => {
            // console.log('onInc');
            if (hasIntervals.value) {
                if (!props.pair.interval[activeIndex.value + 1]) return;
                
                emit('update:modelValue', props.pair.interval[activeIndex.value + 1]);
            } else {
                const { min, max } = minMax.value;
                emit('update:modelValue',  Math.min(Math.max(props.modelValue + 1, min), max));
            }
        };

        const onDec = () => {
            // console.log('onDec');
            if (hasIntervals.value) {
                if (activeIndex.value - 1 < 0) return;
                
                emit('update:modelValue', props.pair.interval[activeIndex.value - 1]);
            } else {
                const { min, max } = minMax.value;
                emit('update:modelValue',  Math.max(Math.min(props.modelValue - 1, max), min));
            }
        };

        const onKeyUp = $event => {
            // console.log('onKeyUp');
            if ($event.keyCode === 38) onInc();
            else if ($event.keyCode === 40) onDec();
        };

        const onInput = $event => {
            // console.log('onInput');
            if ($event == props.modelValue) return;

            if (hasIntervals.value) {
                emit('update:modelValue',  props.pair.interval[$event]);
            } else {
                const { min, max } = minMax.value;
                let leverageValue = parseInt($event, 10);

                if (!isFinite(leverageValue) || String($event) !== String(leverageValue))
                    return void emit('update:modelValue', $event);
              
                leverageValue = leverageValue < min ? min : (leverageValue > max ? max : leverageValue);

                emit('update:modelValue',  leverageValue);
            }
        };

        const sliderMarks = computed(() => {
            const marks = {};

            if (!hasIntervals.value) {
                for (let i = minMax.value.min; i <= minMax.value.max; i += Math.ceil(minMax.value.max / 5))
                    marks[i] = i + 'X';

                marks[minMax.value.max] = minMax.value.max + 'X';
            } else {
                props.pair.interval.forEach((el, i) => {
                    marks[i] = el + 'X';
                });
            }

            return marks;
        });

        const inputValue = computed(() => {
            return hasIntervals.value
                ? props.pair.interval[activeIndex.value]
                : props.modelValue;
        });

        const sliderValue = computed(() => {
            return hasIntervals.value
                ? activeIndex.value
                : props.modelValue;
        });

        const formatTooltip = (value) => {
            return hasIntervals.value
                ? props.pair.interval[value] + 'X'
                : value + 'X';
        };

        onMounted(() => {
            if (!props.modelValue) {
                hasIntervals.value
                    ? emit('update:modelValue',  props.pair.interval[0])
                    : emit('update:modelValue',  parseInt(props.pair.min, 10));
            }
        });

        // const sliderMarks = (value) => {
        //     const mark = { label: `${value}X` };
        //     if (value === 0 || value === this.pair.max) return mark;
        //     const sliderStep = Math.ceil((this.pair.max - this.pair.min) / 6);
				
        //     //return value % sliderStep === 0 ? mark : false
        //     return (value - this.pair.min) % sliderStep === 0 ? mark : false;
        // };

        return {
            gl,
            minMax,
            activeIndex,
            inputValue,
            sliderValue,
            sliderMarks,
            hasIntervals,
            onInc,
            onDec,
            onKeyUp,
            onInput,
            formatTooltip,
            onlyAllowNumber: v => !v || /^\d+$/.test(v),
        };
    },
};
</script>