<template>
<section class="mt-4">
    <n-skeleton v-if="!profile.localization" class="inline-block" :width="215" :height="20" :sharp="false" />
    <div v-else class="text-md text-gray-600 dark:text-white/75 font-semibold text-left">
        {{ profile.localization['profile/marketplace/mentorship/header'] }}
    </div>

    <rb-switch
        id="is_mentor"
        class="mt-4"
        :label="profile.localization['profile/marketplace/mentorship/participate_as_mentor']"
        v-model:value="dataForm.is_mentor" />

    <rb-input
        id="profile__mentorship_price"
        class="mt-4"
        :status="profile.errors?.mentorship_price ? 'error' : undefined"
        :msg="profile.errors?.mentorship_price?.msg"
        :disabled="!dataForm.is_mentor"
        :label="profile.localization['profile/marketplace/mentorship/mentorship_price']"
        :value="pointless(dataForm.mentorship_price)"
        @update:value="onMentorshipPriceInput($event), profile.cleanError('mentorship_price')" >
        <template #beforeInput>
            <strong class="text-xl text-main whitespace-nowrap mr-1">0.</strong>
        </template>
    </rb-input>

    <rb-input
        id="profile__mentor_contact"
        class="mt-4"
        :status="profile.errors?.mentor_contact ? 'error' : undefined"
        :msg="profile.errors?.mentor_contact?.msg"
        :disabled="!dataForm.is_mentor"
        :label="profile.localization['profile/marketplace/mentorship/mentor_contact']"
        v-model:value="dataForm.mentor_contact"
        @update:value="profile.cleanError('mentor_contact')" />
    <n-divider />

    <n-alert class="w-full rounded-md" type="warning">
        <div class="notes font-semibold text-xs" v-html="profile.localization['profile/marketplace/mentorship/mentor_message']"></div>
    </n-alert>

    <section id="profile__mentor_notes" class="rounded-md overflow-hidden mt-4">
        <quill-editor
            theme="snow"
            contentType="html"
            v-model:content="profile.fullInfo.mentor_message"
            @update:content="profile.cleanError('mentor_notes')">
        </quill-editor>
        <div v-if="profile.errors?.mentor_notes" class="w-full mt-2 text-red-400 text-right">{{ profile.errors.mentor_notes.msg }}</div>
    </section>
</section>
<n-button
    strong
    size="large"
    class="rounded-md text-white/90 sticky bottom-0 w-full mt-4"
    :disabled="loading"
    :loading="loading"
    :color="gl.mainColor"
    @click="onUpdate">
    {{ profile.localization['profile_save_changes_button'] }}
</n-button>

<current-password
    :loading="loading"
    v-model:show="showPassModal"
    @confirm="onUpdate" />
</template>

<script>
// vue
import { ref, reactive, watch } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useProfile } from '@store/profile';

// services
import ProfileService from '@services/profileService';

// icons
import { Warning24Filled, Dismiss16Filled, IosArrowRtl24Filled } from '@vicons/fluent';

// components
import RbInput from '@components/rb-input/mobile.vue';
import RbSelect from '@components/rb-select/mobile.vue';
import RbSwitch from '@components/rb-switch/mobile.vue';
import CurrentPassword from '@components/current-password/mobile.vue';

// editor
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

// naive-ui
import {
    NIcon,
    NCard,
    NAlert,
    NButton,
    NDivider,
    NCollapseTransition,
    useNotification } from 'naive-ui';

export default {
    name: 'marketplace-settings-mobile',
    components: {
        NIcon,
        NCard,
        NAlert,
        NButton,
        RbInput,
        RbSelect,
        NDivider,
        RbSwitch,
        QuillEditor,
        CurrentPassword,
        Warning24Filled,
        Dismiss16Filled,
        IosArrowRtl24Filled,
        NCollapseTransition,
    },
    setup() {
        // store
        const gl = useGl();
        const refs = useRefs();
        const profile = useProfile();

        // ui
        const notification = useNotification();

        // vars
        const show = ref(false);
        const loading = ref(false);
        const showPassModal = ref(false);
        const dataForm = reactive({
            is_mentor: profile.fullInfo.is_mentor,
            mentorship_price: profile.fullInfo.mentorship_price,
            mentor_contact: profile.fullInfo.mentor_contact,
            mentor_message: profile.fullInfo.mentor_message,
        });

        watch(() => dataForm.is_mentor, v => {
            if (!v) {
                profile.cleanError('mentorship_price');
                profile.cleanError('mentor_contact');
                profile.cleanError('mentor_notes');
            };
        });

        const onMentorshipPriceInput = $event => {
            let newPrice = String($event);
            const dotIdx = newPrice.indexOf('.');
            if (dotIdx !== -1) {
                newPrice = newPrice.substring(dotIdx + 1);
            }

            if (/[^\d]/.test(newPrice)) {
                return;
            }
        
            dataForm.mentorship_price = $event;
        };

        const pointless = val => {
        // eslint-disable-next-line no-param-reassign
            val = String(val);
            const dotIdx = String(val).indexOf('.');
            return dotIdx === -1 ? val : val.substring(dotIdx + 1);
        };

        const onUpdate = async () => {
            loading.value = true;

            if (!profile.fullInfo.current_password || profile.errors.current_password ) {
                showPassModal.value = true;
                loading.value = false;
                return;
            }
        
            let payload = { ...profile.fullInfo, ...dataForm, _formPath: 'profile.update' };
            payload.messengers = payload.messengers.map((messenger, index) => ({...messenger, _formPath: `messenger#${index}`}));

        
            const data = await ProfileService.update({ profile: payload, ...gl.stageExtra?.extra});
                
            if (!data.data.status) {
                profile.errors = data.errors_form['profile.update']?.fields;

                if (!Object.keys(profile.errors).includes('current_password')) {
                    showPassModal.value = false;

                    Object.keys(profile.errors).forEach(el => {
                        setTimeout(() => {
                            document
                                .querySelector(`#profile__${el}`)
                                .scrollIntoView({ block: 'start', behavior: 'smooth' });
                        }, 500);
                    });

                    // show messages
                    if (data.postMessages)
                        data.postMessages.forEach(el => {
                            if (el.success)
                                notification.success({
                                    content: el.msg,
                                    duration: 2500,
                                    keepAliveOnHover: true,
                                });
                        });
                }
            } else {
                profile.errors = {};
                showPassModal.value = false;

                for (const [ key, value ] of Object.entries(data.data)) {
                    profile.fullInfo[key] = value;
                }

                // show messages
                if (data.postMessages)
                    data.postMessages.forEach(el => {
                        gl.showNotification({
                            type: el.success ? 'success' : 'error',
                            msg: el.msg,
                        });
                    });
            }
            
            loading.value = false;
        };

        return {
            gl,
            refs,
            show,
            profile,
            loading,
            dataForm,
            showPassModal,
            onUpdate,
            pointless,
            onMentorshipPriceInput,
        };
    },
};
</script>