<template>
<n-card
    size="small"
    :bordered="false"
    class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-md"
    :segmented="{
        content: true,
        footer: 'soft'
    }"
    content-style="padding-top: 0;">
    <template #header>
        <div class="flex justify-between items-center">
            <n-skeleton v-if="!refs.localization" class="inline-block" :width="215" :height="20" :sharp="false" />
            <div v-else class="text-xs text-gray-600 dark:text-white/75 font-semibold text-left">
                {{ refs.localization.filters.general.filters_label }}
            </div>
            <n-button
                v-if="filtersChanged"
                secondary
                type="error"
                size="tiny"
                class="rounded-md"
                @click="resetAllFilters">
                {{ refs.localization.filters.general.filters_reset_label }}
            </n-button>
        </div>
    </template>
    <template #default>
        <section class="flex flex-wrap items-end">
            <template v-for="(filter, i) in modifiedRefFilters" :key="i">
                <date-picker
                    v-if="filter.mode === 'date_picker'"
                    :data="filter"
                    :filters="filters"
                    :label="filter.title"
                    class="w-full mt-2"
                    @updateDate="onUpdateDate($event, filter)" />

                <!-- bots -->
                <bots
                    v-if="filter.mode === 'bots'"
                    class="w-full mt-3"
                    :data="filter"
                    :filters="filters"
                    :visible="visibleFilters.includes(filter.filter)"
                    @changeFilter="$emit('changeFilter', $event)"
                    @setVisibleFilters="setVisibleFilters($event)" />

                <!-- exchanges -->
                <exchanges
                    v-if="filter.mode === 'exchanges'"
                    class="w-full mt-3"
                    :data="filter"
                    :filters="filters"
                    :visible="visibleFilters.includes(filter.filter)"
                    @changeFilter="$emit('changeFilter', $event)"
                    @setVisibleFilters="setVisibleFilters($event)" />

                <!-- checkbox group -->
                <checkbox-group
                    v-if="filter.mode === 'checkbox_group'"
                    class="w-full mt-3"
                    :data="filter"
                    :filters="filters"
                    :visible="visibleFilters.includes(filter.filter)"
                    @changeFilter="$emit('changeFilter', $event)"
                    @setVisibleFilters="setVisibleFilters($event)" />

                <!-- radio group -->
                <radio-group
                    v-if="filter.mode === 'radio_group'"
                    class="w-full mt-3"
                    :data="filter"
                    :filters="filters"
                    :visible="visibleFilters.includes(filter.filter)"
                    @changeFilter="$emit('changeFilter', $event)"
                    @setVisibleFilters="setVisibleFilters($event)" />

                <!-- filter coins -->
                <filter-coins
                    v-if="filter.mode === 'filter_coins'"
                    class="w-full mt-3"
                    :data="filter"
                    :filters="filters"
                    :visible="visibleFilters.includes(filter.filter)"
                    @changeFilter="$emit('changeFilter', $event)"
                    @setVisibleFilters="setVisibleFilters($event)" />
            </template>
        </section>

        <section class="flex mt-2">
            <n-dropdown
                scrollable
                trigger="click"
                style="max-height: 300px;"
                class="bg-white dark:bg-darkCardBg"
                placement="bottom-start"
                :show="showDropdownRef"
                :options="modifiedRefFilters"
                @clickoutside="showDropdownRef = false">
                <n-button
                    class="rounded-md mr-2 mt-3 flex-grow"
                    @click="showDropdownRef = true">
                    + {{ refs.localization.filters.general.filters_add_filters_label }}
                </n-button>
            </n-dropdown>
            
            <n-button
                strong
                class="rounded-md font-semibold mt-3 text-white/90"
                :color="gl.mainColor"
                :disabled="loading"
                :loading="loading"
                @click="$emit('getData')">
                {{ refs.localization.filters.general.filters_apply_label }}
            </n-button>
        </section>
    </template>
</n-card>
<section v-if="filtersList">
    <n-button
        secondary
        size="medium"
        type="default"
        class="rounded-md text-main dark:text-white/75 dark:bg-main bg-white px-2"
        @click="showDrawer = true">
        <n-icon size="25"><Filter20Filled /></n-icon>
        <!-- {{ t('filters') }}  -->
    </n-button>
    <n-drawer
        v-model:show="showDrawer"
        width="100%"
        display-directive="show"
        class="bg-gray-100 dark:bg-darkbg"
        :auto-focus="false">
        <n-drawer-content body-content-style="padding: 0">
            <div class="flex flex-col h-full">
                <div class="relative w-full bg-gray-100 dark:bg-darkbg text-sm text-center text-gray-600 dark:text-white/75 py-4">
                    <n-icon
                        size="20"
                        class="text-gray-600 dark:text-white/75 absolute top-1/2 left-2 -translate-y-1/2"
                        @click="showDrawer = false">
                        <ArrowLeft12Regular />
                    </n-icon>
                    {{ t('filters') }}
                    <n-button
                        v-if="filtersChanged"
                        secondary
                        size="tiny"
                        type="error"
                        class="rounded-md flex items-center absolute top-1/2 right-3  -translate-y-1/2"
                        @click="showDrawer = false, $emit('reset-filters')">
                        {{ t('resetSettings')}}
                    </n-button>
                </div>
                <div class="pb-4 flex-grow overflow-y-scroll overscroll-none scrollbar-hide px-3">
                    <template
                        v-for="(filter, i) in filtersList"
                        :key="i">
                        <template v-if="filter.mode === 'radio_group'">
                            <radio-group
                                :data="filter"
                                :filters="filters"
                                @changeFilter="$emit('changeFilter', $event)" />
                        </template>
                        <template v-else-if="filter.mode === 'date_picker'">
                            <date-picker
                                :data="filter"
                                :filters="filters"
                                @updateDate="updateDate($event, filter)" />
                        </template>
                        <template v-else-if="filter.mode === 'bots'">
                            <bots
                                :data="filter"
                                :filters="filters"
                                @changeFilter="$emit('changeFilter', $event)" />
                        </template>
                        <template v-else-if="filter.mode === 'exchanges'">
                            <exchanges
                                :data="filter"
                                :filters="filters"
                                @changeFilter="$emit('changeFilter', $event)" />
                        </template>
                        <template v-else-if="filter.mode === 'checkbox_group'">
                            <checkbox-group
                                :data="filter"
                                :filters="filters"
                                @changeFilter="$emit('changeFilter', $event)" />
                        </template>
                        <template v-else-if="filter.mode === 'filter_coins'">
                            <filter-coins
                                :data="filter"
                                :filters="filters"
                                @changeFilter="$emit('changeFilter', $event)" />
                        </template>
                        <template v-else-if="filter.mode === 'filter_switch'">
                            <div class="flex justify-between">
                                <div class="text-md text-gray-600 dark:text-white/75 whitespace-nowrap">
                                    {{ filter.label }}
                                </div>
                                <rb-switch
                                    :value="filter.value"
                                    @update:value="$emit('changeFilter', [filter.filter, $event])" />
                            </div>
                        </template>
                        <template v-else-if="filter.mode === 'filter_button_groupe'">
                            <div class="flex items-center justify-between">
                                <div class="text-md text-gray-600 dark:text-white/75 whitespace-nowrap">
                                    {{ filter.label }}
                                </div>
                                <rb-radio-group
                                    size="small"
                                    :options="filter.refs"
                                    :value="filter.value"
                                    @update:value="$emit('changeFilter', [filter.filter, $event])" />
                            </div>
                        </template>

                        <n-divider v-if="i < filtersList.length" class="my-3" />
                    </template>

                    <div class="flex sticky bottom-0 z-50 mt-6">
                        <div class="w-6/12 pr-1">
                            <n-button
                                strong
                                class="rounded-md text-gray-600 dark:text-white/75 w-full text-sm"
                                :disabled="loading"
                                :loading="loading"
                                @click="showDrawer = false">
                                {{ refs.localization.filters.all_select.btn__cancel }}
                            </n-button>
                        </div>
                        <div class="w-6/12 pl-1">
                            <n-button
                                strong
                                class="rounded-md text-white/90 sticky bottom-0 w-full text-sm"
                                :disabled="loading"
                                :loading="loading"
                                :color="gl.mainColor"
                                @click="$emit('getData'), showDrawer = false">
                                {{ refs.localization.filters.all_select.btn__apply }}
                            </n-button>
                        </div>
                    </div>
                </div>
            </div>
        </n-drawer-content>
    </n-drawer>
</section>
</template>

<script>
// vue
import { defineComponent } from 'vue';

// general
import general from './general';

// naive-ui
import {
    NCard,
    NIcon,
    NModal,
    NInput,
    NButton,
    NDrawer,
    NDivider,
    NSkeleton,
    NDropdown,
    NRadioGroup,
    NRadioButton,
    NDrawerContent,
    NCheckbox } from 'naive-ui';

// i18n
import { useI18n } from 'vue-i18n';

// components
import RbSwitch from '@components/rb-switch';
import Bots from '@components/filters/bots/mobile.vue';
import Exchanges from '@components/filters/exchanges/mobile.vue';
import RadioGroup from '@components/filters/radio-group/mobile.vue';
import DatePicker from '@components/filters/date-picker/mobile.vue';
import FilterCoins from '@components/filters/filter-coins/mobile.vue';
import CheckboxGroup from '@components/filters/checkbox-group/mobile.vue';

// icons
import {
    Filter20Filled,
    Dismiss16Filled,
    ColumnEdit20Filled,
    ArrowLeft12Regular } from '@vicons/fluent';

export default defineComponent({
    name: 'filters-mobile',
    props: {
        refFilters: {
            type: [ Object, Array ],
        },
        filters: {
            type: Object,
            required: true,
        },
        filtersChanged: {
            type: Boolean,
            default: false,
        },
        filterId: {
            type: String,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Bots,
        NIcon,
        NCard,
        NDrawer,
        NButton,
        RbSwitch,
        NDivider,
        NDropdown,
        NSkeleton,
        Exchanges,
        RadioGroup,
        DatePicker,
        NRadioGroup,
        FilterCoins,
        NRadioButton,
        CheckboxGroup,
        NDrawerContent,
        Filter20Filled,
        ArrowLeft12Regular,
    },
    setup(props, context) {
        
        return {
            ...general(props, context),
        };
    },
});
</script>