// vue
import { computed } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useQuickStart } from '@store/quickStart';

export default function (props, context) {
    // store
    const gl = useGl();
    const quickStart = useQuickStart();

    const disabled = computed(() => !props.checked);

    const minMax = computed(() => {
        const min = parseInt(props.pair.min, 10);
        const max = parseInt(props.pair.max, 10);
        return { min, max };
    });

    const sliderMarks = computed(() => {
        const marks = {};

        for (let i = minMax.value.min; i <= minMax.value.max; i++) {
            if (i === 0 || i === minMax.value.max) {
                marks[i] = i;
                continue;
            }

            const sliderStep = Math.ceil((minMax.value.max - minMax.value.min) / 6);
            if ((i - minMax.value.min) % sliderStep === 0) {
                marks[i] = i;
                continue;
            }
        }

        return marks;
    });

    const onDec = () => {
        const { min, max } = minMax.value;
        context.emit('update:modelValue', Math.min(Math.max(props.modelValue - 1, min), max));
    };

    const onInc = () => {
        const { min, max } = minMax.value;
        context.emit('update:modelValue', Math.max(Math.min(props.modelValue + 1, max), min));
    };

    const onInput = $event => {
        if ($event === '') {
            return void context.emit('update:modelValue', $event);
        }

        const { min, max } = minMax.value;

        if ($event < min) {
            context.emit('update:modelValue', 1);
        } else if ($event > max) {
            context.emit('update:modelValue', minMax.value.max);
        } else {
            context.emit('update:modelValue', $event);
        }
    };

    return {
        gl,
        minMax,
        disabled,
        quickStart,
        sliderMarks,
        onDec,
        onInc,
        onInput,
        onlyAllowNumber: v => !v || /^\d+$/.test(v),
    };
}