<template>
<section class="min-h-screen">
    <template v-if="init">
        <mobile v-if="gl.isMobile" class="mt-4" />
        <desktop v-else />
    </template>
</section>
</template>

<script>
// vue
import { ref, onMounted } from 'vue';

// components
import Desktop from './desktop.vue';
import Mobile from './mobile.vue';

// services
import StatsService from '@services/statsService';

// store
import { useStats } from '@store/stats';
import { useGl } from '@store/ts/gl';

// i18n
import { useI18n } from 'vue-i18n';

export default {
    name: 'trade-statistic-view',
    components: {
        Mobile,
        Desktop,
    },
    setup() {
        // store
        const gl = useGl();
        const stats = useStats();

        // vars
        const init = ref(false);

        // i18n
        const { t } = useI18n();

        const getData = async () => {
            gl.showLoading = true;

            try {
                if (!stats.refs)
                    stats.refs = await StatsService.getRefs();
            } catch {
                gl.showNotification({
                    type: 'error',
                    msg: t('errorMessage'),
                });
            };

            gl.showLoading = false;
        };

        onMounted(async () => {
            await getData();

            init.value = true;
        });
    
        return {
            gl,
            init,
        };
    },
};
</script>