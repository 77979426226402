<template>
<section class="px-3">
    <div class="flex justify-between items-center">
        <n-skeleton v-if="!dashBoard.localization" class="inline-block" :width="215" :height="20" :sharp="false" />
        <div v-else class="text-md text-gray-600 dark:text-white/75 font-semibold text-left">
            {{ dashBoard.localization.dashboard_account_info_title }}
        </div>

        <n-button
            circle
            tertiary
            @click="update"
            :disabled="loading || updateLoading"
            :loading="updateLoading">
            <n-icon v-if="!updateLoading" size="25"><ArrowSyncCircle16Regular /></n-icon>
        </n-button>
    </div>

    <n-card
        size="small"
        :bordered="false"
        class="mt-2 overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-md"
        :segmented="{
          content: true,
          footer: 'soft'
        }">
        <section class="flex">
            <div class="text-left">
                <n-skeleton v-if="!dashBoard.localization" class="inline-block" :width="50" :height="14" :sharp="false" />
                <div v-else class="text-gray-400 dark:text-white/50">{{ dashBoard.localization?.dashboard_account_info_status }}</div>

                <n-skeleton v-if="loading" class="inline-block mt-1" :width="70" :height="18" :sharp="false" />
                <div v-else class="text-gray-600 dark:text-white/75 text-md font-semibold mt-1">{{ refs.status }}</div>

                <n-badge v-if="refs.tasks.enabled" :value="refs.countUnshiftedTask" class="mt-2">
                    <n-button
                        size="tiny"
                        class="rounded-md"
                        :disabled="loading"
                        @click="router.push({ name: 'tasks' })">
                        <div class="text-xs font-semibold">{{ refs.header?.tasks || 'Задания' }}</div>
                    </n-button>
                </n-badge>
            </div>
            <div class="text-left ml-6">
                <n-skeleton v-if="!dashBoard.localization" class="inline-block" :width="30" :height="14" :sharp="false" />
                <div v-else class="text-gray-400 dark:text-white/50">{{ dashBoard.localization?.account_info_id }}</div>

                <n-skeleton v-if="loading" class="inline-block mt-1" :width="15" :height="18" :sharp="false" />
                <div v-else class="text-gray-600 dark:text-white/75 text-md font-semibold mt-1">{{ env.ID }}</div>
            </div>
            <div class="text-left ml-6">
                <n-skeleton v-if="!dashBoard.localization" class="inline-block" :width="120" :height="14" :sharp="false" />
                <div v-else class="text-gray-400 dark:text-white/50">{{ dashBoard.localization?.dashboard_account_info_commission_balance }}</div>

                <n-skeleton v-if="loading" class="inline-block mt-1" :width="100" :height="18" :sharp="false" />
                <div v-else class="flex items-center text-md font-semibold" :class="env.balance > 0 ? 'text-green-600' : 'text-red-500'">
                    {{ env.balance }}
                    <rb-coin class="w-4 ml-2" :coin="currency" />
                </div>
            </div>
        </section>
        <section class="flex justify-start">
            <n-tag v-if="gl.timeToShutdown?.timeToShutdown || gl.timeToShutdown?.timeToStop" type="error" size="small" round>
                <span class="mr-1">{{ gl.timeToShutdown.status === 2 ? refs.localization.header.stop : refs.localization.header.suspend }}</span>
                <strong @click="showTopUpYourBalance = true" class="underline cursor-pointer hover:no-underline">
                    {{ gl.timeToShutdown.status === 2 ? gl.timeToShutdown?.timeToStop : gl.timeToShutdown?.timeToShutdown }}
                </strong>
            </n-tag>
        </section>
        <template #footer>
            <section class="flex flex-grow">
                <n-button
                    secondary
                    size="tiny"
                    type="default"
                    class="rounded-md mr-1"
                    :disabled="loading"
                    @click="window.open('https://desk.revenuebot.io/en/%D0%B1%D0%B0%D0%B7%D0%B0-%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B9', '_blank')">
                    {{ refs.localization.header.knowledge_base }}
                </n-button>

                <n-button
                    secondary
                    size="tiny"
                    type="default"
                    class="rounded-md flex-grow ml-1"
                    :disabled="loading"
                    @click="window.open('https://www.youtube.com/channel/UCrAyFAxvDzPIwYKgdSDwCFg', '_blank')">
                    {{ refs.localization.header.educational_videos }}
                </n-button>
            </section>
            <section class="w-full flex flex-wrap">
                <div class="pr-1 w-8/12 mt-2">
                    <n-button
                        secondary
                        size="tiny"
                        type="default"
                        class="rounded-md w-full"
                        :disabled="loading"
                        @click="window.open('https://appt.link/consultation_from_support/revenuebot', '_blank')">
                        {{ refs.localization.header.free_consultations }}
                    </n-button>
                </div>

                <div class="pl-1 w-4/12 mt-2">
                    <n-button
                        secondary
                        size="tiny"
                        type="default"
                        class="rounded-md w-full"
                        :disabled="loading"
                        @click="window.open(refs.lang === 'ru'
                            ? 'https://t.me/revenuebot_user'
                            : 'https://t.me/revenuebot_users_en', '_blank')">
                        <template #icon>
                            <telegram-icon class="fill-[#1A6DE0]" />
                        </template>
                        UsersChat
                    </n-button>
                </div>
            </section>
        </template>
    </n-card>

    <n-skeleton v-if="!dashBoard.localization" class="inline-block mt-2 w-full rounded-md" :height="48" :sharp="false" />
    <n-button
        v-else
        strong
        size="large"
        class="mt-2 w-full rounded-md border border-main border-solid h-12"
        :disabled="dashBoard.loading"
        @click="showTopUpYourBalance = true">
        <span class="text-gray-600 dark:text-white/75">+ {{ dashBoard.localization?.dashboard_account_info_topup_balance_button }}</span>
    </n-button>

    <topup-balance
        v-model:show="showTopUpYourBalance" />
</section>
</template>

<script>
// vue
import { ref, computed } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useEnv } from '@store/ts/env';
import { useRefs } from '@store/ts/refs';
import { useUser } from '@/store/user';
import { useDashBoard } from '@/store/dashBoard';

// naive-ui
import {
    NTag,
    NCard,
    NIcon,
    NBadge,
    NDrawer,
    NButton,
    NSelect,
    NAvatar,
    NCheckbox,
    NSkeleton,
    NDrawerContent,
    useMessage } from 'naive-ui';

// services
import Api from '@services/api';

// icons
import { ArrowLeft12Regular, ArrowSyncCircle16Regular } from '@vicons/fluent';

// router
import { useRouter } from 'vue-router';

// components
import RbCoin from '@components/rb-coin';
import TopupBalance from '@components/topup-balance';
import TelegramIcon from '@components/icons/telegram.vue';
import RbCurrencySelect from '@components/rb-currency-select/mobile.vue';

export default {
    name: 'account-info-mobile',
    components: {
        NTag,
        NCard,
        NIcon,
        NBadge,
        RbCoin,
        NButton,
        NDrawer,
        NSkeleton,
        TopupBalance,
        TelegramIcon,
        NDrawerContent,
        RbCurrencySelect,
        ArrowLeft12Regular,
        ArrowSyncCircle16Regular,
    },
    setup() {
        // store
        const gl = useGl();
        const env = useEnv();
        const user = useUser();
        const refs = useRefs();
        const dashBoard = useDashBoard();

        // router
        const router = useRouter();

        // vars
        const updateLoading = ref(false);
        const showTopUpYourBalance = ref(false);

        const loading = computed(() => gl.loading || dashBoard.loading);
        const currency = computed(() => refs.currencies.find(el => el.id === env.profitSymbol));

        const update = async () => {
            updateLoading.value = true;

            dashBoard.botsStatsInfo = ( await Api.dashBoard({
                dashboard_block: 'bot_stats',
            }) ).data.bot_stats;

            updateLoading.value = false;
        };

        const forceUpdate = async () => {
            const result = await Promise.allSettled([
                Api.dashBoardRefs(),
                Api.dashBoard(),
                Api.getEnv(),
            ]);

            dashBoard.refs = result[0].value;
            dashBoard.data = result[1].value;
        };

        return {
            gl,
            env,
            refs,
            window,
            router,
            loading,
            currency,
            dashBoard,
            updateLoading,
            showTopUpYourBalance,
            update,
        };
    },
};
</script>