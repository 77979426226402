<template>
<section>
    <template v-if="!apiKeysStats?.total.total">
        <n-card
            size="small"
            :bordered="false"
            class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg"
            :segmented="{
                content: true,
                footer: 'soft'
            }">
            <template #default>
                <div class="border border-dashed border-gray-600 rounded-md flex flex-col items-center py-6">
                    <ApiKeysIcon class="w-24 fill-current" />
                    <div class="mt-2">
                        {{ dashBoard.localization?.dashboard_exchange_balances_empty_message }}
                    </div>
                    <n-button
                        strong
                        class="mt-4 text-white/90 rounded-md"
                        :color="gl.mainColor"
                        @click="showCreateApiKey = true">
                        + {{ dashBoard.localization?.dashboard_exchange_balances_add_exchange }}
                    </n-button>
                </div>
            </template>
        </n-card>
    </template>
    <template v-else>
        <section class="flex items-center justify-between text-base text-left text-gray-600 dark:text-white/75 font-semibold">
            <div class="flex items-center">
                <n-skeleton v-if="!dashBoard.localization" :width="215" :height="20" :sharp="false" />
                <template v-else>{{ dashBoard.localization['dashboard_exchange_balances_title'] }}</template>
    
                <n-skeleton v-if="!dashBoard.localization" class="inline-block ml-6 rounded-2xl" :width="125" :height="34" :sharp="false" />
                <n-button
                    v-else
                    round
                    strong
                    class="ml-6 text-white/90"
                    :color="gl.mainColor"
                    @click="showCreateApiKey = true">
                    + {{ dashBoard.localization?.dashboard_exchange_balances_add_exchange }}
                </n-button>
            </div>
        </section>
    
        <section class="flex flex-wrap items-stretch mt-4">
            <section class="w-7/12 pr-1">
                <n-card
                    size="small"
                    class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg h-[270px]"
                    content-style="padding: 0;"
                    :bordered="false"
                    :segmented="{
                        content: true,
                        footer: 'soft'
                    }">
                    <template #header>
                        <section class="flex items-center justify-between">
                            <n-skeleton v-if="!dashBoard.localization" class="inline-block" :width="215" :height="20" :sharp="false" />
                            <div v-else class="text-sm text-gray-600 dark:text-white/75 font-semibold text-left flex items-center">
                                <rb-help :data="dashBoard.localization.dashboard_exchange_balances_total_balances_title_help" />
                                <div class="ml-2">{{ dashBoard.localization.dashboard_exchange_balances_total_balances_title }}</div>
                            </div>
    
                            <n-skeleton v-if="!dashBoard.localization" class="inline-block ml-2" :width="66" :height="22" :sharp="false" />
                            <n-button
                                v-else
                                strong
                                size="tiny"
                                class="ml-2 rounded-md"
                                type="tertiary"
                                @click="update"
                                :loading="localLoading || !canUpdate"
                                :disabled="localLoading || !canUpdate">
                                {{ dashBoard.localization.dashboard_account_info_auto_refresh_button }}
                            </n-button>
                        </section>
                        
                        <n-skeleton v-if="!dashBoard.localization" :height="4" class="w-full mt-2" :sharp="false" />
                        <n-progress v-else-if="!canUpdate" :height="4" class="mt-2" type="line" :percentage="percentage" :show-indicator="false" />
                    </template>
                    <template #default>
                        <template v-if="!dashBoard.localization">
                            <div
                                v-for="i in 3"
                                :key="i"
                                class="w-full dark:odd:bg-[#242424] odd:bg-gray-50 px-2 flex items-center py-3">
                                <n-skeleton :height="20" class="w-full" :sharp="false" />
                            </div>
                        </template>
                        <template v-else>
                            <div
                                v-for="(total, i) in totalInfo"
                                :key="i"
                                class="w-full dark:odd:bg-[#242424] odd:bg-gray-50 px-2 flex items-center py-1">
                                
                                <rb-title
                                    :help="total.help"
                                    :label="total.label"
                                    helpPosition="left"/>
    
                                <template v-if="total.type === 'info'">
                                    <div
                                        class="flex items-center ml-2 text-base font-semibold py-1">
                                        {{ total.value }}
                                    </div>
                                </template>
                                <template v-else-if="total.type === 'totalAvailable'">
                                    <div
                                        v-if="total.totalBtc"
                                        class="flex items-center ml-4 text-base font-semibold"
                                        :class="total.totalBtc >= 0 ? 'text-green-600' : 'text-red-500'">
                                        {{ total.totalBtc }}
                                        <rb-coin class="w-5 ml-2" :coin="currency(env?.profitSymbol)" />
                                    </div>
                                    <div 
                                        class="flex items-center ml-2 text-base text-lightMain"
                                        :class="total.totalBtc ? 'ml-2' : 'ml-4'">
                                        <span v-if="total.totalBtc" class="text-xl -mt-1">&asymp;</span>
                                        &nbsp;<span>{{ total.totalUsdt }}</span>
                                        <rb-coin class="w-5 ml-2" :coin="currency('usdt')" />
                                    </div>
                                </template>
                                <template v-else-if="total.type === 'pnl'">
                                    <div
                                        class="flex items-center ml-4 text-base font-semibold"
                                        :class="total.totalUsdt >= 0 ? 'text-green-600' : 'text-red-500'">
                                        {{ total.totalUsdt }}
                                        <rb-coin class="w-5 ml-2" :coin="currency('usdt')" />
                                    </div>
                                </template>
                            </div>
                        </template>
                    </template>
                </n-card>
            </section>
            <section class="w-5/12 pl-1">
                <n-card
                    size="small"
                    class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg h-[270px]"
                    content-style="padding: 0;"
                    :bordered="false"
                    :segmented="{
                        content: true,
                        footer: 'soft'
                    }">
                    <template #header>
                        <section class="flex items-center justify-between">
                            <n-skeleton v-if="!dashBoard.localization" class="inline-block" :width="215" :height="20" :sharp="false" />
                            <div v-else class="text-sm text-gray-600 dark:text-white/75 font-semibold text-left">
                                {{ dashBoard.localization.dashboard_exchange_balances_total_balance_wallets_enabled_bots_label }}
                            </div>
    
                            <n-skeleton v-if="!dashBoard.localization" class="inline-block ml-2" :width="66" :height="22" :sharp="false" />
                            <n-button
                                v-else
                                strong
                                size="tiny"
                                class="ml-2 rounded-md"
                                type="tertiary"
                                @click="getTotalBalances"
                                :loading="apiKeysStatsLoading"
                                :disabled="apiKeysStatsLoading">
                                {{ dashBoard.localization.dashboard_account_info_auto_refresh_button }}
                            </n-button>
                        </section>
                    </template>
                    <template #default>
                        <section v-if="totalBalances" class="h-full flex flex-col">
                            <n-scrollbar class="flex-grow">
                                <div
                                    v-for="(balance, key) in totalBalances.balances"
                                    :key="key"
                                    class="w-full dark:odd:bg-[#242424] odd:bg-gray-50">
                                    <div class="py-1 flex flex-row justify-start items-center overflow-x-hidden relative">
                                        <div class="w-4/12 flex items-center">
                                            <rb-coin class="mx-2 w-5 h-5" :coin="currency(key)" />
                                            <div class="text-xs font-semibold uppercase">{{ key }}</div>
                                        </div>
                                        <div class="w-4/12 font-semibold text-left" :class="+balance >= 0 ? 'text-green-600' : 'text-red-500'">{{ balance }}</div>
                                    </div>
                                </div>
                            </n-scrollbar>
    
                            <div class="w-full px-2 items-center py-1 flex justify-center">
                                <div
                                    class="flex items-center ml-4 text-base font-semibold"
                                    :class="totalBalances.balances_total?.btc >= 0 ? 'text-green-600' : 'text-red-500'">
                                    {{ totalBalances.balances_total?.btc || '---' }}
                                    <rb-coin class="w-5 ml-2" :coin="currency(env?.profitSymbol)" />
                                </div>
                                <div 
                                    class="flex items-center text-base text-lightMain ml-2">
                                    <span class="text-xl -mt-1">&asymp;</span>
                                    &nbsp;<span>{{ totalBalances.balances_total?.usdt || '---' }}</span>
                                    <rb-coin class="w-5 ml-2" :coin="currency('usdt')" />
                                </div>
                            </div>
                        </section>
                    </template>
                </n-card>
            </section>
            <section class="w-full mt-4 relative">
                <div v-if="apiKeysRefs.length" class="w-full flex justify-end">
                    <n-button
                        circle
                        secondary
                        id="prev-slide"
                        class="">
                        <n-icon
                            size="15"
                            class="text-gray-600 dark:text-white/75">
                            <ArrowLeft12Regular />
                        </n-icon>
                    </n-button>
                    <n-button
                        circle
                        secondary
                        id="next-slide"
                        class="ml-3">
                        <n-icon
                            size="15"
                            class="text-gray-600 dark:text-white/75">
                            <ArrowRight12Regular />
                        </n-icon>
                    </n-button>
                </div>
    
                <section :class="apiKeysRefs.length ? 'h-[360px]' : ''">
                    <section v-if="!dashBoard.localization || !apiKeysRefs.length && localLoading" class="flex mt-4">
                        <n-skeleton class="rounded-lg h-[350px] mr-1 w-4/12" :sharp="false" />
                        <n-skeleton class="rounded-lg h-[350px] mx-1 w-4/12" :sharp="false" />
                        <n-skeleton class="rounded-lg h-[350px] ml-1 w-4/12" :sharp="false" />
                    </section>
                    <template v-else>
                        <!-- :autoplay="{
                            delay: 4000,
                            disableOnInteraction: false
                        }" -->
                        <!-- :pagination="{
                            clickable: true,
                            dynamicBullets: true,
                            dynamicMainBullets: 4
                        }" -->
                        <swiper
                            :slides-per-view="3"
                            :space-between="8"
                            :navigation="{
                                nextEl: '#next-slide',
                                prevEl: '#prev-slide',
                            }"
                            @swiper="onSwiper"
                            @slideChange="onSlideChange"
                            class="h-full mt-4" >
                            <swiper-slide v-for="key in apiKeysRefs" :key="key.id">
                                <section class="h-[350px] flex flex-col">
                                    <n-card
                                        size="small"
                                        class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg flex-grow"
                                        content-style="padding: 0;"
                                        :bordered="false"
                                        :segmented="{
                                            content: true,
                                            footer: 'soft'
                                        }">
                                        <template #header>
                                            <div class="flex justify-between text-left">
                                                <div>
                                                    <span class="inline-block rb-td-stock-icon h-6" :class="`rb-td-stock-icon-${exchange(key.exchange)?.code_name}`"></span>
                                                    <div class="text-sm text-gray-600 dark:text-white/75 font-semibold">{{ key.name }}</div>
                                                </div>
                                                <div class="flex justify-end">
                                                    <n-popover
                                                        v-if="key.errors"
                                                        class="rounded-md"
                                                        trigger="hover"
                                                        placement="top">
                                                        <template #trigger>
                                                            <n-icon
                                                                size="20"
                                                                class="cursor-pointer mr-2 mt-1 text-red-600">
                                                                <Warning24Filled />
                                                            </n-icon>
                                                        </template>
                                                        <div class="flex items-center">
                                                            <n-icon
                                                                size="16"
                                                                class="mr-2 text-red-600">
                                                                <Warning24Filled />
                                                            </n-icon>
                                                            <span class="text-xs">{{ key.errors }}</span>
                                                        </div>
                                                        
                                                        <!-- <span class="notes text-xs text-white" v-html="helpWarning"></span> -->
                                                    </n-popover>
                                                    <n-popover
                                                        trigger="hover"
                                                        class="rounded-md bg-main py-1 px-2"
                                                        placement="top">
                                                        <template #trigger>
                                                            <n-button
                                                                circle
                                                                secondary
                                                                size="small"
                                                                type="default"
                                                                :disabled="key.loading"
                                                                :loading="key.loading"
                                                                @click="updateKey(key)">
                                                                <n-icon v-if="!key.loading" size="20" class="text-gray-600 dark:text-white/75">
                                                                    <ArrowSyncCircle16Regular />
                                                                </n-icon>
                                                            </n-button>
                                                        </template>
                                                        <span class="text-xs text-white" v-html="dashBoard.localization.dashboard_account_info_auto_refresh_button"></span>
                                                    </n-popover>
                                                </div>
                                            </div>
                                        </template>
                                        <section class="flex flex-col h-full">
                                            <div
                                                class="w-full flex-none">
                                                <div class="py-1 flex flex-row justify-end text-left text-xs text-gray-600 dark:text-white/75">
                                                    <div class="w-4/12">Доступно</div>
                                                    <div class="w-4/12">Всего</div>
                                                </div>
                                            </div>
                                            <!-- <div class="flex-grow">kek</div> -->
                                            <n-scrollbar class="flex-grow">
                                                <div
                                                    v-for="(stat, key) in key.allBalances"
                                                    :key="key"
                                                    class="w-full dark:odd:bg-[#242424] odd:bg-gray-50">
                                                    <div class="py-1 flex flex-row justify-start items-center overflow-x-hidden relative">
                                                        <div class="w-4/12 flex items-center">
                                                            <rb-coin class="mx-2 w-4 h-4" :coin="currency(key)" />
                                                            <div class="text-xs font-semibold uppercase">{{ key }}</div>
                                                        </div>
                                                        <div class="w-4/12 font-semibold text-left text-xs" :class="+stat[key] >= 0 ? 'text-green-600' : 'text-red-500'">{{ stat[key] }}</div>
                                                        <div class="w-4/12 font-semibold text-left text-xs" :class="+stat[`${key}_total`] >= 0 ? 'text-green-600' : 'text-red-500'">{{ stat[`${key}_total`] }}</div>
                                                    </div>
                                                </div>
                                            </n-scrollbar>
                                        </section>
                                    </n-card>
                                    <n-card
                                        size="small"
                                        class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-1 flex-shrink-0"
                                        content-style="padding: 0;"
                                        :bordered="false"
                                        :segmented="{
                                            content: true,
                                            footer: 'soft'
                                        }">
                                        <div class="w-full px-2 flex items-center py-1 dark:even:bg-[#242424] even:bg-gray-50">
                                            <div class="text-xs text-gray-600 dark:text-white/75">
                                                {{ dashBoard.localization.dashboard_exchange_balances_total_label }}:
                                            </div>
                                            <div
                                                class="flex items-center ml-2 text-xs font-semibold"
                                                :class="key.balances_total?.btc_total >= 0 ? 'text-green-600' : 'text-red-500'">
                                                {{ key.balances_total?.btc_total?.toFixed(8) }}
                                                <rb-coin class="w-4 h-4 ml-2" :coin="currency(env?.profitSymbol)" />
                                            </div>
                                            <div 
                                                class="flex items-center ml-3 text-xs text-lightMain"
                                                :class="key.balances_total?.usdt_total ? 'ml-2' : 'ml-4'">
                                                <span class="text-xl -mt-1">&asymp;</span>
                                                &nbsp;<span>{{ key.balances_total?.usdt_total?.toFixed(8) }}</span>
                                                <rb-coin class="w-4 h-4 ml-2" :coin="currency('usdt')" />
                                            </div>
                                        </div>
                                        <div class="w-full px-2 flex items-center py-1 dark:even:bg-[#242424] even:bg-gray-50">
                                            <div class="text-xs text-gray-600 dark:text-white/75">
                                                {{ dashBoard.localization.dashboard_exchange_balances_available_label }}:
                                            </div>
                                            <div
                                                class="flex items-center ml-2 text-xs font-semibold"
                                                :class="key.balances_total?.btc >= 0 ? 'text-green-600' : 'text-red-500'">
                                                {{ key.balances_total?.btc?.toFixed(8) }}
                                                <rb-coin class="w-4 h-4 ml-2" :coin="currency(env?.profitSymbol)" />
                                            </div>
                                            <div 
                                                class="flex items-center ml-3 text-xs text-lightMain"
                                                :class="key.balances_total?.usdt ? 'ml-2' : 'ml-4'">
                                                <span class="text-xl -mt-1">&asymp;</span>
                                                &nbsp;<span>{{ key.balances_total?.usdt?.toFixed(8) }}</span>
                                                <rb-coin class="w-4 h-4 ml-2" :coin="currency('usdt')" />
                                            </div>
                                        </div>
                                        <div class="w-full px-2 flex items-center py-1 dark:even:bg-[#242424] even:bg-gray-50">
                                            <div class="text-xs text-gray-600 dark:text-white/75">
                                                {{ key.futures ? dashBoard.localization.dashboard_exchange_balances_pnl_label + ':' : '&nbsp;' }}
                                            </div>
                                            <div
                                                class="flex items-center ml-2 text-xs font-semibold"
                                                :class="key.pnl?.usdt >= 0 ? 'text-green-600' : 'text-red-500'">
                                                {{ key.futures ? key.pnl?.usdt : '' }}
                                            </div>
                                        </div>
                                    </n-card>
                                </section>
                            </swiper-slide>
                        </swiper>
                    </template>
                </section>
            </section>
        </section>
    </template>

    <rb-api-key-cr
        v-model:show="showCreateApiKey"
        :data="{ show_dashboard: true }"
        @addNewApiKey="addNewApiKey($event)" />
</section>
</template>

<script>
// general function
import general from './general';

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, Autoplay, A11y } from 'swiper';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

// naive-ui
import {
    NIcon,
    NCard,
    NButton,
    NPopover,
    NSkeleton,
    NProgress,
    NScrollbar } from 'naive-ui';

// icons
import {
    Warning24Filled,
    CaretDown12Filled,
    ArrowLeft12Regular,
    ArrowRight12Regular,
    ArrowSyncCircle16Regular }  from '@vicons/fluent';

// components
import RbCoin from '@components/rb-coin';
import ApiKeysIcon from '@components/icons/api-keys.vue';
import RbApiKeyCr from '@components/rb-api-key-cr';

// install Swiper components
SwiperCore.use([Pagination, Navigation, A11y, Autoplay]);

export default {
    name: 'exchange-balances-desktop',
    components: {
        NIcon,
        NCard,
        RbCoin,
        NButton,
        NPopover,
        NSkeleton,
        NScrollbar,
        NProgress,
        Swiper,
        RbApiKeyCr,
        ApiKeysIcon,
        SwiperSlide,
        Warning24Filled,
        CaretDown12Filled,
        ArrowLeft12Regular,
        ArrowRight12Regular,
        ArrowSyncCircle16Regular,
    },
    setup(props, context) {

        return {
            ...general(props, context),
        };
    },
};
</script>

<style lang="scss" scoped>
:deep(.swiper-container-horizontal) {
    & > .swiper-pagination-bullets {
        bottom: 0;
    }
}

:deep(.n-card__content) {
    overflow-y: hidden;
}
</style>