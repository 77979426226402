import { defineStore } from 'pinia';

export const useApiKeys = defineStore('apiKeys', {
    state: () => ({
        refs: null,
        apiKeys: null,
    }),
    getters: {
        localization(state) {
            return state.refs?.localization[Object.keys(state.refs.localization)[0]];
        },
        pager(state) {
            return state.apiKeys.pager;
        },
        columns(state) {
            return state.apiKeys.columns.map(el =>
                el.name === 'key'
                    ? ({
                        ...el,
                        name: 'c_key',
                    })
                    : ({ ...el }));
        },
        records(state) {
            return state.apiKeys.records;
        },
        assignmentCreateTypes(state) {
            return state.refs.assignment_create_types;
        },
    },
});