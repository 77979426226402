<template>
<section>
    <div class="w-full flex flex-wrap lg:items-stretch mt-4">
        <account-info class="w-full" :class="isEnabled('bot_stats') ? 'lg:w-6/12 lg:pr-4' : ''" />
        <block-bots v-if="isEnabled('bot_stats')" class="w-full lg:pl-4 lg:w-6/12" />
    </div>
    <dash-profit v-if="isEnabled('revenue_stats')" />
    <exchange-balances v-if="isEnabled('exchange_balances')" class="w-full lg:mt-8" />
    <ready-bots v-if="isEnabled('marketplace')" />
    <news v-if="isEnabled('news')" class="w-full lg:mt-8" />
</section>
</template>

<script>
// vue
import { computed, watch, onMounted } from 'vue';

// services
import Api from '@services/api';
import LocalStorage from '@services/ts/localStorage';

// coockie
import { useCookies } from 'vue3-cookies';

// router
import { useRouter } from 'vue-router';

// components
import News from '@components/news';
import BlockBots from '@components/block-bots';
import DashProfit from '@components/dash-profit';
import AccountInfo from '@components/account-info';
import ReadyBots from '@components/ready-bots';
import ExchangeBalances from '@components/exchange-balances';

// store
import { useRefs } from '@store/ts/refs';
import { useDashBoard } from '@store/ts/dashBoard';

export default {
    name: 'Home',
    components: {
        News,
        ReadyBots,
        BlockBots,
        DashProfit,
        AccountInfo,
        ExchangeBalances,
    },
    setup() {
        // store
        const refs = useRefs();
        const dashBoard = useDashBoard();

        // coockies
        const { cookies } = useCookies();

        // router
        const router = useRouter();

        watch(() => refs.lang, async () => {
            await getData();
        });

        const dynamicBlocks = computed(() => dashBoard?.dynamicBlocks);

        const components = computed(() => [
            !dynamicBlocks.value
                ? AccountInfo
                : dynamicBlocks.value.includes('account_info') ? AccountInfo : null,
            // !enabled_blocks.value
            //     ? News
            //     : enabled_blocks.value.includes('news') ? News : null,
            !dynamicBlocks.value
                ? DashProfit
                : dynamicBlocks.value.includes('revenue_stats') ? DashProfit : null,
            !dynamicBlocks.value
                ? BlockBots
                : dynamicBlocks.value.includes('bot_stats') ? BlockBots : null,
        ]);

        const getData = async () => {
            dashBoard.loading = true;

            const result = await Promise.allSettled([
                Api.dashBoardRefs(),
                Api.dashBoard({
                    only_blocks: true,
                }),
            ]);

            dashBoard.refs = result[0].value.data;
            dashBoard.data = result[1].value.data;

            LocalStorage.setItem('dynamic_blocks', JSON.stringify(dashBoard.dynamicBlocks));

            checkTasks();

            dashBoard.loading = false;
        };

        const isEnabled = item => dynamicBlocks.value ? dynamicBlocks.value.includes(item) : false;

        const checkTasks = () => {
            if (!cookies.get('tasks_confirm')) {
                if (!refs.tasks.completed && refs.tasks.enabled) {
                    cookies.set('tasks_confirm', true, '1h');

                    router.push({ name: 'tasks' });
                }
            }
        };

        onMounted(async () => {
            await getData();
        });

        return {
            dashBoard,
            components,
            dynamicBlocks,
            isEnabled,
        };
    },
};
</script>
