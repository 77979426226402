import { defineStore } from 'pinia';

import LocalStorage from '@services/ts/localStorage';

// interfaces
import {
    IRefs,
    IData } from './interfaces';

export const useDashBoard = defineStore('dashBoard', {
    state: () => ({
        refs: <IRefs>{
            localization: {},
        },
        data: {
            dynamic_blocks: LocalStorage.getItem('dynamic_blocks'),
        } as IData,
        loading: false,
        botsStatsInfo: null,
        revenueStats: {} as { [key: string] : any },
        news: null,
    }),
    getters: {
        localization(state) {
            return state.refs.localization[Object.keys(state.refs.localization)[0]];
        },
        getNews(state) {
            return state.news;
        },
        enabled_blocks(state) {
            return state.data.enabled_blocks;
        },
        dynamicBlocks(state) {
            return state.data.dynamic_blocks;
        },
        revenue_stats(state){
            return state.revenueStats;
        },
        exchangeBalances(state){
            return state.data.exchange_balances;
        },
        gBotsStatsInfo(state) {
            return state.botsStatsInfo;
        },
        revenueStatsIsNotEmpty(state){
            return Object.keys(state.revenueStats)
                .some((el: string): boolean => state.revenueStats[el].btc > 0);
        },
    },
});