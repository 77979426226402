// vue
import { ref, reactive, computed } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useEnv } from '@store/ts/env';
import { useRefs } from '@store/ts/refs';
import { useProfile } from '@store/profile';

// i18n
import { useI18n } from 'vue-i18n';

// services
import ProfileService from '@services/profileService';

// naive-ui
import { useNotification } from 'naive-ui';

export default function () {
    // store
    const gl = useGl();
    const env = useEnv();
    const refs = useRefs();
    const profile = useProfile();

    // ui
    const notification = useNotification();

    // i18n
    const { t } = useI18n();

    // vars
    const show = ref(false);
    const loading = ref(false);
    const showPassModal = ref(false);
    const dataForm = reactive({
        two_factor_auth: profile.fullInfo.two_factor_auth,
        two_factor_auth_phone: profile.fullInfo.two_factor_auth_phone,
        new_password: profile.fullInfo.new_password,
        re_new_password: profile.fullInfo.re_new_password,
    });

    const timezonesOptions = computed(() => Object.entries(refs.timezones).map(([ key, value ]) => {
        return value.length > 1
            ? ({
                type: 'group',
                label: key,
                children: [ ...value.map(el => ({
                    label: el.name,
                    value: el.id,
                    ...el,
                })) ],
            })
            : ({
                label: value[0].name,
                value: value[0].id,
                ...value[0],
            });
    }));

    const languagesOptions = computed(() => profile.languages.map(el => ({
        label: el.title,
        value: +el.id,
        ...el,
    })));

    const messengersOptions = computed(() => profile.messengers.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    const statViewsOptions = computed(() => profile.statViews.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    const messengerName = id => {
        if (!id || id == -1){
            return '? ';
        }
  
        let hint = profile.messengers.find(el => el.id === id);
  
        return hint ? `${hint.title}: ` : '';
    };

    const onRemoveMessenger = i => {
        profile.fullInfo.messengers.splice(i, 1);
    };

    const onAddMessenger = () => {
        profile.fullInfo.messengers.push({id: -1, value: ''});
    };

    const copy = s => {
        navigator.clipboard.writeText(s);
        notification.success({
            content: t('copied'),
            duration: 2500,
            keepAliveOnHover: true,
        });
    };

    const onUpdate = async () => {
        loading.value = true;

        if (!profile.fullInfo.current_password || profile.errors.current_password ) {
            showPassModal.value = true;
            loading.value = false;
            return;
        }
  
        let payload = { ...profile.fullInfo, ...dataForm, _formPath: 'profile.update' };
        payload.messengers = payload.messengers.map((messenger, index) => ({...messenger, _formPath: `messenger#${index}`}));

        const data = await ProfileService.update({ profile: payload, ...gl.stageExtra?.extra});
        if (!data) {
            showPassModal.value = false;
        } else {
            if (!data.data.status) {
                profile.errors = data.data.errors_form['profile.update']?.fields;

                if (!Object.keys(profile.errors).includes('current_password')) {
                    showPassModal.value = false;

                    Object.keys(profile.errors).forEach(el => {
                        setTimeout(() => {
                            document
                                .querySelector(`#profile__${el}`)
                                .scrollIntoView({ block: 'start', behavior: 'smooth' });
                        }, 500);
                    });

                    // show messages
                    if (data.postMessages)
                        data.postMessages.forEach(el => {
                            if (el.success)
                                notification.success({
                                    content: el.msg,
                                    duration: 2500,
                                    keepAliveOnHover: true,
                                });
                        });
                }
            } else {
                profile.errors = {};
                showPassModal.value = false;

                for (const [ key, value ] of Object.entries(data.data)) {
                    profile.fullInfo[key] = value;
                }

                // show messages
                if (data.postMessages)
                    data.postMessages.forEach(el => {
                        gl.showNotification({
                            type: el.success ? 'success' : 'error',
                            msg: el.msg,
                        });
                    });
            }
        }
      
        loading.value = false;
    };

    return {
        gl,
        env,
        refs,
        show,
        profile,
        loading,
        dataForm,
        showPassModal,
        statViewsOptions,
        languagesOptions,
        timezonesOptions,
        messengersOptions,
        t,
        copy,
        onUpdate,
        messengerName,
        onAddMessenger,
        onRemoveMessenger,
    };
}