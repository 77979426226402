
// vue
import { defineComponent } from 'vue';

// general
import general from './general';

// naive-ui
import {
    NCard,
    NIcon,
    NButton,
    NDrawer,
    NSkeleton,
    NDropdown,
    NRadioButton,
    NRadioGroup,
    NDrawerContent} from 'naive-ui';

// components
import Bots from '@components/filters/bots/desktop.vue';
import Exchanges from '@components/filters/exchanges/desktop.vue';
import RadioGroup from '@components/filters/radio-group/desktop.vue';
import DatePicker from '@components/filters/date-picker/desktop.vue';
import RbSwitch from '@components/rb-switch/index.vue';
import CheckboxGroup from '@components/filters/checkbox-group/desktop.vue';
import FilterCoins from '@components/filters/filter-coins/desktop.vue';

// icons
import {
    Cart20Regular,
    Filter20Filled,
    ColumnEdit20Filled,
    DeleteDismiss24Regular } from '@vicons/fluent';

export default defineComponent({
    name: 'filters-desktop',
    props: {
        refFilters: {
            type: [ Object, Array ],
        },
        filters: {
            type: Object,
            required: true,
        },
        filtersChanged: {
            type: Boolean,
            default: false,
        },
        filterId: {
            type: String,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Bots,
        NIcon,
        NCard,
        NDrawer,
        NButton,
        NDropdown,
        NSkeleton,
        RbSwitch,
        Exchanges,
        DatePicker,
        RadioGroup,
        FilterCoins,
        NRadioButton,
        NRadioGroup,
        CheckboxGroup,
        Cart20Regular,
        NDrawerContent,
        Filter20Filled,
        ColumnEdit20Filled,
        DeleteDismiss24Regular,
    },
    setup(props, context) {
        
        return {
            ...general(props, context),
        };
    },
});
