// vue
import { ref, reactive, computed, watch, onMounted } from 'vue';

// services
import ApiKeysService from'@services/apiKeysService';
import WalletsService from '@services/walletsService';
import ExchangesService from '@services/exchangesService';

// store
import { useGl } from '@store/ts/gl';
import { useBots } from '@/store/bots';
import { useRefs } from '@store/ts/refs';
import { useTools } from '@store/tools';
import { useWallets } from '@store/wallets';

// shared
import { WALLET_DATA_FORM_FACTORY } from '@shared/factories';
import { formatUnexistedCurrsAsUnknown } from '@shared/util';

// i18n
import { useI18n } from 'vue-i18n';

// router
import { useRoute, useRouter } from 'vue-router';

// naive-ui
import { useNotification } from 'naive-ui';

export default function (props, context) {
    // store
    const gl = useGl();
    const refs = useRefs();
    const wallets = useWallets();

    // ui
    const notification = useNotification();

    // router
    const route = useRoute();
    const router = useRouter();

    // i18n
    const { t } = useI18n();

    // vars
    const { _ } = window;
    const init = ref(false);
    const loading = ref(false);
    const dataForm = reactive({});
    const exchangeInfo = ref();
    const apiKeysRefs = ref();
    const walletEditData = ref({});
    const balancesRefs = ref({});
    const walletApiKeyDialog = ref(false);
    const showAllNonZeroBalances = ref(true);
    const loadings = reactive({
        'onCheckBalance': false,
    });

    // computeds
    const walletId = computed(() => props.walletId);
    const isCreate = computed(() => !walletId.value);
    const isUpdate = computed(() => !!walletId.value);
    const apiKeySelected = computed(() => dataForm.api_key.value !== -1);
    const currencySelected = computed(() => dataForm.currency.value !== -1);
    const symbolBalance = computed(() => balancesRefs.value?.[dataForm.currency.value] || '');
    const symbolTotalBalance = computed(() => balancesRefs.value?.[`${dataForm.currency.value}_total`] || '');
    const mainButtonLabel = computed(() => isUpdate.value
        ? wallets.localization?.wallets_update_title_button
        : wallets.localization?.wallets_create_title_button);
    const applyLabel = computed(() => isUpdate.value
        ? wallets.localization?.wallets_update_title_button
        : wallets.localization?.wallets_create_title_button);
    const isNameSelected = computed(() => dataForm.name.value != '');
    const isExchangeSelected = computed(() => dataForm.exchange.value !== -1 && exchangeInfo.value);
    const canCheckBalance = computed(() => isExchangeSelected.value && apiKeySelected.value);
    const isAmountSelected = computed(() => dataForm.amount.value != '');
    const canDoAction = computed(() => isNameSelected.value && isExchangeSelected.value && currencySelected.value);
    // const canDoAction = computed(() => true);

    const exchangeOptions = computed(() => refs.exchanges.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    const currencyOptions = computed(() => Array.isArray(exchangeInfo.value?.currencies)
        ? formatUnexistedCurrsAsUnknown(refs, exchangeInfo.value.currencies).map(el => ({
            value: el.id,
            label: el.title,
            ...el,
        }))
        : []);

    const apiKeysOptions = computed(() => Array.isArray(apiKeysRefs.value?.records)
        ? apiKeysRefs.value.records.map(el => ({
            value: el.id,
            label: el.name,
            ...el,
        })) : []);

    const allSymbolsBalances = computed(() => {
        const curSymbol = dataForm.currency.value;
        let balancesMap = [], balancesCheckMap = {};
      
        _.forOwn(balancesRefs.value, (value, key) => {
            const [symbol, hasTotal] = key.split('_');
            const symEntry = _.get(balancesCheckMap, symbol, {available: 0.00, total: 0.00});
          
            if (hasTotal) {
                symEntry.total = value;
            } else {
                symEntry.available = value;
            }
            balancesCheckMap[symbol] = symEntry;
        });
      
        if (curSymbol && curSymbol != -1) {
            let curSymbolInfo = _.get(balancesCheckMap, curSymbol, {available: 0.00, total: 0.00});
            delete balancesCheckMap[curSymbol];
            balancesMap.push({symbol: curSymbol, ...curSymbolInfo});
        }
      
        _.forEach(Object.keys(balancesCheckMap).sort(), (symbol) => {
            const {available, total} = balancesCheckMap[symbol];
          
            if (parseFloat(total, 10) === 0) return;
          
            balancesMap.push({symbol, available, total});
        });
      
        return (balancesRefs.value && Object.keys(balancesRefs.value).length) > 0 ? balancesMap : null;
    });
      

    // watchers
    watch(() => props.show, v => {
        if (v) {
            mainInit();
        } else {
            walletEditData.value = {};
            balancesRefs.value = {};
        }
    });

    watch(() => dataForm.exchange?.value, async v => {
        loading.value = true;
        setLoadings(['exchange', 'currency', 'amount', 'api_key', 'comment'], true);
    
        if (v != -1) {
            try {
                if (isCreate.value && !props.coinSymbolForWallet) {
                    dataForm.currency.value = -1;
                }

                await Promise.allSettled([
                    getExchangeFullInfo(v),
                    getApiKeysList(v),
                ]);
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };
        }

        setLoadings(['exchange', 'currency', 'amount', 'api_key', 'comment'], false);
        loading.value = false;
    });

    // fns
    const mainInit = async () => {
        init.value = false;

        await Promise.allSettled([
            walletsRefs(),
            getFullWalletInfo(),
        ]);

        initModule();

        init.value = true;
    };

    const walletsRefs = async () => {
        if (wallets.refs) return;

        try {
            wallets.refs = ( await WalletsService.getRefs() ).data;
        } catch {
            notification.error({
                content: t('errorMessage'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        };
    };

    const getExchangeFullInfo = async id => {
        try {
            exchangeInfo.value = ( await ExchangesService.getFullInfo(id) ).data;
        } catch {
            notification.error({
                content: t('errorMessage'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        };
    };

    const getApiKeysList = async (id = dataForm.exchange.value) => {
        try {
            apiKeysRefs.value = ( await ApiKeysService.getApiKeysList({exchanges: [id]}) ).data;
        } catch {
            notification.error({
                content: t('errorMessage'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        };
    };

    const getFullWalletInfo = async () => {
        if (isUpdate.value) {
            try {
                walletEditData.value = ( await WalletsService.getFullInfo(walletId.value) ).data;
            } catch {
                gl.showNotification({
                    type: 'error',
                    msg: t('errorMessage'),
                });
            };
        }
    };

    const initModule = () => {
        if (props.exchangeId) {
            walletEditData.value.exchange = props.exchangeId;
        }
        if (props.coinSymbolForWallet) {
            walletEditData.value.currency = props.coinSymbolForWallet;
        }

        const res = WALLET_DATA_FORM_FACTORY(walletEditData.value);

        for (const [key, value] of Object.entries(res)) {
            if (key === 'name') {
                dataForm[key] = {
                    value,
                    title: wallets.localization['wallets_name_f'],
                    placeholder: wallets.localization['wallets_name_i'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: true,
                    customField: true,
                };
            } else if (key === 'comment') {
                dataForm[key] = {
                    value,
                    title: wallets.localization['wallets_comment_f'],
                    placeholder: wallets.localization['wallets_comment_i'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: true,
                    customField: true,
                };
            } else if (key === 'exchange') {
                dataForm[key] = {
                    value,
                    title: wallets.localization['wallets_exchange_f'],
                    placeholder: wallets.localization['wallets_exchange_i'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: true,
                    customField: true,
                };
            } else if (key === 'currency') {
                dataForm[key] = {
                    value,
                    title: wallets.localization['wallets_currency_f'],
                    placeholder: wallets.localization['wallets_currency_i'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: computed(() => isExchangeSelected.value),
                    customField: true,
                };
            } else if (key === 'amount') {
                dataForm[key] = {
                    value,
                    title: computed(() => dataForm?.type == 1
                        ? (wallets.localization?.wallets_amount_sum || 'Cумма') + ':'
                        : (wallets.localization?.wallets_amount_percent || 'Процент') + ':'),
                    placeholder: computed(() => dataForm?.type == 1
                        ? wallets.localization?.wallets_set_amount_sum || 'Введите сумму'
                        : wallets.localization?.wallets_set_amount_percent || 'Введите процент'),
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: computed(() => isExchangeSelected.value),
                    customField: true,
                };
            } else if (key === 'api_key') {
                dataForm[key] = {
                    value,
                    title: wallets.localization['wallets_api_f'],
                    placeholder: wallets.localization['wallets_api_i'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: computed(() => isExchangeSelected.value),
                    customField: true,
                };
            } else dataForm[key] = value;
        };
    };

    const onCheckBalance = async () => {
        loadings.onCheckBalance = true;

        const prepare = await WalletsService.getBalances(dataForm.api_key.value);

        if (!prepare.data.status) {
            prepare.data.errors.forEach(({ msg }) => {
                notification.error({
                    content: msg,
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            });

            // reset balancesRefs
            balancesRefs.value = {};
        } else {
            balancesRefs.value = prepare.data.balances;
        }

        loadings.onCheckBalance = false;
    };

    const onActionWallet = async () => {
        loading.value = true;
        setLoadings([], true);

        const records = {
            name: dataForm.name.value,
            comment: dataForm.comment.value,
            currency: dataForm.currency.value,
            exchange: dataForm.exchange.value,
            type: dataForm.type,
            amount: dataForm.amount.value,
            api_key: dataForm.api_key.value,
            _formPath: 'wallets.addNew',
        };

        let prepare;

        try {
            prepare = isCreate.value
                ? await WalletsService.addNew(records)
                : await WalletsService.udpate({
                    id: dataForm.id,
                    ...records,
                });
        } catch {
            notification.error({
                content: t('errorMessage'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        }
    
        if (!prepare.data.status) {
            if (prepare.data?.errors_form) {

                for (let key in dataForm) {
                    const fields = prepare.data.errors_form['wallets.addNew'].fields;
                    const el = Object.keys(fields).find(el => el === key);

                    if (el) {
                        dataForm[key].status = 'error';
                        dataForm[key].msg = fields[el].msg;
                    } else {
                        if (typeof dataForm[key] === 'object' && dataForm[key] !== null) {
                            dataForm[key].status = 'success';
                            dataForm[key].msg = undefined;
                        }
                    }
                }
            }
        } else {
            if (isUpdate.value) {
                context.emit('updateWallet', prepare.data.record);
            } else {
                context.emit('addNewWallet', prepare.data.records[0]);
            }
            // clean apiKeyModel
            for (let key in dataForm) {
                if (typeof dataForm[key] === 'object' && dataForm[key] !== null)
                    dataForm[key].value = '';
            };

            // close modal
            context.emit('update:show', false);

            // show messages
            prepare.postMessages.forEach(el => {
                notification.success({
                    content: el.msg,
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            });
        }

        setLoadings([], false);
        loading.value = false;
    };

    const setLoadings = (fields = [], value = true) => {
        const localFields = !fields.length ? Object.keys(dataForm) : fields;
        
        localFields.forEach(key => {
            if (dataForm[key]?.loading != undefined) {
                dataForm[key].loading = value;
            }
        });
    };

    const exchange = exchange => refs.exchanges.find(el => el.id === exchange);
    const currency = icon => refs.currencies.find(el => el.id === icon);

    const addNewApiKey = async apiKey => {
        setLoadings(['api_key'], true);

        await getApiKeysList();
        dataForm.api_key.value = apiKey.id;

        context.emit('addNewApiKey', apiKey);

        setLoadings(['api_key'], false);
    };

    onMounted(() => {
        if (props.show) {
            mainInit();
        }
    });

    return {
        gl,
        init,
        refs,
        loading,
        wallets,
        loadings,
        dataForm,
        isUpdate,
        isCreate,
        applyLabel,
        canDoAction,
        balancesRefs,
        symbolBalance,
        apiKeysOptions,
        currencyOptions,
        mainButtonLabel,
        canCheckBalance,
        exchangeOptions,
        currencySelected,
        symbolTotalBalance,
        walletApiKeyDialog,
        isExchangeSelected,
        allSymbolsBalances,
        showAllNonZeroBalances,
        t,
        exchange,
        currency,
        onCheckBalance,
        onActionWallet,
        addNewApiKey,
    };
}