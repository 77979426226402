<template>
<n-modal
    size="small"
    :show="show"
    :mask-closable="maskClosable"
    @update:show="$emit('update:show', $event)"
    transform-origin="center">
    <section>
        <n-card
            size="small"
            role="dialog"
            aria-modal="true"
            class="bg-white dark:bg-darkCardBg rounded-md"
            :segmented="{
                content: true,
                footer: 'soft'
            }"
            :style="`width: ${width}; max-height: ${showCLoseButton ? 70 : 80}vh;`"
            content-style="height: 100%; overflow: auto;">
            <template #header>
                <div v-if="label" class="text-sm flex items-center justify-center text-gray-600 dark:text-white/75 font-semibold">
                    <n-icon
                        v-if="type === 'error'"
                        class="cursor-pointer text-lg mr-2 text-red-500">
                        <Warning24Filled />
                    </n-icon>
                    <n-icon
                        v-if="type === 'success'"
                        class="cursor-pointer text-lg mr-2 text-green-600">
                        <Checkmark12Filled />
                    </n-icon>
                    {{ label }}
                </div>
                <slot name="headerContent"></slot>
            </template>
            
            <template #default>
                    <slot name="default"></slot>
            </template>
            <template #footer>
                <slot name="footer"></slot>
            </template>
        </n-card>
        <n-button
            v-if="showCLoseButton"
            circle
            secondary
            type="default"
            class="absolute -bottom-12 left-1/2 -translate-x-1/2"
            @click="$emit('update:show', false)">
            <template #icon>
                <n-icon class="text-gray-900 dark:text-white" size="16"><Dismiss16Filled /></n-icon>
            </template>
        </n-button>
    </section>
</n-modal>
</template>

<script>
// store
import { useGl } from '@store/ts/gl';

// naive-ui
import {
    NIcon,
    NCard,
    NModal,
    NButton } from 'naive-ui';

// icons
import {
    Warning24Filled,
    Dismiss16Filled,
    Checkmark12Filled } from '@vicons/fluent';

export default {
    name: 'base-modal-mobile',
    props: {
        label: {
            type: String,
            required: true,
        },
        show: {
            type: Boolean,
            default: false,
        },
        width: {
            type: [ Number, String ],
            default: '90vw',
        },
        maskClosable: {
            type: Boolean,
            default: true,
        },
        showCLoseButton: {
            type: Boolean,
            default: true,
        },
        type: {
            type: String,
            default: 'default',
        },
    },
    components: {
        NIcon,
        NCard,
        NModal,
        NButton,
        Warning24Filled,
        Dismiss16Filled,
        Checkmark12Filled,
    },
    setup() {
    // store
        const gl = useGl();

        return {
            gl,
        };
    },
};
</script>