// vue
import { ref, computed, watch, onMounted } from 'vue';

// services
import Marketplace from '@services/marketplace';

// i18n
import { useI18n } from 'vue-i18n';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useMarketplace } from '@store/ts/marketplace';

// router
import { useRoute, useRouter } from 'vue-router';

// UI
import { useMessage } from 'naive-ui';

export default function () {
    const route = useRoute();
    const router = useRouter();

    // store
    const gl = useGl();
    const refs = useRefs();
    const marketplace = useMarketplace();

    // ui
    

    // i18n
    const { t } = useI18n();

    const init = ref(false);

    const currentTab = computed(() => {
        if (['marketplace.buy'].includes(route.name)) {
            return 'buy'; 
        } else if (route.name.includes('marketplace.sell')) {
            return 'sell'; 
        } else if (route.name.includes('marketplace.mentorship')) {
            return 'mentorship';
        } else if (route.name.includes('marketplace.signals')) {
            return 'signals';
        } else if (route.name.includes('marketplace.buy.free')) {
            return 'buy/free'; 
        }

        return 'buy';
    });

    const loading = computed(() => gl.loading);

    watch(() => refs.lang, async () => {
        await getData();
    });

    const tabs = computed(() => !init.value
        ? []
        : [
            {
                name: 'buy',
                title: marketplace.localization?.['marketplace/tabs/buy/title'],
            }, {
                name: 'buy/free',
                title: marketplace.localization?.['marketplace/tabs/free/title'] || 'Бесплатные боты',
            }, {
                name: 'sell',
                title: marketplace.localization?.['marketplace/tabs/sell/title'],
            }, {
                name: 'mentorship',
                title: marketplace.localization?.['marketplace/tabs/mentorship/title'],
            }, {
                name: 'signals',
                title: marketplace.localization?.['marketplace/tabs/signals/title'],
            },
        ]);
        
    const changeTab = (tab) => {
        router.push({ path: `/marketplace/${tab}` });
        currentTab.value = tab;
    };

    const getData = async () => {
        if (marketplace.refsInit) return;
        
        gl.showLoading = true;

        try {
            marketplace.refs = ( await Marketplace.getRefs() ).data;
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        marketplace.refsInit = true;
        gl.showLoading = false;
    };

    onMounted(async () => {
        await getData();

        init.value = true;
    });

    return {
        tabs,
        init,
        refs,
        loading,
        currentTab,
        changeTab,
    };
}