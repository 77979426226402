<template>
<div v-if="init" class="flex">
    <div class="w-3/12 h-full sticky top-0">
        <div
            v-for="item in tabs"
            :key="item.id"
            class="flex items-center h-[34px] text-md text-left px-3 mb-2 hover:bg-white dark:hover:bg-[#2d2d2d] rounded-md cursor-pointer dark:hover:text-white"
            :class="item.id === tab ? 'bg-white dark:bg-[#2d2d2d] dark:text-white text-main' : 'text-gray-600 dark:text-white/50'"
            @click="changeTab(item.id)">
            <n-icon size="18" class="mr-2"><component :is="item.icon"></component></n-icon>
            {{ item.title }}
        </div>
    </div>
    <div class="w-9/12 lg:pl-4">
        <n-tabs
            animated
            type="line"
            :value="tab">
            <n-tab-pane
                v-for="item in tabs"
                :key="item.id"
                :name="item.id"
                class="pt-0"
                display-directive="show">
                <component
                    :tab="tab"
                    :myTab="item.id"
                    :is="item.component"
                    @change-tab="changeTab($event)"></component>
            </n-tab-pane>
        </n-tabs>
        <div v-if="showSaveButton" class="flex justify-end mt-6">
            <n-button
                strong
                size="medium"
                class="rounded-md text-white/90"
                :color="gl.mainColor"
                @click="onUpdate">
                {{ profile.localization['profile_save_changes_button'] }}
            </n-button>
        </div>
    </div>
</div>

<base-modal
    width="550"
    v-model:show="showPassModal">
    <template #headerContent>
        <div class="text-xl">{{ profile.localization['profile_password_current_password_i'] }}</div>
    </template>
    <template #default>
        <rb-input
            type="password"
            :status="profile.errors?.current_password ? 'error' : undefined"
            :msg="profile.errors?.current_password?.msg"
            :placeholder="profile.localization['profile_password_current_password_i']"
            v-model:value="profile.fullInfo.current_password"
            @update:value="profile.cleanError('current_password')" />
    </template>
    <template #footer>
        <div class="flex justify-end">
            <n-button
                strong
                size="large"
                :color="gl.mainColor"
                @click="onUpdate">
                {{ profile.localization['profile_save_changes_button'] }}
            </n-button>
        </div>
    </template>
</base-modal>
</template>

<script>
// vue
import { ref, reactive, computed, watch, onMounted, onUnmounted } from 'vue';

// services
import ProfileService from '@services/profileService';
import LocalStorage from '@services/ts/localStorage';

// store
import { useGl } from '@store/ts/gl';
import { useEnv } from '@store/ts/env';
import { useProfile } from '@store/profile';
import { useDashBoard } from '@store/ts/dashBoard';

// i18n
import { useI18n } from 'vue-i18n';

// router
import { useRoute, useRouter } from 'vue-router';

// icons
import {
    Alert24Regular,
    Person16Regular,
    Password24Regular,
    PersonInfo16Filled,
    MoneyHand24Regular,
    MoneyCalculator24Regular,
    ContentSettings24Regular,
    TabInprivateAccount24Regular,
    PeopleCommunityAdd20Regular } from '@vicons/fluent';

// components
import RbInput from '@components/rb-input';
import OAuth from '@components/profile/o-auth/desktop.vue';
import BaseModal from '@components/base/base-modal';
import MainInfo from '@components/profile/main-info/desktop.vue';
import Password from '@components/profile/password/desktop.vue';
import AccountInfo from '@components/profile/account-info/desktop.vue';
import Notifications from '@components/profile/notifications/desktop.vue';
import BalanceHistory from '@components/profile/balance-history/desktop.vue';
import ReferralProgram from '@components/profile/referral-program/desktop.vue';
import PaymentsRequest from '@components/profile/payments-request/desktop.vue';
import MarketplaceSettings from '@components/profile/marketplace-settings/desktop.vue';

// naive-ui
import {
    NTabs,
    NCard,
    NIcon,
    NButton,
    NTabPane,
    NSkeleton,
    useNotification } from 'naive-ui';

export default {
    name: 'Profile',
    components: {
        OAuth,
        NTabs,
        NCard,
        NIcon,
        RbInput,
        NButton,
        MainInfo,
        Password,
        BaseModal,
        NTabPane,
        NSkeleton,
        AccountInfo,
        Notifications,
        Alert24Regular,
        BalanceHistory,
        ReferralProgram,
        PaymentsRequest,
        Person16Regular,
        Password24Regular,
        MoneyHand24Regular,
        MarketplaceSettings,
        MoneyCalculator24Regular,
        ContentSettings24Regular,
        PeopleCommunityAdd20Regular,
        TabInprivateAccount24Regular,
    },
    setup() {
        // store
        const gl = useGl();
        const env = useEnv();
        const profile = useProfile();
        const dashBoard = useDashBoard();

        // router
        const route = useRoute();
        const router = useRouter();

        // ui
        const notification = useNotification();

        // i18n
        const { t } = useI18n();

        // vars
        const tab = computed(() => route.query?.tab);

        const init = ref(false);
        const showPassModal = ref(false);
        const currentLang = ref();

        watch(() => gl.stageState, v => {
            // if (v === 'inactive') {
            //     if (stageFn.value)
            //         stageFn.value();
            // } else if (v === 'canceled') {
            //     stageFn.value = null;
            // }
        });

        const tabs = computed(() => init.value
            ? [
                {
                    id: 'account_info',
                    title: profile.localization['profile_account_info_title'],
                    component: AccountInfo,
                    icon: Person16Regular,
                    fields: [],
                }, {
                    id: 'main_info',
                    title: profile.localization['profile_main_info_title'],
                    component: MainInfo,
                    icon: PersonInfo16Filled,
                    fields: ['full_name'],
                }, {
                    id: 'marketplace_settings',
                    title: profile.localization['profile/marketplace/header'],
                    component: MarketplaceSettings,
                    icon: ContentSettings24Regular,
                    fields: ['mentorship_price', 'mentor_contact', 'mentor_notes'],
                }, {
                    id: 'account_balance_history',
                    title: profile.localization['profile_account_balance_history_title'],
                    component: BalanceHistory,
                    icon: MoneyCalculator24Regular,
                    fields: [],
                }, {
                    id: 'referral_info',
                    title: profile.localization['profile_referral_info_title'],
                    component: ReferralProgram,
                    icon: PeopleCommunityAdd20Regular,
                    fields: [],
                }, {
                    id: 'referral_info_withdrawal',
                    title: profile.localization['profile_referral_info_withdrawal_request_title'],
                    component: PaymentsRequest,
                    icon: MoneyHand24Regular,
                    fields: [],
                }, {
                    id: 'oauth',
                    title: profile.localization['profile/oauth/header'],
                    component: OAuth,
                    icon: TabInprivateAccount24Regular,
                    fields: [],
                }, {
                    id: 'profile_notifications',
                    title: profile.localization['profile_notification_title'],
                    component: Notifications,
                    icon: Alert24Regular,
                    fields: [],
                }, {
                    id: 'profile_password',
                    title: profile.localization['profile_password_title'],
                    component: Password,
                    icon: Password24Regular,
                    fields: ['new_password'],
                },
            ]: []);

        const showSaveButton = computed(() => ![
            'oauth',
            'account_info',
            'referral_info',
            'referral_info_withdrawal',
            'account_balance_history'].includes(tab.value));

        const getRefs = async (showLoading = true) => {
            if (showLoading)
                gl.showLoading = true;

            try {
                profile.refs = ( await ProfileService.getRefs() ).data;
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };
            
            if (showLoading)
                gl.showLoading = false;
        };

        const getFullInfo = async (showLoading = true) => {
            if (showLoading)
                gl.showLoading = true;

            try {
                profile.fullInfo = ( await ProfileService.getFullInfo() ).data;
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };
            
            if (showLoading)
                gl.showLoading = false;
        };

        const onUpdate = async () => {
            gl.showLoading = true;

            if (!profile.fullInfo.current_password || profile.errors.current_password ) {
                showPassModal.value = true;
                gl.showLoading = false;
                return;
            }
        
            let mentorship_price = String(profile.fullInfo.mentorship_price);
            const dotIdx = mentorship_price.indexOf('.');
            if (dotIdx === -1) {
                mentorship_price = `0.${mentorship_price}`;
                profile.fullInfo.mentorship_price = mentorship_price;
            } else {
                profile.fullInfo.mentorship_price = mentorship_price.startsWith('0') ? mentorship_price : `0${mentorship_price}`;
            }
        
            let payload = { ...profile.fullInfo, _formPath: 'profile.update' };
            payload.messengers = payload.messengers.map((messenger, index) => ({...messenger, _formPath: `messenger#${index}`}));
        
            const data = await ProfileService.update({ profile: payload });
            if (!data) {
                showPassModal.value = false;
            } else {
                if (!data.data.status) {
                    profile.errors = data.data.errors_form['profile.update']?.fields;

                    if (!Object.keys(profile.errors).includes('current_password')) {
                        showPassModal.value = false;

                        Object.keys(profile.errors).forEach(el => {
                            const currentTab = tabs.value.find(tab => tab.fields.includes(el));

                            if (currentTab) {
                                changeTab(currentTab.id);

                                setTimeout(() => {
                                    document
                                        .querySelector(`#profile__${el}`)
                                        .scrollIntoView({ block: 'start', behavior: 'smooth' });
                                }, 500);
                            }
                        });
                    }

                    // show messages
                    if (data.postMessages)
                        data.postMessages.forEach(el => {
                            notification[el.success ? 'success' : 'error']({
                                content: el.msg,
                                duration: 2500,
                                keepAliveOnHover: true,
                            });
                        });
                } else {
                    profile.errors = {};
                        
                    showPassModal.value = false;

                    setDashboardBlocks(data.data.dashboard_blocks);

                    for (const [ key, value ] of Object.entries(data.data)) {
                        profile.fullInfo[key] = value;
                    }

                    // show messages
                    if (data.postMessages)
                        data.postMessages.forEach(el => {
                            notification[el.success ? 'success' : 'error']({
                                content: el.msg,
                                duration: 2500,
                                keepAliveOnHover: true,
                            });
                        });

                    if (currentLang.value !== data.data.language) {
                        location.reload();
                    }
                }
            }
            
            gl.showLoading = false;
        };

        const setDashboardBlocks = blocks => {
            const dynamicBlocks = blocks
                .filter(({ enabled }) => enabled)
                .map(({ id }) => id);

            dashBoard.data.dynamic_blocks = dynamicBlocks;

            LocalStorage.setItem(
                'dynamic_blocks',
                JSON.stringify(dynamicBlocks),
            );
        };

        const getData = async () => {
            gl.showLoading = true;

            await Promise.allSettled([
                getRefs(false),
                getFullInfo(false),
            ]);

            gl.showLoading = false;
        };

        const changeTab = $event => {
            router.push({ name: route.name, query: { tab: $event }});
        };

        onMounted(async () => {
            if (!route.query?.tab) {
                router.push({ name: route.name, query: { tab: 'account_info' }});
            }
            
            await getData();
            
            init.value = true;
            gl.secondMenu = true;

            currentLang.value = profile.fullInfo.language;
        });

        onUnmounted(() => {
            gl.secondMenu = false;
        });

        return {
            gl,
            tab,
            tabs,
            init,
            profile,
            showPassModal,
            showSaveButton,
            onUpdate,
            changeTab,
        };
    },
};
</script>

<style lang="scss" scoped>
:deep(.n-tabs) .n-tabs-nav {
    display: none;
}
</style>