// vue
import { ref, reactive, watch, onMounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useEnv } from '@store/ts/env';
import { useRefs } from '@store/ts/refs';
import { useProfile } from '@store/profile';

// services
import ProfileService from '@services/profileService';
import Referral from '@services/referral';

// i18n
import { useI18n } from 'vue-i18n';

// router
import { useRoute } from 'vue-router';

// naive-ui
import { useNotification } from 'naive-ui';

export default function (props, context) {
    // store
    const gl = useGl();
    const env = useEnv();
    const refs = useRefs();
    const profile = useProfile();

    // router
    const route = useRoute();

    // ui
    const notification = useNotification();

    // i18n
    const { t } = useI18n();

    // vars
    const loading = ref(false);
    const localLoading = ref(false);
    const fullInfo = ref();
    const errors = reactive({
        cleanError(key) {
            if (key) {
                delete this[key];
            } else {
                Object.keys(dataForm).forEach(key => delete this[key]);
            }
        },
    });
    const errors2 = reactive({
        cleanError(key) {
            if (key) {
                delete this[key];
            } else {
                Object.keys(dataForm2).forEach(key => delete this[key]);
            }
        },
    });

    watch(() => props.tab, v => {
        if (props.myTab === v) {
            if (!fullInfo.value)
                getFullInfo();
        }
    });

    const dataForm = reactive({
        id_acc: '',
        amount: '',
        comment: '',
    });
        
    const dataForm2 = reactive({
        withdraw_address: '',
        withdraw_amount: '',
    });

    const onInternalTransfer = async () => {
        gl.isMobile
            ? localLoading.value = true
            : gl.showLoading = true;

        try {
            const prepare = await ProfileService.internalTransfer({
                ...dataForm,
                _formPath: 'InternalTransfer',
            });

            if (!prepare.data.status && prepare.data.errors_form?.InternalTransfer.fields) {
                for( const [key, value] of Object.entries(prepare.data.errors_form.InternalTransfer.fields)) {
                    errors[key] = value;
                }
            } else {
                errors.cleanError();
            }

            // show messages
            if (prepare.postMessages)
                prepare.postMessages.forEach(el => {
                    notification[el.success ? 'success' : 'error']({
                        content: el.msg,
                        duration: 2500,
                        keepAliveOnHover: true,
                    });
                });
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };
      
        gl.isMobile
            ? localLoading.value = false
            : gl.showLoading = false;
    };

    const onRequestWithdraw = async () => {
        gl.isMobile
            ? localLoading.value = true
            : gl.showLoading = true;

        try {
  
            const { data, postMessages } = await Referral.requestWithdraw({
                ...dataForm2,
                _formPath: 'WithdrawRequest',
            });

            if (!data.status) {
                for( const [key, value] of Object.entries(data.errors_form.WithdrawRequest.fields)) {
                    errors2[key] = value;
                }
            } else {
                errors2.cleanError();

                // show messages
                if (postMessages)
                    postMessages.forEach(el => {
                        gl.showNotification({
                            type: el.success ? 'success' : 'error',
                            msg: el.msg,
                        });
                    });
            }
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };
      
        gl.isMobile
            ? localLoading.value = false
            : gl.showLoading = false;
    };

    const getFullInfo = async () => {
        gl.isMobile
            ? loading.value = true
            : gl.showLoading = true;

        try {
            fullInfo.value = ( await Referral.getFullInfo() ).data;

            dataForm2.withdraw_address = fullInfo.value.withdraw_address;
            dataForm2.withdraw_amount = fullInfo.value.withdraw_amount;
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };
      
        gl.isMobile
            ? loading.value = false
            : gl.showLoading = false;
    };

    const currency = icon => refs.currencies.find(el => el.id === icon);

    onMounted(async () => {
        if (route.query?.tab === 'referral_info_withdrawal' || !fullInfo.value) {
            await getFullInfo();
        }
    });

    return {
        gl,
        env,
        refs,
        errors,
        loading,
        errors2,
        profile,
        dataForm,
        fullInfo,
        dataForm2,
        localLoading,
        currency,
        onRequestWithdraw,
        onInternalTransfer,
    };
}