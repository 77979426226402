<template>
<div class="flex" :class="classes">
    <div
        class="text-xs lg:text-sm mt-[2px] lg:mt-0 label"
        :class="labelClasses"
        v-html="label">
    </div>
    <n-popover
        v-if="help"
        :disabled="disabled"
        trigger="hover"
        class="lg:max-w-[500px] max-w-[180px] bg-main rounded-md"
        placement="top">
        <template #trigger>
            <n-icon
                class="text-main text-lg"
                :class="firstHelpCLasses" >
                <QuestionCircle20Filled />
            </n-icon>
        </template>
        <span class="text-[10px] lg:text-xs text-white" v-html="help"></span>
    </n-popover>
    <n-popover
        v-if="helpWarning"
        :disabled="disabled"
        trigger="hover"
        class="lg:max-w-[500px] max-w-[180px] rounded-md bg-yellow-600"
        placement="top">
        <template #trigger>
            <n-icon
                class="text-lg text-yellow-600"
                :class="secondHelpCLasses" >
                <Warning24Filled />
            </n-icon>
        </template>
        <span class="notes text-[10px] lg:text-xs text-white" v-html="helpWarning"></span>
    </n-popover>
</div>
</template>

<script>
// vue
import { computed } from 'vue';

// naive-ui
import {
    NIcon,
    NInput,
    NDivider,
    NPopover } from 'naive-ui';

// icons
import { Warning24Filled, QuestionCircle20Filled } from '@vicons/fluent';

export default {
    name: 'rb-title',
    props: {
        classes: {
            type: String,
        },
        label: {
            type: String,
            required: true,
        },
        status: {
            type: String,
            default: undefined,
        },
        help: {
            type: String,
        },
        helpWarning: {
            type: String,
        },
        helpPosition: {
            type: String,
            default: 'right',
        },
        noWrap: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        NIcon,
        NPopover,
        Warning24Filled,
        QuestionCircle20Filled,
    },
    setup(props) {
        const labelClasses = computed(() => {
            return [
                props.status == 'error' ? 'text-red-400' : 'text-gray-600 dark:text-white/75',
                props.helpPosition == 'right' ? 'order-first' : 'order-last ml-2',
                props.noWrap == true ? 'whitespace-nowrap' : '',
                props.disabled == true ? 'opacity-50' : '',
            ];
        });

        const firstHelpCLasses = computed(() => {
            return [
                props.helpPosition == 'right' ? 'order-2 ml-2' : 'order-first',
                props.disabled ? 'opacity-50' : 'cursor-pointer',
            ];
        });

        const secondHelpCLasses = computed(() => {
            return [
                props.helpPosition == 'right' ? 'order-3 ml-2' : 'order-2',
                props.disabled ? 'opacity-50' : 'cursor-pointer',
            ];
        });

        return {
            labelClasses,
            firstHelpCLasses,
            secondHelpCLasses,
        };
    },
};
</script>