<template>
    <mobile v-if="gl.isMobile">
        <template #afterTitle><slot name="afterTitle"></slot></template>
        <template #after><slot name="after"></slot></template>
        <template #info><slot name="info"></slot></template>
        <template #messages><slot name="messages"></slot></template>
        <template #afterSearch><slot name="afterSearch"></slot></template>
    </mobile>
    <desktop v-else>
        <template #afterTitle><slot name="afterTitle"></slot></template>
        <template #after><slot name="after"></slot></template>
        <template #info><slot name="info"></slot></template>
        <template #messages><slot name="messages"></slot></template>
    </desktop>
</template>

<script>
import { useGl } from '@store/ts/gl';

// components
import Desktop from './desktop.vue';
import Mobile from './mobile.vue';

export default {
    name: 'bots-createw',
    components: {
        Mobile,
        Desktop,
    },
    setup() {
        const gl = useGl();
    
        return {
            gl,
        };
    },
};
</script>