// vue
import { h, ref, reactive, computed, watch, onMounted } from 'vue';

// services
import ApiKeysService from'@services/apiKeysService';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useApiKeys } from '@store/apiKeys';

// components
import BaseDrawerMobile from '@components/base-drawer/mobile.vue';
import BaseModalDesktop from '@components/base-modal/desktop.vue';
import Blank from '@components/rb-api-key-cr/blank';

// i18n
import { useI18n } from 'vue-i18n';

// naive-ui
import { useNotification } from 'naive-ui';

// icons
import { CloudAdd16Filled }  from '@vicons/fluent';

export default function (props, context) {
    // store
    const gl = useGl();
    const refs = useRefs();
    const apiKeys = useApiKeys();

    // vars
    const init = ref(false);
    const apiKeyInfo = ref();
    const dataForm = reactive({});
    const localLoading = ref(false);
    const apiOptionsOnlyForCreate = ref('create');
    const loadings = reactive({
        apiKey: false,
    });

    // ui
    const notification = useNotification();

    // i18n
    const { t } = useI18n();

    // computeds
    const component = computed(() => props.likeAModal ? (gl.isMobile ? BaseDrawerMobile : BaseModalDesktop) : Blank);
    const apiKeyId = computed(() => props.apiKeyId);
    const isCreate = computed(() => !apiKeyId.value);
    const isUpdate = computed(() => !!apiKeyId.value);
    // const label = computed(() =>
    //     props.exchangeId
    //         ? props.apiKeyId ? apiKeys.localization?.apikeys_update_button : apiKeys.localization?.apikeys_title_button
    //         : null);
    const exchangeValue = computed(() => props.exchangeId || dataForm?.exchange?.value);
    const title = computed(() => props.apiKeyId ? apiKeys.localization?.apikeys_update_button : apiKeys.localization?.apikeys_title_button);
    const applyLabel = computed(() => props.apiKeyId ? apiKeys.localization?.apikeys_update_button : apiKeys.localization?.apikeys_add_button);
    const exchangeMetaInfo = computed(() => refs.exchanges.find(el => el.id === exchangeValue.value)?.meta);
    const exchangeMeta2Info = computed(() => refs.exchanges.find(el => el.id === exchangeValue.value)?.meta2);
    const canDoingSomethingWidthApiKey = computed(() => dataForm.name.value && dataForm.exchange.value && dataForm.key.value && dataForm.sign.value);
    const isExchangeSelected = computed(() => dataForm.exchange.value !== -1);
    const fastapiEnabled = computed(() => refs.exchanges.find(el => el.id === exchangeValue.value)?.fastapi_enabled);
    const apiOptions = ref(
        props.onlyCreate
            ? 'create'
            : (props.apiKeysList?.length ? 'select' : (fastapiEnabled.value ? 'fast_api' : 'create')));

    const destroyed = computed(() => {
        if (props.likeAModal && !props.show) {
            return true;
        }

        return false;
    });

    const exchangeOptions = computed(() => refs.exchanges.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    const localApiKeysList = computed(() => props.apiKeysList.map(el => ({
        label: el?.name || el?.title,
        value: el.id,
        ...el,
    })));

    // const canFastApi = computed(() => {
    //     const div = document.createElement('div');
    //     div.innerHTML = exchangeMeta2Info.value;

    //     let nodeList = div.querySelectorAll('a');
    //     const result = [];
        
    //     for (const node of nodeList) {
    //         if (node.href.includes('https://app.revenuebot.io/external/fauth')) {
    //             result.push(node);
    //         }
    //     }

    //     // return !props.onlyCreate ? !!result.length : false;
    //     return !!result.length;
    // });

    const assignmentTypes = computed(() => {
        const result = [
            ...apiKeys.assignmentCreateTypes,
            ...refs.assignmentTypes.filter(({ id }) => id == 'select'),
        ];

        const i = result.findIndex(({ id }) => id == 'fast_api');

        if (~i) {
            if (fastapiEnabled.value) {
                result[i] = {
                    ...result[i],
                    icon: {
                        classes: 'text-yellow-400',
                        name: CloudAdd16Filled,
                    },
                };
            } else {
                result.splice(i, 1);
            }
        }

        return result;
    });

    const assignmentTypesOnlyForCreate = computed(() => {
        const result = [...apiKeys.assignmentCreateTypes];

        const i = result.findIndex(({ id }) => id == 'fast_api');

        if (~i) {
            result[i] = {
                ...result[i],
                icon: {
                    classes: 'text-yellow-400',
                    name: CloudAdd16Filled,
                },
            };
        }

        return result;
    });

    watch(fastapiEnabled, v => {
        if (v && !props.apiKeysList.length && !props.onlyCreate) {
            apiOptions.value = 'fast_api';
        }

        if (v && isCreate.value && props.onlyCreate) {
            apiOptionsOnlyForCreate.value = 'fast_api';
        } else {
            apiOptionsOnlyForCreate.value = 'create';
        }
    });

    watch(apiOptions, v => {
        if (v == 'fast_api' && !props.onlyCreate) {
            setTimeout(() => {
                fastApiLink();
            }, 200);
        }
    });

    watch(() => exchangeMeta2Info.value, v => {
        if (v) {
            setTimeout(() => {
                fastApiLink();
            }, 200);
        }
    });

    // watchers
    watch(() => props.show, v => {
        if (v) {
            mainInit();
        } else {
            apiKeyInfo.value = null;
            apiOptionsOnlyForCreate.value = 'create';
            apiOptions.value = 'create';
        }
    });

    // fns
    const exchange = exchange => refs.exchanges.find(el => el.id === exchange);

    const mainInit = async () => {
        init.value = false;

        await Promise.allSettled([
            apiKeyssRefs(),
            getFullApiKeyetInfo(),
        ]);

        initModule();

        init.value = true;
    };

    const getFullApiKeyetInfo = async () => {
        if (isUpdate.value) {
            try {
                apiKeyInfo.value = ( await ApiKeysService.getFullInfo(apiKeyId.value) ).data;
            } catch {
                gl.showNotification({
                    type: 'error',
                    msg: t('errorMessage'),
                });
            };
        }
    };

    const apiKeyssRefs = async () => {
        if (apiKeys.refs) return;

        try {
            apiKeys.refs = ( await ApiKeysService.getRefs() ).data;
        } catch {
            notification.error({
                content: t('errorMessage'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        };
    };

    const initModule = () => {
        const res = {
            name: apiKeyInfo.value?.name || props.data?.name || '',
            exchange: props.exchangeId || props.data?.exchange ||  apiKeyInfo.value?.exchange || -1,
            key: apiKeyInfo.value?.key || props.data?.key || '',
            sign: apiKeyInfo.value?.sign || props.data?.sign ||  '',
            show_dashboard: apiKeyInfo.value?.show_dashboard || props.data?.show_dashboard || false,
        };

        for (const [key, value] of Object.entries(res)) {
            if (key === 'name') {
                dataForm[key] = {
                    value,
                    title: apiKeys.localization['apikeys_name_f'],
                    placeholder: apiKeys.localization['apikeys_name_i'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: true,
                    customField: true,
                };
            } else if (key === 'exchange') {
                dataForm[key] = {
                    value,
                    title: apiKeys.localization['apikeys_exchange_f'],
                    placeholder: apiKeys.localization['apikeys_exchange_i'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: true,
                    customField: true,
                };
            } else if (key === 'key') {
                dataForm[key] = {
                    value,
                    title: apiKeys.localization['apikeys_key_f'],
                    placeholder: apiKeys.localization['apikeys_key_i'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: true,
                    customField: true,
                };
            } else if (key === 'sign') {
                dataForm[key] = {
                    value,
                    title: apiKeys.localization['apikeys_sign_f'],
                    placeholder: apiKeys.localization['apikeys_sign_i'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: true,
                    customField: true,
                };
            } else if (key === 'show_dashboard') {
                dataForm[key] = {
                    value,
                    title: apiKeys.localization['show_dashboard_label'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: true,
                    customField: true,
                };
            };
        };
    };

    const getApiKeysList = async (id = exchangeValue.value) => {
        const rescords = ( await ApiKeysService.getApiKeysList({
            exchanges: [id],
            statuses: [1],
        }) ).data.records;

        for (const key of rescords) {
            const i = props.apiKeysList.findIndex(({ id }) => {
                return id == key.id;
            });

            if (!~i) {
                if (!props.onlyCreate) {
                    apiOptions.value = 'select';
                } else {
                    if (props.likeAModal) {
                        context.emit('update:show', false);
                    }

                    gl.showNotification({
                        type: 'success',
                        msg: apiKeys.localization?.key_created_successfully || 'Ключ \'FastAPI key\' успешно создан',
                    });
                }
                
                return void context.emit('addNewApiKey', key);
            }
        }

        if (destroyed.value) return;
        
        getApiKeysList();
    };

    const onDoingSomethingWithApiKey = async () => {
        localLoading.value = true;

        const formPath = isCreate.value ? 'apikeys.addNew' : 'apikeys.update';

        const record = {
            exchange: dataForm.exchange.value,
            name: dataForm.name.value,
            key: dataForm.key.value,
            sign: dataForm.sign.value,
            show_dashboard: dataForm.show_dashboard.value,
            _formPath: formPath,
        };

        let result;

        try {
            result = isCreate.value
                ? await ApiKeysService.addApiKeyToExchange(record.exchange, record)
                : await ApiKeysService.update({
                    id: props.apiKeyId,
                    ...record,
                });

            if (!result.data.status) {
                if (result.data?.errors_form) {

                    for (let key in dataForm) {
                        const fields = result.data.errors_form[formPath].fields;
                        const el = Object.keys(fields).find(el => el === key);

                        if (el) {
                            dataForm[key].status = 'error';
                            dataForm[key].msg = fields[el].msg;
                        } else {
                            dataForm[key].status = 'success';
                            dataForm[key].msg = undefined;
                        }
                    }
                }
              
                if (result.data?.errors) {
                    result.data.errors.forEach(el => {
                        notification.error({
                            content: el.msg,
                            duration: 2500,
                            keepAliveOnHover: true,
                        });
                    });
                }
            } else {
                context.emit('update:show', false);

                if (isUpdate.value) {
                    context.emit('updateApiKey', result.data.record);
                } else {
                    context.emit('addNewApiKey', result.data?.records[0]);
                }

                if (!props.onlyCreate) {
                    apiOptions.value = 'select';
                }

                // clean apiKeyModel
                for (let key in dataForm) {
                    dataForm[key].value = '';
                }

                // show messages
                result.postMessages.forEach(el => {
                    gl.showNotification({
                        type: el.success ? 'success' : 'error',
                        msg: el.msg,
                    });
                });
            }
        } catch {
            notification.error({
                content: t('errorMessage'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        }

        localLoading.value = false;
    };

    const setLoadings = (fields = [], value = true) => {
        fields.forEach(key => {
            if (dataForm[key]?.loading != undefined) {
                dataForm[key].loading = value;
            }
        });
    };

    const fastApiLink = () => {
        let nodeList = document.querySelectorAll('#fast_api .notes a');
        const result = [];
        
        for (const node of nodeList) {
            if (node.href.includes('https://app.revenuebot.io/external/fauth')) {
                result.push(node);
            }
        }

        result.forEach(el => {
            if (el.getAttribute('listener') !== 'true') {
                el.setAttribute('listener', true);
                el.addEventListener('click', e => {
                    // e.preventDefault();
                    // console.log('shit');
                    getApiKeysList();
                });
            }
        });
    };

    onMounted(async () => {
        if (!props.likeAModal || props.show) {
            await mainInit();
        }
    });

    return {
        gl,
        init,
        title,
        apiKeys,
        dataForm,
        isUpdate,
        isCreate,
        loadings,
        component,
        applyLabel,
        apiOptions,
        localLoading,
        fastapiEnabled,
        assignmentTypes,
        exchangeOptions,
        exchangeMetaInfo,
        localApiKeysList,
        exchangeMeta2Info,
        isExchangeSelected,
        apiOptionsOnlyForCreate,
        assignmentTypesOnlyForCreate,
        canDoingSomethingWidthApiKey,
        exchange,
        onDoingSomethingWithApiKey,
    };
}