<template>
<base-modal-desktop
    v-if="show"
    :label="gl.postDialogMessages[page - 1].title"
    :show="true"
    :mask-closable="false"
    :show-cLose-button="false"
    @update:show="close">
    <template #default>
        <div v-html="gl.postDialogMessages[page - 1].msg"></div>
    </template>
    <template #footer>
        <div v-if="refs.localization" class="flex justify-end">
            <n-button
                class="text-white/90 rounded-md"
                :color="gl.mainColor"
                @click="dialogMessageMarkAsRead(gl.postDialogMessages[page - 1])">
                {{ refs.localization.dialogs.next }}
            </n-button>
            <!-- <n-button
                class="text-gray-600 dark:text-white/75 rounded-md ml-2"
                @click="close">
                {{ refs.localization.dialogs.cancel }}
            </n-button> -->
        </div>
    </template>
</base-modal-desktop>
</template>

<script>
// general
import general from './general';

// naive-ui
import {
    NIcon,
    NCard,
    NModal,
    NButton,
    NPagination } from 'naive-ui';

// icons
import { Dismiss16Filled } from '@vicons/fluent';

export default {
    name: 'post-dialog-message-desktop',
    props: {
        label: {
            type: String,
            required: true,
        },
        width: {
            type: String,
            default: '600px',
        },
        height: {
            type: Number, String,
        },
        maskClosable: {
            type: Boolean,
            default: true,
        },
        showCLoseButton: {
            type: Boolean,
            default: true,
        },
        classes: {
            type: String,
        },
        lightDark: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        NIcon,
        NCard,
        NModal,
        NButton,
        NPagination,
        Dismiss16Filled,
    },
    setup() {
        
        return {
            ...general(),
        };
    },
};
</script>

<style lang="scss" scoped>
:deep(a) {
    color: #1A6DE0 !important;
}

:deep(b) {
    color: #1A6DE0 !important;
}
</style>