<template>
<section>
    <div class="flex items-center">
        <div class="text-xs text-gray-600 dark:text-white/75 whitespace-nowrap">{{ title }}</div>
        <n-popover
            v-if="recommendedPairsInfo"
            trigger="hover"
            class="max-w-[200px] rounded-md bg-yellow-600"
            placement="right">
            <template #trigger>
                <n-icon
                    class="cursor-pointer text-lg ml-2 text-yellow-600">
                    <QuestionCircle20Filled />
                </n-icon>
            </template>
            <span class="notes text-[10px] text-white" v-html="recommendedPairsInfo"></span>
        </n-popover>
    </div>
<!--  overflow-x-auto v-x-scroll mt-4 -->
    <div class="flex justify-start items-stretch mt-4">
        <label
            v-for="(curPair, i) in recommendedPairs.slice(0, 3)"
            class="cursor-pointer border border-transparent hover:border-main p-2 rounded-md max-w-[30%]"
            :class="[{'not-avail': curPair.disabled || loading || disabled}, curPair.id === modelValue && !curPair.disabled ? 'border-main bg-main bg-opacity-25' : '']"
            :key="i">
            <input
                type="radio"
                class="hidden"
                :value="curPair.id"
                :checked="curPair.id === modelValue"
                :disabled="curPair.disabled || disabled"
                @change="$emit('update:modelValue', $event.target.value)"/>
            <div class="flex items-center">
                <rb-coin class="w-6" :coin="currency(curPair.pair, false)[0]" />
                <div class="text-center px-2 whitespace-nowrap">{{ curPair.pair }}</div>
                <rb-coin class="w-6" :coin="currency(curPair.pair, false)[1]" />
            </div>
        </label>
        <div v-if="recommendedPairs.length > 3" class="flex flex-grow ml-2">
            <n-button
                strong
                secondary
                @click="showDrawer = true"
                class="px-1 h-full rounded-md ">
                <span class="font-semibold text-base -mt-1">...</span>
            </n-button>
        </div>
    </div>
    <rb-pair-select
        class="mt-4"
        popoverType="warning"
        :disabled="disabled || loading"
        :loading="loading"
        :help="howToChooseInfo"
        :options="pairsRefsOptions"
        :label="label + ':'"
        :value="modelValue !== -1 ? modelValue : undefined"
        :status="status === 'error' ? 'error' : undefined"
        :msg="msg"
        @update:value="$emit('update:modelValue', $event == undefined ? -1 : $event)" />

    <n-alert v-if="tickerMessage" class="my-4" type="warning" size="small">
        <div class="notes text-xs" v-html="tickerMessage"></div>
    </n-alert>

    <n-drawer
        v-model:show="showDrawer"
        width="100%"
        display-directive="show"
        class="bg-gray-100 dark:bg-darkbg"
        :auto-focus="false">
        <n-drawer-content body-content-style="padding: 0">
            <div class="flex flex-col h-full">
                <div class="w-full flex justify-between px-3 items-center bg-gray-100 dark:bg-darkbg text-sm text-center text-gray-600 dark:text-white/75 py-2">
                    <n-icon
                        size="20"
                        class="text-gray-600 dark:text-white/75"
                        @click="showDrawer = false">
                        <ArrowLeft12Regular />
                    </n-icon>
                    <n-button
                        quaternary
                        class="bg-transparent text-main pr-0"
                        @click="showDrawer = false">
                        {{ t('done') }}
                    </n-button>
                </div>
                <div class="pb-4 flex-grow overflow-y-scroll overscroll-none scrollbar-hide px-3">
                    <section class="flex flex-wrap">
                        <div v-for="(curPair, i) in recommendedPairs" :key="i" class="w-4/12">
                            <label
                                class="cursor-pointer border border-transparent hover:border-main p-2 rounded-md block"
                                :class="[{'not-avail': curPair.disabled || loading || disabled}, curPair.id === modelValue && !curPair.disabled ? 'border-main bg-main bg-opacity-25' : '']">
                                <input
                                    type="radio"
                                    class="hidden"
                                    :value="curPair.id"
                                    :checked="curPair.id === modelValue"
                                    :disabled="curPair.disabled || disabled"
                                    @change="$emit('update:modelValue', $event.target.value), showDrawer = false"/>
                                <div class="flex items-center justify-center">
                                    <rb-coin class="w-6" :coin="currency(curPair.pair, false)[0]" />
                                    <div class="text-center px-3 whitespace-nowrap">{{ curPair.pair }}</div>
                                    <rb-coin class="w-6" :coin="currency(curPair.pair, false)[1]" />
                                </div>
                            </label>
                        </div>
                    </section>
                </div>
              </div>
          </n-drawer-content>
      </n-drawer>
</section>
</template>

<script>
// vue
import { h, ref, computed } from 'vue';

// store
import { useRefs } from '@store/ts/refs';

// components
import RbCoin from '@components/rb-coin';
import GridNav from '@components/base/grid-nav.vue';
import RbPairSelect from '@components/rb-pair-select/mobile.vue';

// i18n
import { useI18n } from 'vue-i18n';

// naive-ui
import {
    NCard,
    NIcon,
    NAlert,
    NInput,
    NDrawer,
    NButton,
    NSelect,
    NDivider,
    NPopover,
    NCheckbox,
    NDrawerContent,
    useMessage } from 'naive-ui';

// icons
import { Warning24Filled, QuestionCircle20Filled, ArrowLeft12Regular } from '@vicons/fluent';

export default {
    name: 'rb-pairs-mobile',
    emits: ['setPerPage', 'update:modelValue'],
    props: {
        title: String,
        loading: Boolean,
        tickerMessage: String,
        label: String,
        dataHelp: String,
        gridNavLabel: String,
        recommendedPairsInfo: String,
        howToChooseInfo: String,
        disabled: Boolean,
        modelValue: {
            type: [String, Number],
            required: true,
        },
        pager: {
            type: Object,
            required: true,
        },
        recommendedPairsRef: {
            type: Array,
            required: true,
        },
        pairsRefs: {
            type: Array,
            required: true,
        },
        status: {
            type: String,
            default: undefined,
        },
        msg: {
            type: String,
        },
    },
    components: {
        NIcon,
        NAlert,
        RbCoin,
        NDrawer,
        NButton,
        NSelect,
        GridNav,
        NDivider,
        NPopover,
        RbPairSelect,
        NDrawerContent,
        Warning24Filled,
        ArrowLeft12Regular,
        QuestionCircle20Filled,
    },
    setup(props, { emit }) {
        // store
        const refs = useRefs();

        // i18n
        const { t } = useI18n();

        // vars
        const showDrawer = ref(false);

        const recommendedPairs = computed(() => {
            let res = Array.isArray(props.recommendedPairsRef) ?
                props.recommendedPairsRef
                : [];

            if (res.length) {
                const arr = [];
                res.forEach(el => {
                    if (!~arr.findIndex(item => item.id === el.id)) {
                        arr.push(el);
                    }
                });
                res = arr;
            };

            return res;

            // return res.slice(0, props.pager.perPage);
        });

        const currency = (icon, first = true) => {
            
            const lIcon = first ? icon.split('/').shift().trim() : icon.split('/');

            const res = Array.isArray(lIcon)
                ? [...lIcon.map(curIcon => refs.currencies.find(el => el.id === curIcon.split('__')[0]))]
                : refs.currencies.find(el => el.id === lIcon);

            return res;
        };

        const hasApplicablePairClass = (pair) => !!props.pairsRefs.some(({ id }) => id === pair.pair);

        const pairsRefsOptions = computed(() => {
            return props.pairsRefs.map(cur => {
                return {
                    label: cur.title,
                    value: cur.id,
                    ...cur,
                };
            });
        });

        const renderLabel = (option) => {
            return h(
                'div',
                {
                    class: 'flex items-center py-1',
                },
                [
                    h(RbCoin, {
                        class: 'w-4',
                        coin: currency(option.value, false)[0],
                    }),
                    h(
                        'div',
                        {
                            class: 'px-3',
                        },
                        [
                            h('span', {
                                class: 'text-xs',
                            }, option.label.split('/')[0]),
                            h('span', {
                                class: 'opacity-50 px-1',
                            }, '/'),
                            h('span',  {
                                class: 'text-xs',
                            }, option.label.split('/')[1]),
                        ],
                    ),
                    h(RbCoin, {
                        class: 'w-4',
                        coin: currency(option.value, false)[1],
                    }),
                ],
            );
        };
        
        return {
            showDrawer,
            recommendedPairs,
            pairsRefsOptions,
            t,
            currency,
            renderLabel,
            hasApplicablePairClass,
        };
    },
};
</script>

<style lang="scss" scoped>
.not-avail {
  color: #888!important;
  cursor: not-allowed!important;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
</style>