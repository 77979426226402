// vue
import { ref, computed } from 'vue';

// i18n
import { useI18n } from 'vue-i18n';

// services
import Api from '@services/api';

// naive-ui
import { useNotification } from 'naive-ui';

// store
import { useGl } from '@store/ts/gl';
import { useDashBoard } from '@store/ts/dashBoard';

export default function (props, context) {
    const gl = useGl();
    const dashBoard = useDashBoard();

    // vars
    const carousel = ref();
    const updateLoading = ref(false);

    // ui
    const notification = useNotification();

    // i18n
    const { t } = useI18n();

    // const loading = ref(false);
    const loading = computed(() => gl.loading || dashBoard.loading);

    const goTo = (url) => {
        window.open(url, '_blank');
    };

    const update = async () => {
        updateLoading.value = true;

        try {
            dashBoard.news = ( await Api.dashBoard({
                dashboard_block: 'news',
            }) ).data.news;
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        }

        updateLoading.value = false;
    };

    return {
        loading,
        carousel,
        dashBoard,
        updateLoading,
        goTo,
        update,
    };
}