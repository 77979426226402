// vue
import { ref, computed, onMounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';

export default function (props, context) {
    // store
    const gl = useGl();

    // vars
    const rbRadioGroup = ref();
    const bgClasses = ref(['bg-white', 'dark:bg-inputBackground']);

    const localOptions = computed(() => props.options.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    // const setBgColor = () => {
    //     const parent = rbRadioGroup.value?.parentElement;

    //     const rearrangeColors = (v1, v2) => {
    //         const i = bgClasses.value.findIndex(selector => selector == v1);
    
    //         if (~i) {
    //             bgClasses.value[i] = v2;
    //             return true;
    //         }

    //         return false;
    //     };
        
    //     const getParentColor = parent => {
    //         if (parent) {
    //             const classList = parent?.classList;
    //             const backgroundColor = parent?.style?.backgroundColor;

    //             if (classList?.length) {
    //                 for (const el of classList) {
    //                     if (el.includes('bg-')) {
    //                         if (!gl.darkTheme && el == 'bg-white') {
    //                             if (rearrangeColors('bg-white', 'bg-gray-100')) {
    //                                 return;
    //                             }
    //                         }
    //                     }
    //                 }
    //             }

    //             getParentColor(parent?.parentElement);
    //         }
    //     };

    //     getParentColor(parent);
    // };

    const buttonCLass = computed(() => {
        const result = [];

        const sizes = {
            'small': gl.isMobile ? 'px-2 py-1 text-[10px]' : 'px-3 py-[2px]',
            'medium': gl.isMobile ? 'px-2 py-[2px] text-xs' : 'px-4 py-1',
            'large': gl.isMobile ? 'px-4 py-[3px] text-sm' : 'px-5 py-2',
        };

        result.push(sizes[props.size]);

        return result;
    });

    const updateValue = $event => {
        context.emit('update:value', $event);
    };

    onMounted(() => {
        // setBgColor();
    });

    return {
        gl,
        bgClasses,
        localOptions,
        rbRadioGroup,
        buttonCLass,
        updateValue,
    };
}